import moment from "moment";
import { useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "../../../Button";
// import { color } from "../../../../constant/color";
import { sampleData } from "./data";
// import MeterInfoModal from "../../MeterInfoModal";
import { toast } from "react-hot-toast";
import axios from "../../../../utils/axios";
import { meterController } from "../../../../controllers/MeterController";
// import SetLoadPolicy from "../../../../Modals/SetLoadPolicy";
import { themeColor as color } from "../../../../constant/color";
// import MeterInfoModal from "../../modals/MeterInfoModal";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";
import { useSelector } from "react-redux";
import SetLoadPolicy from "../../../Meter/Table/Modals/SetLoadPolicy";
import MeterInfoModal from "../../../Meter/modals/MeterInfoModal";
import { Dropdown, message, Space } from "antd";
import { TbDotsVertical } from "react-icons/tb";
import { tableCustomStyles } from "../../../../helperFuncs";
import { useUnassignMeterMutation } from "../../../../services/meter.services";
import { errorBlock } from "../../../../controllers/errorBlock";
import { AppLoader } from "../../../AppLoader";
import { useEditCustomerMeterMutation } from "../../../../services/customer.services";
import EditCustomerMeter from "../../modals/AddAppUser";
const AssignedMetersTable = ({
  data,
  setinfomodal,
  setmeternumber,
  refresh,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectMeter, setselectMeter] = useState(null);
  const [selectedLoading, setselectedLoading] = useState(null);
  const [loading, setloading] = useState(null);
  const [details, setdetails] = useState(null);
  const [loadType, setloadType] = useState(null);
  const [showSet, setshowSet] = useState(false);

  const [
    editCustomerMeter,
    { isLoading: editLoading, error: editError, data: editData },
  ] = useEditCustomerMeterMutation();

  let num = 1;

  const handleUnassign = (item) => {
    // unassignMeter({
    //   meternumber: item?.meter,
    //   customerid: item?.customerid,
    // })
    //   .then((res) => {})
    //   .catch((err) => {
    //     errorBlock(err);
    //   });
  };

  const [selectedId, setSelectedId] = useState(null);
  const [modal, setModal] = useState(false);
  const [editing, setediting] = useState(false);
  const userDetials = useSelector((state) => state.userDetials);

  const handleLoad = async (type, number) => {
    try {
      if (type == "set") {
        return setshowSet(true);
      } else if (type == "get") {
        let res = await axios.post("/meter/getpermeterloadcontrolpolicy", {
          meternumber: number,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);

          setloadType(null);
          setselectMeter(null);
          return;
        }
        console.log(result.message, "/get");
        setloadType(null);
        setselectMeter(null);
        setloading(false);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log("Selected Rows: ", selectedRows);
  };

  const columns = [
    {
      name: "Meter no",
      center: true,
      selector: (row) => row.meter,
      grow: 2,
    },
    {
      name: "Customer name",
      center: true,
      selector: (row) => row.customerid,
      grow: 2,
    },
    {
      name: "Customer type ",
      center: true,
      selector: (row) => row.customertype,
      grow: 2,
    },
    {
      name: "Address",
      center: true,
      selector: (row) => row.metertype,
      grow: 1,
      cell: (item) => {
        return <div className="">{item.address ?? "-Nil-"}</div>;
      },
    },

    {
      name: "Meter type",
      center: true,
      selector: (row) => row.metertype,
      grow: 1,
      cell: (item) => {
        return <div className="">{item.metertype ?? "-Nil-"}</div>;
      },
    },

    {
      name: "Action",
      center: true,
      selector: (row) => row.meternumber,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <div
                        className="text-[12px]"
                        onClick={() => {
                          setSelectedId(item.meter);
                          setModal(true);
                        }}
                      >
                        View details
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        className="text-[12px]"
                        onClick={() => {
                          setediting(true);
                          setdetails(item);
                        }}
                      >
                        Edit
                      </div>
                    ),
                  },
                ],
              }}
              placement="bottom"
              arrow
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <TbDotsVertical color="black" />
                </Space>
              </a>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <div
      style={{
        borderTop: "1px solid #eee",
      }}
      className=""
    >
      {modal && (
        <MeterInfoModal
          setopen={setModal}
          open={modal}
          meterNumber={selectedId}
        />
      )}

      {editing && (
        <EditCustomerMeter
          open={editing}
          details={details}
          setopen={setediting}
          refresh={refresh}
        />
      )}

      {showSet && (
        <SetLoadPolicy meterId={selectMeter} closeModal={setshowSet} />
      )}
      <DataTable
        // selectableRows
        onSelectedRowsChange={handleChange}
        columns={columns}
        customStyles={tableCustomStyles}
        data={data}
        pagination
        // expandableRows
        // expandableRowsComponent={<div className="">this is a sample</div>}
        progressPending={loading}
        paginationPerPage={10}
      />
    </div>
  );
};

export default AssignedMetersTable;
