import { useState } from "react";
import Revenue from "../../../../components/Dashboards/Revenue";
const SiteRevenue = () => {
  const [paymentHistory, setPaymetHistory] = useState([]);
  const [revenue, setRevenue] = useState(0);

  return (
    <div className="row mt-4 w-full bg-white rounded-md shadow-sm p-2">
      <div className="">
        <h3>Revenue generated in site</h3>
      </div>
      <Revenue paymentHistory={paymentHistory} revenue={revenue} />
    </div>
  );
};

export default SiteRevenue;
