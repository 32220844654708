import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import { useState, useEffect, useRef } from "react";
import { themeColor as color } from "../../../../constant/color";
import moment from "moment";

const RevenueCharts = ({ paymentHistory }) => {
  const chartRef = useRef();
  const [gradientValue, setGradientValue] = useState("");

  const labels = paymentHistory ? paymentHistory.map((i) => i?.orderDate) : [];

  return (
    <Line
      ref={chartRef}
      data={{
        labels, // x axis
        datasets: [
          {
            type: "bar",
            label: "Amount",
            fill: false,
            borderColor: "dodgerblue",
            data: paymentHistory ? paymentHistory.map((i) => i?.amount) : [],
            borderWidth: 2, // Line width
            pointBackgroundColor: "dodgerblue", // Point color
            pointBorderColor: "dodgerblue",
            backgroundColor: "dodgerblue",
            pointBorderWidth: 0.5,
            barThickness: 40,
          },

          {
            type: "bar",
            label: "Net Revenue",
            fill: false,
            borderColor: "rgba(255, 159, 64)",
            borderWidth: 2,
            backgroundColor: "rgba(255, 159, 64, 1)",
            pointBackgroundColor: "rgba(255, 159, 64, 1)",
            pointBorderColor: "rgba(255, 159, 64, 1)",
            pointBorderWidth: 0.5,
            barThickness: 40,
            data: paymentHistory
              ? paymentHistory.map((i) => i?.netRevenue)
              : [],
          },
          {
            type: "bar",
            label: "Commisson",
            fill: false,
            borderColor: "tomato",
            data: paymentHistory
              ? paymentHistory.map((i) => i?.commission)
              : [],
            borderWidth: 2,
            backgroundColor: "tomato",
            pointBackgroundColor: "tomato",
            pointBorderColor: "tomato",
            pointBorderWidth: 0.5,
            barThickness: 40,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: true,
          title: {
            display: false,
          },
          tooltip: {
            // callbacks: {
            //   label: function (tooltipItem, data) {
            //     return `RWF ${parseInt(tooltipItem.parsed.y)}`;
            //   },
            // },
          },
        },
        scales: {
          x: {
            display: true,
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            stacked: true,
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default RevenueCharts;
