import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout";
import { IoIosArrowForward } from "react-icons/io";
import DateRangePicker from "../../../components/DateRangePicker";
import RevenueChart from "../../../components/Dashboards/SystemAdmin/Chart/RevenueChart";
import PowerQualityChart from "../../../components/Dashboards/SystemAdmin/Chart/PowerQualityChart";
import { useAsync } from "../../../utils/Hooks/useAsync";
import { client } from "../../../utils/api";
import { getInitials } from "../../../../src/utils/helpers";
import moment from "moment";
import ListLoader from "../../../components/ListLoader";
import { Empty, Select } from "antd";
import ProjectSiteLocation from "../../../components/ProjectManager/ProjectSite/Map/ProjectSiteLocation";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { themeColor } from "../../../constant/color";
import RoleOverviewCard from "../../../components/sysadmin/Cards/RoleOverviewCard";
import { GiPayMoney } from "react-icons/gi";
import { BsBuildingFillGear } from "react-icons/bs";
import { FaUsersCog } from "react-icons/fa";

import { FaUsers } from "react-icons/fa6";
import { useSelector } from "react-redux";
import Rule from "../../../components/Rule/Index";
import { useGetSalesBySysAdminMutation } from "../../../services/salesanalytics.services";
import { errorBlock } from "../../../controllers/errorBlock";
import { useGetAllSiteMapMutation } from "../../../services/map.services";
import FilterDateRange from "../../../components/FilterDateRange";
const SysAdminDashboard = () => {
  const { name } = useSelector((state) => state.domain);
  const [refresh, setrefresh] = useState(true);
  const [opendate, setopendate] = useState(false);

  const [startdate, setStartDate] = useState(
    moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  const [getSalesBySysAdmin, { isLoading, error, data }] =
    useGetSalesBySysAdminMutation();

  const [
    getAllSiteMap,
    { isLoading: sitemapLoading, error: sitemapError, data: sitemapData },
  ] = useGetAllSiteMapMutation();

  useEffect(() => {
    getAllSiteMap().unwrap();
  }, [refresh]);

  useEffect(() => {
    if (sitemapError) {
      errorBlock(sitemapError);
    }
  }, [sitemapError]);

  useEffect(() => {
    getSalesBySysAdmin({ startdate, enddate }).unwrap();
  }, [refresh]);

  useEffect(() => {
    if (error) {
      errorBlock(error);
    }
  }, [error]);

  const [currency, setcurrency] = useState("");

  useEffect(() => {
    if (data?.cumulativeStats) {
      setcurrency(
        !Object.keys(data?.cumulativeStats)
          ? ""
          : Object.keys(data?.cumulativeStats)[0]
      );
    }
  }, [data]);
  // get all role
  const {
    data: allRoles,
    run: getRoles,
    status: allRolesStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  // get all decommission
  const {
    data: meterDecommission,
    run: getAllDecomissionedMeters,
    status: decommissionedMeterStatus,
  } = useAsync({ data: [], status: "pendgin" });

  const {
    data: metersInService,
    run: getMetersInService,
    status: metersInServiceStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  const {
    data: regsiteredMeters,
    run: getRegisteredMeters,
    status: regsiteredMetersStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  const [showmap, setshowmap] = useState(true);

  // get all user roles
  useEffect(() => {
    getRoles(
      client(`roles/getallroles`, {
        data: {},
        method: "POST",
      })
    );
  }, [getRoles]);

  // meter in service
  useEffect(() => {
    const data = {
      startdate: startdate,
      enddate: enddate,
    };

    getMetersInService(
      client(`meter/countallmetersinservice`, {
        data,
        method: "POST",
      })
    );
  }, [refresh]);

  // get decommissioned meter
  useEffect(() => {
    const data = {
      startdate: startdate,
      enddate: enddate,
    };

    getAllDecomissionedMeters(
      client(`/meter/countalldecommisionedmeters`, {
        data,
        method: "POST",
      })
    );
  }, [refresh]);

  // registered meters
  useEffect(() => {
    const data = {
      startdate: startdate,
      enddate: enddate,
    };

    getRegisteredMeters(
      client(`/meter/countallregisteredmeters`, {
        data,
        method: "POST",
      })
    );
  }, [refresh]);

  const projectManagers = useMemo(() => {
    const { roles: roleData } = allRoles;
    if (roleData?.length) {
      return roleData
        .filter((item) => item.role === "Project Manager")
        .slice(0, 4);
    }
    return [];
  }, [allRoles]);

  const salesManagers = useMemo(() => {
    const { roles: roleData } = allRoles;
    if (roleData?.length) {
      return roleData.filter((item) => item.role === "Sales Agent").slice(0, 4);
    }
    return [];
  }, [allRoles]);

  const customerManagers = useMemo(() => {
    const { roles: roleData } = allRoles;
    if (roleData?.length) {
      return roleData
        .filter((item) => item.role === "Customer Manager")
        .slice(0, 4);
    }
    return [];
  }, [allRoles]);

  const customerRepresentative = useMemo(() => {
    const { roles: roleData } = allRoles;
    if (roleData?.length) {
      return roleData
        .filter((item) => item.role === "Customer Representative")
        .slice(0, 4);
    }
    return [];
  }, [allRoles]);

  const RoleCard = ({ role, sitename, username }) => {
    return (
      <div className="flex items-center justify-between my-2 even:bg-gray-50 p-1 cursor-pointer rounded-sm">
        <div className="flex items-center">
          <div className="rounded-full text-black bg-gray-200 font-semibold  p-3 w-10 h-10 flex items-center justify-center">
            {getInitials(username)}
          </div>
          <div className="ml-3">
            <div className="">{username ? username : "N/A"}</div>
            <div className="text-gray-400 text-[12px] font-light">
              Site managed:{" "}
              <span className="font-semibold text-gray-800">{sitename}</span>
            </div>
          </div>
        </div>
        <div>
          <IoIosArrowForward size={10} />
        </div>
      </div>
    );
  };

  const MeterTopCard = ({ title, caption, value, bg }) => {
    // border-red-200 bg-red-50
    return (
      <div
        className={`flex my-2 items-center h-full rounded justify-between p-4 border-[1px] border-sky-200 ${
          title == "Active meters"
            ? "bg-sky-50"
            : title == "Decommissioned meters"
            ? " bg-sky-50"
            : "bg-sky-50"
        }`}
      >
        <div className="w-[70%]">
          <div className="font-semibold pb-1">{title}</div>
          <div className="font-light">{caption}</div>
        </div>
        <div className="font-bold w-[30%] text-2xl text-end">{value}</div>
      </div>
    );
  };

  const handleRevenuefilter = (e) => {
    switch (e) {
      case "today":
        setStartDate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "week":
        setStartDate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "month":
        setStartDate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "year":
        setStartDate(
          moment(Date.now()).startOf("year").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      default:
        break;
    }

    setEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
    setrefresh((prev) => !prev);
  };

  const rolesInSystem = [
    {
      id: 1,
      tag: "Project Site",
      link: "/projectsite",
      icon: <BsBuildingFillGear size={34} />,
      caption: "View site overview",
    },
    {
      id: 2,
      tag: "Customer Managers",
      link: "/dashboard/salesagent",
      icon: <FaUsersCog size={34} />,
      caption: "Monitor managers",
    },
    {
      id: 3,
      tag: "Customer Support",
      link: "/dashboard/salesagent",
      icon: <FaUsers size={34} />,
      caption: "Monitor Support",
    },
    {
      id: 4,
      tag: "Sales Agent",
      link: "/dashboard/allsalesagent",
      icon: <GiPayMoney size={34} />,
      caption: "View sales agent activities",
    },
  ];

  return (
    <Layout>
      <div className="mb-40">
        {/* first row */}
        <div className="mb-2 bg-white rounded drop-shadow-md p-2">
          <div className="mt-2 font-semibold">
            Your Company <span className="text-secondary">({name})</span>{" "}
            Analytics
          </div>
          <hr className="my-2" />
          <div className="flex items-center justify-between">
            <div className="">
              <h2 className="mb-0">Meters stats and Revenue across sites.</h2>
            </div>
            <div className="">
              <FilterDateRange
                open={opendate}
                setRefresh={setrefresh}
                setopen={setopendate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                handlefilter={handleRevenuefilter}
              />
              {/* <Select
                defaultValue={"today"}
                className="w-[200px]"
                onChange={(e) => handleRevenuefilter(e)}
                options={[
                  { value: "today", label: "Today" },
                  { value: "week", label: "This week" },
                  { value: "month", label: "This month" },
                  { value: "year", label: "This year" },
                ]}
              /> */}
            </div>
          </div>

          {/* Meters analytics */}
          <div className="grid grid-cols-3 gap-4 mt-2 mb-4">
            <div className="col-span-1">
              <div className="h-[550px] border-r-[1px] p-4">
                <div className="">
                  <div className="font-semibold">Meters </div>
                  <div className="text-gray-500 font-light">
                    Meters Analytics from all project sites
                  </div>
                </div>
                <div className="h-[420px] grid grid-cols-1 gap-4">
                  <MeterTopCard
                    title={"Active meters"}
                    caption={"Total number of meters in service."}
                    value={metersInService?.count ?? 0}
                  />
                  <MeterTopCard
                    title={"Decommissioned meters"}
                    caption={"Total number of meters taken out of use."}
                    value={0}
                  />
                  <MeterTopCard
                    title={"Registered meters"}
                    caption={
                      "Total number of meters registered to your organization."
                    }
                    value={regsiteredMeters?.count ?? 0}
                  />
                </div>
              </div>
            </div>
            {/* Revenue */}
            <div className="col-span-2">
              <div className="h-[550px] rounded  p-4">
                <div className="flex w-full items-center justify-between">
                  <div className="">
                    <div className="font-semibold">Revenue</div>
                    <div className="text-gray-700 font-normal">
                      Revenue generated from all project sites.
                    </div>
                  </div>
                  {currency && (
                    <div className="">
                      <div className="text-end">Select currency</div>
                      <Select
                        style={{
                          width: "180px",
                        }}
                        placeholder={"Select currency"}
                        onChange={(e) => {
                          setcurrency(e);
                        }}
                        defaultValue={currency}
                        options={
                          data?.cumulativeStats
                            ? Object.keys(data?.cumulativeStats).map((i) => {
                                return {
                                  value: i,
                                  label: i,
                                };
                              })
                            : []
                        }
                      />
                    </div>
                  )}
                </div>
                <Rule />
                <div className="h-[460px]">
                  {!data?.siteStats ? (
                    <div className="">
                      <Empty description="No record to show. Select a large date range" />
                    </div>
                  ) : (
                    <RevenueChart
                      currency={currency}
                      data={data?.siteStats}
                      height={450}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* second row */}
        <div className="hidden grid-cols-1 md:grid-cols-3 lg:grid-cols-3 mb-2 gap-2">
          <div className="bg-white drop-shadow-md rounded h-[200px]">
            <div className="p-2">
              <h2 className="my-0 font-semibold">Customer Support </h2>
              <p className="my-0  text-[13px]">
                Summary of all open/closed tickets you have today
              </p>
              <div className="grid grid-cols-2 gap-2 mt-2">
                <div className="flex items-center flex-col justify-between border-[1px] rounded p-2 h-[130px] w-full">
                  <h4 className="text-red-400">Open tickets</h4>
                  <h2 className="text-[45px] text-red-400 font-bold">123</h2>
                </div>
                <div className="flex items-center flex-col justify-between border-[1px] rounded p-2 h-[130px] w-full">
                  <h4 className="text-green-600">Closed tickets</h4>
                  <h2 className="text-[45px] text-green-600 font-bold">123</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 ">
            <div className="bg-white p-2 drop-shadow-md rounded h-[200px]">
              <div className="">
                <h2 className="my-0">Project site</h2>
                <p className="text-gray-700 text-[13px] my-0">
                  List of all your project sites
                </p>
              </div>
              <div className="flex mt-3 items-center flex-col justify-between border-[1px] rounded p-2 h-[130px] w-full">
                <h4 className="text-green-600 mt-5"></h4>
                <h2 className="text-[45px]  font-bold">28</h2>
              </div>
            </div>
            <div className="bg-white drop-shadow-md rounded h-[200px]"></div>
          </div>
          {/* <div className="bg-white drop-shadow-md rounded h-[200px]"></div> */}
        </div>

        <div className=" hidden grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2">
          {rolesInSystem.map((i, index) => (
            <RoleOverviewCard key={index} data={i} />
          ))}
        </div>
        {/* third row */}
        <div className="p-[10px] bg-white rounded drop-shadow-md my-2">
          <div className="flex items-center justify-between">
            <div className="font-semibold">Project Site Location</div>
            <div
              className=" cursor-pointer"
              onClick={() => {
                setshowmap((prev) => !prev);
              }}
            >
              {showmap ? (
                <IoIosArrowUp size={20} color={themeColor.black} />
              ) : (
                <IoIosArrowDown size={20} color={themeColor.black} />
              )}
            </div>
          </div>
          {showmap && (
            <div className={`h-[500px] mt-2 rounded border-[1px]`}>
              <ProjectSiteLocation data={sitemapData?.sites ?? []} zoom={4} />
            </div>
          )}
        </div>
        {/* Roles Section */}
        <div className="mb-2">
          <div className="bg-white flex item-center justify-between drop-shadow-md  rounded p-2">
            <h2 className="mb-0">Recent roles created</h2>
            <Link
              className="text-black font-light text-[13px]"
              to="/userroles/projectzone?role=All"
            >
              View all{" "}
            </Link>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2">
          {/* Project Manager */}
          <div className="bg-white drop-shadow-md  rounded p-2 h-[400px]">
            <div className="flex items-center justify-between mb-3">
              <div className="font-semibold ">Project Managers</div>
              <div className="">
                <Link
                  className="text-black font-light text-[13px]"
                  to="/userroles/projectzone?role=Project Manager"
                >
                  View all{" "}
                </Link>
              </div>
            </div>
            {allRolesStatus === "pending" ? (
              <ListLoader rows={6} />
            ) : (
              <div className="h-full">
                {allRolesStatus !== "pending" && projectManagers.length == 0 ? (
                  <div className="flex items-center justify-center h-[75%] w-[100%] ">
                    <Empty description="No project manager has been added" />
                  </div>
                ) : (
                  projectManagers.map((pm, i) => (
                    <Link
                      key={pm?.roleid}
                      className="text-black"
                      to={`/dashboard/projectsiteinfo/${pm?.siteid}/${pm?.username}`}
                    >
                      <RoleCard
                        username={pm?.username}
                        key={pm?.roleid}
                        role={pm?.role}
                        sitename={pm?.sitename}
                      />
                    </Link>
                  ))
                )}
              </div>
            )}
          </div>
          {/* Customer Representative */}
          <div className="bg-white rounded hidden drop-shadow-md p-2 h-[400px]">
            <div className="flex items-center justify-between mb-3">
              <div className="font-semibold ">Customer Representatives</div>
              <div className="">
                <Link
                  className="text-black font-light text-[13px]"
                  to="/userroles/projectzone?role=Customer Representative"
                >
                  View all{" "}
                </Link>
              </div>
            </div>
            {allRolesStatus === "pending" ? (
              <ListLoader rows={6} />
            ) : (
              <div className="h-full">
                {allRolesStatus !== "pending" &&
                customerRepresentative.length == 0 ? (
                  <div className="flex items-center justify-center  h-[75%] w-[100%] ">
                    <Empty description="No project manager has been added" />
                  </div>
                ) : (
                  customerRepresentative.map((cr, i) => (
                    <Link
                      key={cr?.roleid}
                      className="text-black font-light text-[13px]"
                      to={`http://172.29.105.37:3000/tickets`}
                    >
                      <RoleCard
                        username={cr?.username}
                        key={cr?.roleid}
                        role={cr?.role}
                        sitename={cr?.sitename}
                      />
                    </Link>
                  ))
                )}
              </div>
            )}
          </div>
          {/* SALES AGENT */}
          <div className="bg-white rounded drop-shadow-md p-2 h-[400px]">
            <div className="flex items-center justify-between mb-3">
              <div className="font-semibold ">Sales Agents</div>
              <div className=" ">
                <Link
                  className="text-black font-light text-[13px]"
                  to="/userroles/projectzone?role=Sales Agent"
                >
                  View all{" "}
                </Link>
              </div>
            </div>
            {allRolesStatus === "pending" ? (
              <ListLoader rows={6} />
            ) : (
              <div className="text-primary h-full">
                {allRolesStatus !== "pending" && salesManagers.length == 0 ? (
                  <div className="flex items-center justify-center  h-[75%] w-[100%] ">
                    <Empty description="No sales manager has been added" />
                  </div>
                ) : (
                  salesManagers.map((sm, i) => (
                    <Link
                      className="text-black font-light text-[13px]"
                      to={`/dashboard/salesagent/${sm?.siteid}/${sm?.username}`}
                    >
                      <RoleCard
                        key={sm?.roleid}
                        username={sm?.username}
                        role={sm?.role}
                        sitename={sm?.sitename}
                      />
                    </Link>
                  ))
                )}
              </div>
            )}
          </div>
          {/* Customer Manager */}
          <div className="bg-white rounded drop-shadow-md p-2 h-[400px]">
            <div className="flex items-center justify-between mb-3">
              <div className="font-semibold ">Customer Managers</div>
              <div className="">
                <Link
                  className="text-black font-light text-[13px]"
                  to="/userroles/projectzone?role=Customer Manager"
                >
                  View all{" "}
                </Link>
              </div>
            </div>
            {allRolesStatus === "pending" ? (
              <ListLoader rows={6} />
            ) : (
              <div className="text-primary h-full">
                {allRolesStatus !== "pending" &&
                customerManagers.length == 0 ? (
                  <div className="flex items-center justify-center h-[75%] w-[100%] ">
                    <Empty description="No customer manager has been added" />
                  </div>
                ) : (
                  customerManagers.map((cm, i) => (
                    <Link
                      className="text-primary"
                      to={`/dashboard/customermanager/${cm?.siteid}/${cm?.username}`}
                    >
                      <RoleCard
                        username={cm?.username}
                        key={cm?.roleid}
                        role={cm?.role}
                        sitename={cm?.sitename}
                      />
                    </Link>
                  ))
                )}
              </div>
            )}
          </div>
        </div>

        <div className="p-4 bg-white rounded drop-shadow-md mt-4">
          <div className="flex items-center justify-between">
            <div className="">
              <div className="font-semibold">Power quality data</div>
              <div className="text-gray-500 font-light">
                Power quality data from active meter from project site .{" "}
              </div>
            </div>
            <div className="">
              <div className="italic font-light text-sm text-end">
                Select a date range
              </div>
              <div className="">
                <DateRangePicker />
              </div>
            </div>
          </div>
          <div className="h-[500px]">
            <PowerQualityChart />
          </div>
        </div>
      </div>
      <div className="h-[80px] w-full"></div>
    </Layout>
  );
};

export default SysAdminDashboard;
