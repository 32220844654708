import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { userController } from "../../../controllers/UserController";
import React from "react";

import TableSkimmer from "../../../components/TableSkimmer";
import { useSelector } from "react-redux";
import Layout from "../../../components/Layout";
import CustomerWithoutMeteTable from "../../../components/CustomerManager/Tables/CustomerWithoutMeteTable";

const ZoneCustomers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputname, setinputname] = useState("");
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const [count, setCount] = useState({
    total: 0,
    active: 0,
    inactive: 0,
  });
  const [refresh, setRefresh] = useState(false);
  const [domain, setdomain] = useState("");
  const [columns, setColumns] = useState([]);
  const [filter, setfilter] = useState("all");

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        setLoading(true);
        let res = await axios.post(
          "/customer/getallcustomeraccountswithoutmeter",
          {}
        );
        let result = userController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        setData(result.message.body);

        setCount({
          total: result.message.meta.count,
        });
        return () => {
          controller.abort();
        };
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    })();
  }, [refresh, filter]);

  const filteredData = data.filter(
    (i) =>
      i.customerid.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i.lastname.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i.firstname.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i.address.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i.agent.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i.customertype.toLowerCase().trim().includes(inputname.toLowerCase())
  );

  return (
    <Layout>
      <div
        style={{
          marginBottom: 200,
        }}
      >
        <div className=" ">
          <div className="mt-1">
            <div className=" bg-white p-2 rounded-md shadow-md">
              <div className="flex items-center ">
                <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
                <div className="font-semibold ml-2">
                  Total registered users in your company without meter
                </div>
              </div>
              {/* <div className="hidden grid-cols-1 md:grid-cols-4 lg:grid-cols-3 my-1 gap-4">
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100">
                    <FaUsers size={25} />
                  </div>
                  <div className="pt-2 font-light">Total Registered Users</div>
                  <div className="pt-2 font-bold text-3xl">{count.total}</div>
                </div>
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100">
                    <FaUsers size={25} />
                  </div>
                  <div className="pt-2 font-light">Total Active Users</div>
                  <div className="pt-2 font-bold text-3xl">{count.active}</div>
                </div>
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100">
                    <FaUsers size={25} />
                  </div>
                  <div className="pt-2 font-light">Total Suspended Users</div>
                  <div className="pt-2 font-bold text-3xl">
                    {count.inactive}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="mt-2 bg-white  rounded-md px-2 shadow-md ">
            <div className="flex items-center pt-4 justify-end">
              <input
                placeholder="Enter search word"
                value={inputname}
                onChange={(e) => setinputname(e.target.value)}
                className="h-[40px] px-2 bg-gray-100 w-[250px] rounded"
              />
            </div>
            <hr className="mt-3" />
            <div
              className="grid grid-cols-1 h-[80vh] md:grid-cols-1 lg:grid-cols-1"
              style={{}}
            >
              {loading ? (
                <TableSkimmer entries={12} />
              ) : (
                <>
                  <CustomerWithoutMeteTable
                    columns={columns}
                    data={filteredData}
                    refreshBtn={setRefresh}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ZoneCustomers;
