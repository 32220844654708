import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const AdminSiteRevenue = ({ data = {}, currency, selectedRevenue }) => {
  // Check if required props are present
  if (!currency || !selectedRevenue || !selectedRevenue.key) {
    console.error("Missing required props in AdminSiteRevenue");
    return <div>Getting required data</div>;
  }

  // Transform data into the format expected by the chart
  const chartData = Object.entries(data)
    .map(([key, site]) => {
      const currencyData = site[currency];
      if (!currencyData) {
        console.error(`Missing currency data for ${currency} in site ${key}`);
        return null;
      }
      const value = currencyData[selectedRevenue.key];
      if (value === undefined) {
        console.error(`Missing ${selectedRevenue.key} data for site ${key}`);
        return null;
      }
      return {
        siteName: site.siteName || `Site ${key}`,
        value: value,
      };
    })
    .filter(Boolean); // Remove null entries

  const labels = chartData.map((item) => item.siteName);
  const values = chartData.map((item) => item.value);

  if (labels.length === 0 || values.length === 0) {
    console.error("No valid data to display");
    return <div>Error: No valid data to display</div>;
  }

  return (
    <Bar
      data={{
        labels: labels,
        datasets: [
          {
            label: selectedRevenue?.title ?? "",
            backgroundColor: "rgba(255, 159, 64, 0.6)",
            borderColor: "rgba(255, 159, 64, 0.6)",
            data: values,
            borderWidth: 1,
            barThickness: 30,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                let label = tooltipItem.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                label += new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: `${currency}`,
                }).format(tooltipItem.raw);
                return label;
              },
            },
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text:
                selectedRevenue?.title.trim() === "Total kWh"
                  ? "Unit (kWh)"
                  : selectedRevenue?.key === "transactionCount"
                  ? ""
                  : currency,
            },
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default AdminSiteRevenue;
