import { Select } from "antd";
import PowerQuality from "../../../../components/Dashboards/PowerQuality";

const ProjectSiteConsumption = () => {
  return (
    <div className="w-100 mt-2 bg-white p-2 shadow-md rounded-md ">
      <div className="flex h-[30px] border-b-1 border-gray-400  items-start justify-between">
        <div className="">
          <div className="font-semibold">Power quality data</div>
          <div className="text-gray-500">
            Power quality data from active meter from project site .{" "}
          </div>
        </div>
        <div className="">
          <div className="italic hidden font-light text-sm text-end">
            Select a date range
          </div>
          <div className="">
            <Select
              style={{
                width: 200,
              }}
              defaultValue={"energy"}
              placeholder="Select data..."
              options={[
                { value: "energy", label: "Energy" },
                { value: "voltage", label: "Voltage" },
                { value: "Current", label: "Current" },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="h-[550px]">
        <PowerQuality />
      </div>
    </div>
  );
};

export default ProjectSiteConsumption;
