import { useSelector } from "react-redux";
import { events } from "../../../../utils/events";
import Backdrop from "../../../Backdrop";
import { Button } from "../../../Button";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { useForm } from "react-hook-form";
import { errorBlock } from "../../../../controllers/errorBlock";
import { userController } from "../../../../controllers/UserController";
import axios from "../../../../utils/axios";
import { useState } from "react";
import toast from "react-hot-toast";
import BottomModal from "../../../GeneralModals/BottomModal";
import AllSiteDiscountTable from "../Table/AllSiteDiscountTable";
import { CustomButton } from "../../../CustomButton";
import { Select } from "antd";

const SitediscountMultiple = ({ open, setopen, sites, refreshBtn }) => {
  const { sitename, siteid } = useSelector((state) => state.projectSite);
  const { username } = useSelector((state) => state.userDetails);
  const [loading, setloading] = useState(false);
  const [selectedzone, setselectedzone] = useState({
    allSelected: false,
    selectedCount: 0,
    selectedRows: [],
  });
  const [type, settype] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleDiscount = async (data) => {
    try {
      if (selectedzone.selectedCount < 1) {
        return toast.error("Select atleast one zone");
      }

      setloading(true);

      let zones = selectedzone.selectedRows.map((i) => Number(i?.siteid));

      let res = await axios.post(
        "/salesdiscount/operation/bulkinsertsitediscounts",
        {
          siteidarray: JSON.stringify(zones),
          discounttype: type,
          discountpercentage: data.discount,
        }
      );

      let result = userController(res);

      if (result.type != "success") {
        toast.error(result.message);
        setloading(false);

        return;
      }

      if (result.message.response == "success") {
        toast.success("Operation successful.");
        setloading(false);
        setopen(false);
        refreshBtn((prev) => !prev);
        return;
      }

      toast.success(result.message.response);
      setopen(false);
      setloading(false);
    } catch (error) {
      errorBlock(error);
      setloading(false);
    }
  };

  return (
    <BottomModal open={open} setopen={setopen}>
      <div className="w-full h-screen animate__animated animate__fadeInUp">
        <div
          className="bg-white px-3 rounded-t-[20px] h-[95vh] absolute w-full"
          style={{
            bottom: 0,
          }}
        >
          <div className="pt-2 flex items-start justify-between">
            <div className="">
              <h3 className="font-semibold mb-0">
                Apply sales discount to multiple site
              </h3>
              <p>
                Discount will be applied to all sales agent in the selected
                project site
              </p>
            </div>

            <CloseButton closeBtn={setopen} />
          </div>
          <hr />
          <div className="">
            <div className="pb-3 flex pt-2 items-center justify-between">
              <div className="font-semibold mt-2">
                All project site you manage
              </div>
              {selectedzone.selectedCount > 0 && (
                <div className="flex items-center">
                  <Select
                    size="large"
                    style={{
                      width: 200,
                      borderRadius: 10,
                      fontSize: 14,
                    }}
                    bordered
                    placeholder={"Select discount type."}
                    onChange={(e) => settype(e)}
                    options={[
                      {
                        value: "site_discount",
                        label: "Site discount",
                      },
                      {
                        value: "agent_discount",
                        label: "Agent discount",
                      },
                    ]}
                  />
                  <form
                    onSubmit={handleSubmit(handleDiscount)}
                    className="flex items-center border-[1px] ml-2 p-[2px] border-gray-200 rounded-sm"
                  >
                    <input
                      {...register("discount", {
                        required: true,
                      })}
                      type="number"
                      className="px-[2px] text-[14px] placeholder:text-[14px] outline-none"
                      placeholder="Enter discount"
                    />
                    <CustomButton
                      disable={loading}
                      className={
                        "bg-black flex items-center justify-center h-[35px] w-[100px] rounded-md"
                      }
                    >
                      <p className="text-white m-0">
                        {loading ? "Apply..." : "Apply"}
                      </p>
                    </CustomButton>
                  </form>
                  {errors.discount && (
                    <span className="text-rose-400">Field is required</span>
                  )}
                </div>
              )}
            </div>

            <hr />

            <AllSiteDiscountTable
              data={sites?.body ? sites?.body : []}
              setselectedzone={setselectedzone}
            />
          </div>
        </div>
      </div>
      {/* <div className="w-screen h-screen flex items-center justify-end animate__animated animate__fadeInUp"></div> */}
    </BottomModal>
  );
};

export default SitediscountMultiple;

// <div className="w-full  m-auto bg-white drop-shadow-md rounded-sm p-4">
//   <div className="flex items-start w-full justify-between">
//     <div className="font-semibold text-[17px]">
//       Apply project site discount {sitename} ----
//     </div>
//     <div className="flex  mt-[-5px] justify-end">
//       <CloseButton closeBtn={setopen} />
//     </div>
//   </div>
//   <div className="text-gray-700 mt-2 font-normal">
//     You are about to apply a discount to all sales agent in {sitename}
//     <span className="text-secondary font-semibold"> {sitename}</span>{" "}
//   </div>
//   <Rule />
//   <div>
//     {/*  */}
//     <form className="mt-4" onSubmit={handleSubmit(handleDiscount)}>
//       <div className="mt-3">
//         <label className="text-primary font-normal  text-sm">
//           Enter discount
//         </label>
//         <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[40px]">
//           <input
//             type="number"
//             {...register("discount", {
//               required: true,
//             })}
//             placeholder={"Enter discount percentage"}
//             className="p-2 bg-transparent placeholder:font-thin w-full outline-none border-[0px]"
//           />
//         </div>
//       </div>
//       {errors.discount && (
//         <span className="text-rose-400">Field is required</span>
//       )}

//       <div className="mt-6">
//         <Rule />
//       </div>
//       <Button loading={loading} text={"Apply discount"} />
//     </form>
//   </div>
// </div>;
