import { AiOutlineEye, AiOutlineKey, AiOutlineMail } from "react-icons/ai";
import { SiLastpass } from "react-icons/si";
import { Button } from "../../../components/Button";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Switch } from "antd";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";
import ErrorText from "../../../components/Errors/ErrorText";

const ForgotPassword = () => {
  const [sent, setsent] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onCodeRequest = async () => {
    try {
    } catch (error) {
      console.log();
    }
  };

  const onChangePassword = async () => {
    try {
    } catch (error) {
      console.log();
    }
  };

  return (
    <div className="h-screen w-screen h-100 bg-gray-50 flex flex-row items-center justify-center">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="">
        <div className="">
          <div className=""></div>
        </div>
        <div className="bg-white w-[95%] md:w-[480px] h-auto drop-shadow-md rounded-md p-6">
          <div className="text-center font-semibold text-[18px] text-gray-900">
            {sent ? "Update password" : "Chanels password recovery"}
          </div>
          <hr className="my-2" />
          <div className="w-100">
            <div className="text-center w-100 font-light text-primary text-1xl pb-2">
              {sent
                ? "Enter the 4-digits code alongside your new password"
                : "A 4-digits code will be sent to your email."}
            </div>
            {/* Email */}
            {sent ? (
              <form onSubmit={handleSubmit(onChangePassword)}>
                {/* code */}
                <div className="">
                  <label className="text-primary font-normal text-sm">
                    Code
                  </label>
                  <div className="flex items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
                    <div className="d-inline flex items-center justify-center px-2">
                      <SiLastpass
                        color="text-primary"
                        className="text-primary"
                      />
                    </div>
                    <div className="ml-2 w-100">
                      <input
                        type={"number"}
                        {...register("code", {
                          required: true,
                        })}
                        placeholder={"Enter your 4-digits code here"}
                        className=" placeholder:text-sm outline-none border-[0px] "
                      />
                    </div>
                  </div>
                  {errors.code && <ErrorText />}
                </div>
                {/* Password */}
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    New Password
                  </label>
                  <div className="flex items-center justify-between w-100 border-[1px] rounded border-gray-300 mt-2 h-[45px]">
                    <div className="flex w-full">
                      <div className="d-inline flex items-center justify-center px-2">
                        <AiOutlineKey
                          color="text-primary"
                          className="text-primary"
                        />
                      </div>
                      <input
                        type={"text"}
                        {...register("password", {
                          required: true,
                        })}
                        placeholder={"Enter your new password"}
                        className="ml-2 w-full placeholder:text-sm placeholder:font-normal outline-none border-[0px]"
                      />
                    </div>
                  </div>
                  {errors.password && <ErrorText />}
                </div>
                <div className="flex items-center mt-4 justify-between">
                  <div className="text-primary font-normal text-sm">
                    Logout from all device
                  </div>
                  <div className="flex items-center">
                    <Switch
                      unCheckedChildren="No"
                      checkedChildren="Yes"
                      size="default"
                      // loading={loading}
                      // onChange={() => handleSwitch(data?.powerstate)}
                      // checked={data?.powerstate == 1 ? true : false}
                    />
                  </div>
                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmit(onCodeRequest)} className="mt-4">
                <div className="flex items-center w-100 bg-gray-100 border-[1px] rounded border-gray-300 mt-2 h-[45px]">
                  <div className="d-inline flex items-center justify-center px-2">
                    <AiOutlineMail
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <input
                    type={"text"}
                    placeholder={"Enter your email address"}
                    className="w-full bg-transparent placeholder:text-sm placeholder:font-normal outline-none border-[0px]"
                  />
                </div>
              </form>
            )}

            {/* Button */}
            <div className=" w-100 mt-4">
              {sent ? (
                <Button text={"Continue"} loading={false} />
              ) : (
                <Button text={"Send Code"} loading={false} />
              )}
            </div>

            <div className="w-100 mt-4">
              <Link
                to="/"
                className="underline mt-4 w-100 block cursor-pointer text-sm  text-secondary text-center"
              >
                Already have an account? Log in.
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
