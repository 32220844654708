import { useEffect, useState } from "react";
import BackBtn from "../../../../components/BackBtn";
import Layout from "../../../../components/Layout";
import ProjectSiteLocation from "../../../../components/ProjectManager/ProjectSite/Map/ProjectSiteLocation";
import { errorBlock } from "../../../../controllers/errorBlock";
import { useGetAllSiteMapMutation } from "../../../../services/map.services";

const AllProjectSiteLocation = () => {
  const [refresh, setrefresh] = useState(true);

  const [
    getAllSiteMap,
    { isLoading: sitemapLoading, error: sitemapError, data: sitemapData },
  ] = useGetAllSiteMapMutation();

  useEffect(() => {
    getAllSiteMap().unwrap();
  }, [refresh]);

  useEffect(() => {
    if (sitemapError) {
      errorBlock(sitemapError);
    }
  }, [sitemapError]);
  return (
    <div className="w-full mt-4 h-auto p-4 rounded shadow-md bg-white">
      <hr className="my-3" />
      <div className="flex items-center pb-1 justify-between">
        <div className="font-semibold">
          Location of project site in your company
        </div>
      </div>
      <div className="h-[700px] rounded ">
        <ProjectSiteLocation data={sitemapData?.sites ?? []} zoom={4} />
      </div>
    </div>
  );
};

export default AllProjectSiteLocation;
