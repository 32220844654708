import "./styles.css";
import { useState } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import axios from "../../../../utils/axios";
import toast from "react-hot-toast";
import { BsTrash, BsTrash2 } from "react-icons/bs";
import Rule from "../../../Rule/Index";
import Backdrop from "../../../Backdrop";
import { CustomButton } from "../../../CustomButton";

const DeleteSite = ({ setopen, open, site, refresh, selectedSite }) => {
  const [loading, setLoading] = useState(false);
  const [sitename, setSitename] = useState("");

  const { Option } = Select;

  const deleteProjectSite = () => {
    setLoading(true);
    axios
      .post("/sitemanagement/deletesite", { sitename: selectedSite })
      .then((response) => {
        setLoading(false);
        toast.success("Project site has been deleted");
        refresh((prev) => !prev);
        setopen(false);
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message);
      });
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[450px] lg:w-[450px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <BsTrash size={14} />
              <div className=" ml-2">Delete {selectedSite?.sitename}</div>
            </div>

            <div className="cursor-pointer" onClick={() => setopen(false)}>
              Close
            </div>
          </div>

          <div className="my-4 text-center">
            <div className="">
              <p>
                {" "}
                Sitename: <span className="font-semibold">
                  {selectedSite}
                </span>{" "}
              </p>{" "}
            </div>
            Are you sure you want to delete this project site? <br />
            This operation is irreversable!
          </div>
          <Rule />
          <div className="w-100 mt-4">
            <CustomButton
              loading={loading}
              onClick={() => deleteProjectSite(true)}
              className={
                "p-2 w-full rounded bg-red-500 flex items-center justify-center"
              }
            >
              <p className="m-0 text-white">Yes, delete</p>
            </CustomButton>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default DeleteSite;
