import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "Ikeja",
};

export const domainSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateDomain: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { updateDomain } = domainSlice.actions;

export const domainReducer = domainSlice.reducer;
