import moment from "moment";
import { RiHashtag } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { themeColor as color } from "../../../../constant/color";
import "./style.css";
import { getInitials } from "../../../../utils/helpers";
const RolesCard = ({ role, sitename, username, link }) => {
  const navigate = useNavigate();
  return (
    <Link
      to={link}
      className="flex items-center justify-between my-2 even:bg-gray-50 p-1 cursor-pointer rounded-sm"
    >
      <div className="flex items-center">
        <div className="rounded-full text-black bg-gray-200 font-semibold  p-3 w-10 h-10 flex items-center justify-center">
          {getInitials(username)}
        </div>
        <div className="ml-3">
          <div className="text-primary">{username ? username : "N/A"}</div>
          <div className="text-gray-400 text-[13px] font-light">
            Site managed:{" "}
            <span className="font-semibold text-gray-800">{sitename}</span>
          </div>
        </div>
      </div>
      <div>
        <IoIosArrowForward size={10} />
      </div>
    </Link>
  );
};

export default RolesCard;
