import toast from "react-hot-toast";
import { generalController } from "../../controllers/generalController";
import axios from "axios";
import { logoutUser, userLoggedIn } from "../slice/authslice";
import { LOG_IN, LOG_OUT } from "../types";
import { getToken } from "../../utils/token";
import { errorBlock } from "../../controllers/errorBlock";
import { headers } from "../../utils/headers";

export const postLogin = () => async (dispatch, getState) => {
  try {
    console.log("Logged In");
    dispatch(userLoggedIn());
  } catch (error) {
    console.log(error);
  }
};

export const postLogout = () => async (dispatch, getState) => {
  try {
    const username = await getToken("spiral_username");
    const sessionid = await getToken("spiral_token");
    const res = await axios.post(
      "https://api.mms.chanels.io/auth/logout",
      {
        username,
        sessionid,
      },
      headers
    );

    const result = generalController(res);

    if (result.type != "success") {
      return toast.error("Logout failed");
    }

    dispatch(logoutUser());
    localStorage.clear();
    toast.success("Logout successfully.");

    window.location.href = "/";
  } catch (error) {
    console.log(error.message);
    errorBlock(error);
  }
};
