import { MdOutlineGasMeter } from "react-icons/md";

import "./styles.css";
import { Link } from "react-router-dom";
import { IoFlashOutline } from "react-icons/io5";
import { RiUserAddLine } from "react-icons/ri";
import { Select } from "antd";

const SiteDetailsCardRight = ({
  title,
  value,
  imgType,
  caption,
  bg,
  border,
  link,
  currency,
}) => {
  const { Option } = Select;
  return (
    <div className="border p-1 rounded flex align-center justify-between ">
      <div className="flex flex-col justify-between ">
        <div className="">{title}</div>
        <div className="">
          <Link to={link ?? "/"} className="underline text-blue-600">
            Click to explore {imgType} over time{" "}
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-start justify-between">
        <div className="">
          {imgType == "energy" && (
            <div className="">
              <IoFlashOutline color="orange" size={30} />
            </div>
          )}
          {imgType == "revenue" && (
            <div className=" bg-secondary text-white rounded text-center p-1">
              {currency ?? "-Nil-"}
            </div>
          )}
          {imgType == "customer" && (
            <div className="">
              <RiUserAddLine color="orange" size={30} />
            </div>
          )}
          {imgType == "dcu" && (
            <div className="">
              <MdOutlineGasMeter color="orange" size={30} />
            </div>
          )}

          <p className="text-3xl m-0 mt-6 text-end">
            {value}
            {imgType == "energy" && <span className="text-[14px]">kWh</span>}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SiteDetailsCardRight;
