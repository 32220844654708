import { useEffect, useState } from "react";
import {
  useParams,
  useSearchParams,
  Link,
  Outlet,
  useNavigate,
} from "react-router-dom";
import BackBtn from "../../../components/BackBtn";

import StatsCard from "../../../components/Dashboards/StatsCard";
import Layout from "../../../components/Layout";

import axios from "../../../utils/axios";
// import axios from "axios"
import moment from "moment";
import TableTop from "../../../components/TableTop";
import Rule from "../../../components/Rule/Index";
import SalesLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesLineChart";
import { useDispatch, useSelector } from "react-redux";
import { errorBlock } from "../../../controllers/errorBlock";
import RevenueChart from "../../../components/Dashboards/SystemAdmin/Chart/RevenueChart";
import { useAsync } from "../../../utils/Hooks/useAsync";
import { client } from "../../../utils/api";
import { setCountry } from "../../../redux/slice/projectsiteSlice";
import { IoIosLink } from "react-icons/io";
import ProjectsiteManageStats from "../../ProjectManager/Tabs/ProjectsiteManageStats";
import toast from "react-hot-toast";
import MeterClassTable from "../../../components/ProjectManager/MeterClass/Table/MeterClassTable";
const ProjectManagerDashboard = () => {
  const dispatch = useDispatch();

  let projectSiteName = useSelector((state) => state.projectSite).sitename;
  let projectSiteId = useSelector((state) => state.projectSite).siteid;
  const navigate = useNavigate();
  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  // sitename = decodeURI(sitename);
  const [sitedetails, setsitedetails] = useState({});
  const [startDate, setStartDate] = useState(
    moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [refresh, setRefresh] = useState(false);
  const [projectManagers, setProjectManagers] = useState([]);
  const [customerManagers, setCustomerManagers] = useState([]);
  const [meters, setMeters] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [tabstate, settabstate] = useState(1);

  // cmRoles
  const {
    data: cmRoles,
    run: getCmRoles,
    status: cmRoleStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  // get all Customer manager
  useEffect(() => {
    getCmRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(projectSiteId)]),
          role: "Customer Manager",
        },
        method: "POST",
      })
    );
  }, [getCmRoles]);

  // crRoles
  const {
    data: crRoles,
    run: getCrRoles,
    status: crRoleStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  // get all Customer representative
  useEffect(() => {
    getCrRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(projectSiteId)]),
          role: "Customer Representative",
        },
        method: "POST",
      })
    );
  }, [getCrRoles]);

  // sl roles
  const {
    data: slRoles,
    run: getSlRoles,
    status: slRoleStatus,
  } = useAsync({
    data: [],
    status: "pending",
  });

  useEffect(() => {
    getSlRoles(
      client(`/roles/getallusersbyzoneandrole`, {
        data: {
          projectzonearray: JSON.stringify([Number(projectSiteId)]),
          role: "Sales Agent",
        },
        method: "POST",
      })
    );
  }, [getSlRoles]);

  // get site details
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/sitemanagement/getspecificsitebysiteid", {
          siteid: projectSiteId,
        });
        setsitedetails(res.data);

        dispatch(setCountry(res.data.country));
      } catch (err) {
        errorBlock(err);
      }
    })();
  }, []);

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="">
          <BackBtn text={"Go back"} />
          <div className=""></div>
        </div>
        {/* stats and Project site */}
        <div className="grid grid-cols-2 gap-4">
          <div className=" p-2 w-full bg-white shadow-md rounded-md">
            <div className="font-semibold">Project site details</div>
            <div className="font-bold text-[30px] mt-1">{projectSiteName}</div>
            <Rule />
            <div className="py-2 flex items-center justify-between">
              <div className="">Site type:</div>
              <div className="font-semibold">
                {sitedetails ? sitedetails?.sitetype : ""}
              </div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">GPS:</div>
              {sitedetails?.gps ? (
                <Link
                  to={`/projectsite/location?lat=${
                    sitedetails.gps.split(",")[0]
                  }&long=${sitedetails.gps.split(",")[1]}&siteid=${
                    sitedetails?.siteid
                  }`}
                  className=" flex items-center justify-end font-semibold underline"
                >
                  {sitedetails ? sitedetails?.gps : ""}
                  <div className="">
                    <IoIosLink className="" size={15} />
                  </div>
                </Link>
              ) : (
                <div className=" flex items-center justify-end font-semibold underline">
                  {sitedetails ? sitedetails?.gps : ""}
                  <div className="">
                    <IoIosLink className="" size={15} />
                  </div>
                </div>
              )}
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">Country:</div>
              <div className="font-semibold">
                {sitedetails?.country ?? "-Nil-"}
              </div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">Timezone:</div>
              <div className="font-semibold">
                {sitedetails?.timezone ?? "-Nil-"}
              </div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">Date created:</div>
              <div className="font-semibold">
                {sitedetails
                  ? moment(sitedetails?.datecreated).format("lll")
                  : ""}
              </div>
            </div>
          </div>
          <div className="row bg-white shadow-md rounded-md">
            <StatsCard
              meters={meters}
              meterTxt="Total meters in site"
              customerManagers={crRoles?.count ?? 0} // Customer Representative
              customers={cmRoles?.count ?? 0} // customer
              revenue={slRoles?.count ?? 0} // Sales Agent
            />
          </div>
        </div>

        {/* Switch tabs */}
        <div className="bg-white shadow-md rounded mt-2 p-2">
          <div className="">
            <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
              <div
                onClick={() => {
                  settabstate(1);
                  navigate("/dashboard/projectmanager");
                }}
                className={
                  tabstate == 1
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center rounded-md cursor-pointer px-2 py-[3px] mx-1  text-[14px]`
                }
              >
                Site Overview
              </div>

              <div
                onClick={() => {
                  settabstate(3);
                  navigate("/dashboard/projectmanager/consumption-analytics");
                }}
                className={
                  tabstate == 3
                    ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                    : `text-center rounded-md cursor-pointer px-2 py-[3px] mx-1 text-[14px] `
                }
              >
                View consumption analytics
              </div>
              {/* <div
                onClick={() => {
                  settabstate(4);
                  navigate("/dashboard/projectmanager/topup-history");
                }}
                className={
                  tabstate == 4
                    ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                    : `text-center border-[1px] border-gray-300 rounded-md cursor-pointer px-2 py-[3px] mx-1 text-gray-400 text-[14px] `
                }
              >
                Top up history
              </div> */}
            </div>
          </div>
        </div>
        <Outlet />

        {tabstate == 1 && <ProjectsiteManageStats />}
      </div>
      <div className="h-[300px]"></div>
    </Layout>
  );
};

export default ProjectManagerDashboard;
