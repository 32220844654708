import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { getToken } from "../../utils/token";
import { channelController } from "../../controllers/channelController";
import { toast } from "react-hot-toast";
import Rule from "../../components/Rule/Index";
import { useForm } from "react-hook-form";
import { AiOutlineUser } from "react-icons/ai";
import { Button } from "../../components/Button";
import moment from "moment";
import Skimmer from "../../components/Loader/Skimmer";
import ErrorText from "../../components/Errors/ErrorText";
import { errorBlock } from "../../controllers/errorBlock";
// import UserImg from "../../assets/svg/285655_user_icon.svg";
const Settings = ({}) => {
  const [profile, setprofile] = useState(null);
  const [loading, setloading] = useState({
    data: true,
    save: false,
  });
  const [tabstate, settabstate] = useState(1);
  const [oldpassword, setoldpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      let username = await getToken("spiral_username");
      let res = await axios.post("/auth/resetpassword", {
        // callerid, sessionid, password, newpassword, logoutofotherdevices(Boolean type)
        password: oldpassword,
        newpassword: newpassword,
        logoutofotherdevices: false,
        // subdomain: "ikj",
        // username,
      });
      console.log(res.data);
      let { response } = res.data;
      if (response == "success") {
        toast.success("Password updated sucessfully");
      } else {
        toast.error("Password update failed.");
      }
    } catch (err) {
      errorBlock(err);
    }
  };

  const handleReset = async () => {
    try {
      if (!(oldpassword && newpassword))
        return toast.error("Both values are required");

      let res = await axios.post("/auth/resetpassword", {
        password: oldpassword,
        newpasswords: newpassword,
        logoutofotherdevices: false,
        subdomain: "ikj",
      });

      let { response } = res.data;
      if (response == "success") {
        toast.success("Password updated successfully");
      } else {
        toast.error("Failed to update password.");
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        let username = await getToken("spiral_username");
        let res = await axios.post("/auth/useraccount/getuser", {
          username,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setloading((prev) => {
            return {
              ...prev,
              data: false,
            };
          });
          return;
        }
        if (!result?.message.firstname || !result?.message.lastname) {
          setloading((prev) => {
            return {
              ...prev,
              data: false,
            };
          });
          return;
        }
        setprofile(result.message);
        setloading((prev) => {
          return {
            ...prev,
            data: false,
          };
        });
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.response);
          setloading((prev) => {
            return {
              ...prev,
              data: false,
            };
          });
          return;
        }
        toast.error(err.message);
        setloading((prev) => {
          return {
            ...prev,
            data: false,
          };
        });
      }
    })();
    return () => {};
  }, []);

  return (
    <Layout>
      <div className="mt-2">
        <div className="font-normal hidden text-gray-900 my-3 text-[16px]">
          Profile page
        </div>
        {/* {tabstate === 1 ? ( */}
        <div className="bg-white h-[90vh] overflow-hidden rounded-lg drop-shadow-md  relative">
          <div className="absolute h-[80px] w-[80px] rounded-full border-[2px] bg-white drop-shadow-sm left-[20px] top-[150px]">
            {/* <img src={UserImg} alt={"user image"} /> */}
          </div>
          <div className="h-[200px] bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"></div>

          {/* tabs */}
          <div className="mt-[50px] px-3 mb-4">
            <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
              <div
                onClick={() => settabstate(1)}
                className={
                  tabstate == 1
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center cursor-pointer px-2 py-1 text-[14px]`
                }
              >
                Account
              </div>
              <div
                onClick={() => settabstate(2)}
                className={
                  tabstate == 2
                    ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                    : `text-center cursor-pointer text-[14px] px-2 py-1 `
                }
              >
                Change password
              </div>
            </div>
          </div>

          <Rule />
          <div className="px-3 pb-3">
            {tabstate === 1 ? (
              <div className="flex flex-col justify-center items-center mt-4">
                <div className="flex items-center justify-between p-1 mt-3 w-full border-gray-300 md:w-[70%] border-[1px] rounded-md ">
                  <div className="font-light tetx-gray-700">Full name:</div>
                  <div className="font-semibold text-gray-800 px-3 ">
                    {loading.data ? (
                      <Skimmer width={"100px"} heigth={"30px"} />
                    ) : (
                      profile?.firstname + " " + profile?.lastname
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between p-1 mt-3 w-full border-gray-300 md:w-[70%] border-[1px] rounded-md ">
                  <div className="font-light text-gray-700">Username:</div>
                  <div className="font-semibold text-gray-800 px-3">
                    {loading.data ? (
                      <Skimmer width={"100px"} heigth={"30px"} />
                    ) : (
                      profile?.username
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between p-1 mt-3 w-full border-gray-300 md:w-[70%] border-[1px] rounded-md ">
                  <div className="font-light text-gray-700">Email:</div>
                  <div className="font-semibold text-gray-800 px-3">
                    {loading.data ? (
                      <Skimmer width={"100px"} heigth={"30px"} />
                    ) : (
                      profile?.email
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between p-1 mt-3 w-full border-gray-300 md:w-[70%] border-[1px] rounded-md ">
                  <div className="font-light text-gray-700">Status:</div>
                  {loading.data ? (
                    <Skimmer width={"100px"} heigth={"30px"} />
                  ) : profile?.activestatus == "active" ? (
                    <div className="font-normal px-3 text-green-600">
                      {profile?.activestatus}
                    </div>
                  ) : (
                    <div className="font-normal px-3 text-red-600">
                      {profile?.activestatus}
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-between p-1 mt-3 w-full border-gray-300 md:w-[70%] border-[1px] rounded-md ">
                  <div className="font-light text-gray-700">
                    Date registered:
                  </div>
                  <div className="font-semibold px-3 text-gray-800">
                    {loading.data ? (
                      <Skimmer width={"100px"} heigth={"30px"} />
                    ) : (
                      moment(profile?.dateregistered).format("lll")
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center mt-4 ">
                {/* <div className="font-semibold">Change password</div> */}
                <form
                  className="w-full md:w-[350px]"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="mt-4">
                    <label className="text-primary font-light text-[14px]">
                      Old password
                    </label>
                    <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[43px] w-full">
                      <div className="d-inline flex items-center justify-center px-2">
                        <AiOutlineUser
                          size={18}
                          color="text-primary"
                          className="text-primary"
                        />
                      </div>
                      <div className="ml-2">
                        <input
                          required
                          value={oldpassword}
                          {...register("oldpassword", { required: true })}
                          onChange={(e) => setoldpassword(e.target.value)}
                          type={"text"}
                          placeholder={"Enter your old passsword"}
                          className=" placeholder:text-[13px] placeholder:font-thin w-full outline-none border-[0px]"
                        />
                      </div>
                    </div>
                    {errors.oldpassword && (
                      <ErrorText text={"Please enter your old passsword"} />
                    )}
                  </div>
                  <div className="mt-4">
                    <label className="text-primary font-light text-[14px]">
                      New password
                    </label>
                    <div className="flex bg-white items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[43px] w-full">
                      <div className="d-inline flex items-center justify-center px-2">
                        <AiOutlineUser
                          size={18}
                          color="text-primary"
                          className="text-primary"
                        />
                      </div>
                      <div className="ml-2">
                        <input
                          required
                          value={newpassword}
                          {...register("newpassword", { required: true })}
                          onChange={(e) => setnewpassword(e.target.value)}
                          type={"text"}
                          placeholder={"Enter your new old password"}
                          className=" placeholder:text-[13px] placeholder:font-thin w-full outline-none border-[0px]"
                        />
                      </div>
                    </div>
                    {errors.oldpassword && (
                      <ErrorText text={"Please enter your new passsword"} />
                    )}
                  </div>
                  <div className="mt-5 w-full">
                    <Button text={"Update"} loading={loading.save} />
                  </div>
                </form>
                {/* </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
