import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const siteMapAPI = createApi({
  reducerPath: "siteMapAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers) => {
      // Add any additional headers if needed
    },
  }),
  endpoints: (build) => ({
    getAllSiteMeterMap: build.mutation({
      query: ({ siteid }) => ({
        url: "sitemanagement/mapanalytics/getalldevicesbysiteid",
        method: "POST",
        body: {
          ...credentials,
          siteid: Number(siteid),
        },
      }),
    }),
    getAllSiteMap: build.mutation({
      query: () => ({
        url: "sitemanagement/mapanalytics/getallsiteswithdetails",
        method: "POST",
        body: {
          ...credentials,
        },
      }),
    }),
    getMetersInDCUMap: build.mutation({
      query: ({ dcuid, siteid }) => ({
        url: "sitemanagement/mapanalytics/getmetersbydcuid",
        method: "POST",
        body: {
          ...credentials,
          dcuid: dcuid,
          siteid: Number(siteid),
        },
      }),
    }),
  }),
});

export const {
  useGetAllSiteMeterMapMutation,
  useGetAllSiteMapMutation,
  useGetMetersInDCUMapMutation,
} = siteMapAPI;
