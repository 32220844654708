import { useSelector } from "react-redux";
import { events } from "../../../../utils/events";
import Backdrop from "../../../Backdrop";
import { Button } from "../../../Button";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { useForm } from "react-hook-form";
import { errorBlock } from "../../../../controllers/errorBlock";
import { userController } from "../../../../controllers/UserController";
import axios from "../../../../utils/axios";
import { useState } from "react";
import toast from "react-hot-toast";

const SitediscountSingle = ({ open, setopen, refreshBtn }) => {
  const { sitename, siteid } = useSelector((state) => state.projectSite);
  const { username } = useSelector((state) => state.userDetails);
  const [loading, setloading] = useState(false);
  const [agentdetails, setagentdetails] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleDiscount = async (data) => {
    try {
      setloading(true);
      let res = await axios.post(
        "/salesdiscount/operation/createsitediscount",
        {
          siteid: Number(siteid),
          discountpercentage: data.discount,
          projectmanagerusername: username,
        }
      );

      let result = userController(res);

      if (result.type != "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }

      if (result.message.response == "success") {
        toast.success("Operation successful.");
        setloading(false);
        setopen(false);
        refreshBtn((prev) => !prev);
        return;
      }

      toast.success(result.message.response);
      setopen(false);
      setloading(false);
    } catch (error) {
      errorBlock(error);
      setloading(false);
    }
  };

  //
  return (
    <Backdrop open={open} setopen={setopen}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[500px] m-auto bg-white drop-shadow-md rounded-sm p-4">
          <div className="flex items-start w-full justify-between">
            <div className="font-semibold text-[17px]">
              Apply project site discount {sitename}
            </div>
            <div className="flex  mt-[-5px] justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <div className="text-gray-700 mt-2 font-normal">
            You are about to apply a discount to all sales agent in {sitename}
            <span className="text-secondary font-semibold">
              {" "}
              {sitename}
            </span>{" "}
          </div>
          <Rule />
          <div>
            {/*  */}
            <form className="mt-4" onSubmit={handleSubmit(handleDiscount)}>
              <div className="mt-3">
                <label className="text-primary font-normal  text-sm">
                  Enter discount
                </label>
                <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[40px]">
                  <input
                    type="number"
                    {...register("discount", {
                      required: true,
                    })}
                    placeholder={"Enter discount percentage"}
                    className="p-2 bg-transparent placeholder:font-thin w-full outline-none border-[0px]"
                  />
                </div>
              </div>
              {errors.discount && (
                <span className="text-rose-400">Field is required</span>
              )}

              <div className="mt-6">
                <Rule />
              </div>
              <Button loading={loading} text={"Apply discount"} />
            </form>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default SitediscountSingle;
