import { Select } from "antd";
import FilterRevenue from "./sysadmin/modals/FilterRevenue";
const FilterDateRange = ({
  open,
  setopen,
  handlefilter,
  setEndDate,
  setStartDate,
  setRefresh,
}) => {
  return (
    <div className="">
      {open && (
        <FilterRevenue
          open={open}
          setopen={setopen}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          refreshBtn={setRefresh}
        />
      )}

      <Select
        defaultValue={"today"}
        className="w-[200px]"
        onChange={(e) => {
          if (e == "custom") {
            setopen(true);
            return;
          }
          handlefilter(e);
        }}
        placeholder="bottom"
        options={[
          { value: "today", label: "Today" },
          { value: "week", label: "This week" },
          { value: "month", label: "This month" },
          { value: "custom", label: "Use custom date" },
        ]}
      />
    </div>
  );
};

export default FilterDateRange;
