// import { applyMiddleware, compose, createStore } from "redux";
// import { thunk } from "redux-thunk";
// import { rootReducer } from "./reducers";
// import { composeWithDevTools } from "@redux-devtools/extension";
// import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export function configureStore(preloadedstate) {
//   const middleware = [thunk];
//   const middlewareEnhancers = applyMiddleware(...middleware);

//   const enhancers = [middlewareEnhancers];
//   const composeEnhancers = composeWithDevTools(...enhancers);

//   const store = createStore(persistedReducer, preloadedstate, composeEnhancers);

//   let persistor = persistStore(store);

//   if (process.env.NODE_ENV !== "production" && module.hot) {
//     module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
//   }
//   return { store, persistor };
// }

import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootReducer, RootState } from "./reducers";
import { meterAPI } from "../services/meter.services";
import { DCUAPI } from "../services/dcu.services";
import { customerClassAPI } from "../services/customerclass.services";
import { customerAPI } from "../services/customer.services";
import { revenueAPI } from "../services/revenue.services";
import { salesanalyticsAPI } from "../services/salesanalytics.services";
import { rolesAPI } from "../services/roles.services";
import { projectSiteAPI } from "../services/projectsite.services";
import { siteMapAPI } from "../services/map.services";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    meterAPI.reducerPath,
    DCUAPI.reducerPath,
    customerClassAPI.reducerPath,
    customerAPI.reducerPath,
    salesanalyticsAPI.reducerPath,
    revenueAPI.reducerPath,
    rolesAPI.reducerPath,
    projectSiteAPI.reducerPath,
    siteMapAPI.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }).concat(
      meterAPI.middleware,
      DCUAPI.middleware,
      customerClassAPI.middleware,
      customerAPI.middleware,
      revenueAPI.middleware,
      salesanalyticsAPI.middleware,
      rolesAPI.middleware,
      projectSiteAPI.middleware,
      siteMapAPI.middleware
    ),
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
