import Layout from "../../components/Layout";
import TopNav from "../../components/TopNav";
import UserTable from "../../components/Users/Tables/UserTable";
import TableTop from "../../components/TableTop";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "../../utils/axios";
import { userController } from "../../controllers/UserController";
import TopCard from "../../components/TopCard";
import React from "react";
import { Button } from "../../components/Button";
import { FaUsers } from "react-icons/fa";

/** AntD Table */
import AntDTable from "../../components/Table/AntdTable";
import { CamelCaseToTitleCase } from "../../../src/utils/helpers";
import { themeColor as color } from "../../constant/color";
import TableSkimmer from "../../components/TableSkimmer";
import { getToken } from "../../utils/token";
import { Input, Select } from "antd";
import { useSelector } from "react-redux";

const Users = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const [count, setCount] = useState({
    total: 0,
    active: 0,
    inactive: 0,
  });
  const [refresh, setRefresh] = useState(false);
  const [columns, setColumns] = useState([]);
  const [inputname, setinputname] = useState("");
  const [filter, setfilter] = useState("all");

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        // const storedDomain = getToken("spiral_domain");
        // setdomain(storedDomain);
        setLoading(true);
        let res = await axios.post(
          "/auth/customeraccount/getallregisteredusers",
          {
            limit: 100,
            offset: 0,
          }
        );
        let result = userController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        setData(() => {
          if (filter == "all") {
            return result.message.body;
          } else if (filter == "active") {
            return result.message.body.filter(
              (i) => i.activestatus == "active"
            );
          } else if (filter == "inactive") {
            return result.message.body.filter(
              (i) => i.activestatus == "inactive"
            );
          }
        });

        setCount({
          total: result.message.meta.count,
          active:
            result.message.body.filter((i) => i.activestatus == "active")
              .length ?? 0,
          inactive:
            result.message.body.filter((i) => i.activestatus == "inactive")
              .length ?? 0,
        });
        return () => {
          controller.abort();
        };
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    })();
  }, [refresh, filter]);

  const filteredData = data.filter(
    (i) =>
      i?.activestatus.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i?.email.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i?.firstname.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i?.lastname.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i?.username.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i?.phone.toLowerCase().trim().includes(inputname.toLowerCase())
  );
  return (
    <Layout>
      <div
        style={{
          marginBottom: 200,
        }}
      >
        <div className=" ">
          <div className="mt-2">
            <div className=" bg-white p-2 rounded-md shadow-md">
              <div className="h-[50px]">
                <div className="flex items-center ">
                  <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
                  <div className="font-semibold ml-2">
                    Total registered staff in your company
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 my-1 gap-4">
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100">
                    <FaUsers size={25} />
                  </div>
                  <div className="pt-2 font-light">Total Registered Staff</div>
                  <div className="pt-2 font-bold text-3xl">{count.total}</div>
                </div>
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100">
                    <FaUsers size={25} />
                  </div>
                  <div className="pt-2 font-light">Total Active Staff</div>
                  <div className="pt-2 font-bold text-3xl">{count.active}</div>
                </div>
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100">
                    <FaUsers size={25} />
                  </div>
                  <div className="pt-2 font-light">Total Suspended Staff</div>
                  <div className="pt-2 font-bold text-3xl">
                    {count.inactive}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 bg-white rounded-md p-2 shadow-md ">
            <div className="flex items-center justify-end">
              <div className=" w-[200px] mb-3">
                <Input
                  size="large"
                  onChange={(e) => setinputname(e.target.value)}
                  className="placeholder:font-light"
                  placeholder="Search for a staff..."
                />
              </div>
            </div>
            {loading ? (
              <TableSkimmer entries={12} />
            ) : (
              <>
                <div className="hidden items-center pt-4 justify-end">
                  <Select
                    style={{
                      width: 150,
                    }}
                    defaultValue={"all"}
                    className="w-[150px]"
                    onChange={(e) => {
                      if (e == "all") {
                        setfilter("all");
                      } else if (e == "active") {
                        setfilter("active");
                      } else if (e == "inactive") {
                        setfilter("inactive");
                      }
                    }}
                    options={[
                      { value: "all", label: "All users" },
                      { value: "active", label: "Active users" },
                      { value: "inactive", label: "Inactive users" },
                    ]}
                  />
                </div>

                <UserTable
                  columns={columns}
                  data={filteredData}
                  refreshBtn={setRefresh}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default Users;
