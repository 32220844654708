import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlinePhone,
  AiOutlineUser,
} from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getToken, setToken } from "../../../utils/token";
import axios from "axios";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";
import { headers } from "../../../utils/headers";

const CompleteRegistration = () => {
  let navigate = useNavigate();
  const [eye, setEye] = useState(false);
  const [password, setPassword] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!firstname || !lastname || !email || !phone) {
        return toast.error("All values are required");
      }
      let callerid = await getToken("spiral_username");
      let sessionid = await getToken("spiral_token");
      setLoading(true);
      let res = await axios.post(
        "https://api.mms.chanels.io/auth/completeregistration",
        {
          callerid: callerid,
          sessionid,
          firstname,
          lastname,
          phone,
          email,
        },
        headers
      );
      if (res.data.response == "failure") {
        setLoading(false);
        return toast.error("Operation failed. Please try again");
      }

      if (res.data.response == "invalid session") {
        setLoading(false);
        toast.error(res.data.response);
        navigate("/");
        return;
      }

      setLoading(false);
      toast.success("Registration complete. Please log in to continue.");
      await setToken("spiral_registration", 1);

      return navigate("/");
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="h-screen bg-gray-50 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-items-center place-content-center gap-10">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="w-[95%] z-50 md:w-[450px] mx-auto bg-white rounded-md shadow-md">
        <div className="text-center pt-4 text-primary font-semibold text-2xl">
          Chanels
        </div>
        <div className=" w-[full] h-auto rounded-md px-6">
          <div className="font-noraml text-center">
            Fill the fields below to complete registration.
          </div>
          <hr className="mt-4" />
          <div className="mt-4">
            {/* Firstname */}
            <div className="">
              <div className="flex items-center w-100 border-[1px] rounded bg-gray-100 border-gray-300 mt-2 h-[45px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    type={"text"}
                    placeholder={"Enter your firstname"}
                    className="outline-none border-[0px] bg-transparent w-full"
                  />
                </div>
              </div>
            </div>
            {/* Lastname */}
            <div className="mt-4">
              <div className="flex items-center w-100 border-[1px] rounded bg-gray-100 border-gray-300 mt-2 h-[45px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    type={"text"}
                    placeholder={"Enter your lastname"}
                    className="  outline-none border-[0px] bg-transparent w-full"
                  />
                </div>
              </div>
            </div>
            {/* Email */}
            <div className="mt-4">
              <div className="flex items-center w-100 border-[1px] rounded bg-gray-100 border-gray-300 mt-2 h-[45px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type={"email"}
                  placeholder={"Enter your email"}
                  className="ml-2 w-ful outline-none border-[0px] bg-transparent w-full"
                />
              </div>
            </div>
            {/* Phone */}
            <div className="mt-4">
              <div className="flex items-center w-100 border-[1px] rounded bg-gray-100 border-gray-300 mt-2 h-[45px] ">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlinePhone
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type={"number"}
                  placeholder={"Enter your phone number"}
                  className="ml-2 w-full outline-none border-[0px] bg-transparent"
                />
              </div>
            </div>
            {/* Button */}
            <div className=" w-100 mt-7">
              <Button
                text={"Continue"}
                loading={loading}
                onClick={handleSubmit}
              />
            </div>

            <div className="w-100 mt-4 flex justify-center text-center mb-6">
              <p className="pr-1">Already have an account. </p>
              <Link
                to="/"
                className="underline w-100 block cursor-pointer text-sm font-light text-secondary text-center"
              >
                <span className=""> Log in.</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteRegistration;
