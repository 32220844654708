import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const RevenueChart = ({ data, height, currency }) => {
  let tabKey = "totalRevenue";
  // const labels = !data
  //   ? []
  //   : Object.keys(data).map((item) => data[item]?.siteName);

  // const sample = !data
  //   ? []
  //   : Object.keys(data).map((item) => data[item][currency]?.totalRevenue);

  const chartData = Object.entries(data)
    .map(([key, site]) => {
      const currencyData = site[currency];
      if (!currencyData) {
        console.error(`Missing currency data for ${currency} in site ${key}`);
        return null;
      }
      const value = currencyData[tabKey];
      if (value === undefined) {
        console.error(`Missing ${tabKey} data for site ${key}`);
        return null;
      }
      return {
        siteName: site.siteName || `Site ${key}`,
        value: value,
      };
    })
    .filter(Boolean); // Remove null entries

  const labels = chartData.map((item) => item.siteName);
  const values = chartData.map((item) => item.value);

  return (
    <Bar
      data={{
        labels: labels, // Site names as x-axis labels
        datasets: [
          {
            label: "Total Revenue",
            backgroundColor: "#008FFB",
            borderColor: "#008FFB",
            // data: !data ? [] : data.map((i) => i?.transactionCount),
            data: values,
            borderWidth: 1,
            barThickness: 30,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                // Custom tooltip formatting
                let label = tooltipItem.dataset.label || "";

                if (currency) {
                  if (label) {
                    label += ": ";
                  }

                  label += new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: `${currency}`,
                  }).format(tooltipItem.raw);
                }

                return label;
              },
            },
          },
        },
        scales: {
          x: {
            // stacked: false, // Ensure bars are side by side
            display: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false, // Ensure bars are not stacked
            display: true,
            title: {
              display: true,
              text: `${currency ?? ""}`,
            },
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default RevenueChart;
