import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const DCUAPI = createApi({
  reducerPath: "dcuPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      //   const token = credentials.token;
      //   if (token) {
      //     headers.set("Version", `0.1`);
      //     return headers;
      //   }
    },
  }),
  endpoints: (build) => ({
    // unassign roles
    getDCUs: build.mutation({
      query: (data) => ({
        url: "sitemanagement/dcu/getdcusfromsite",
        method: "POST",
        body: {
          ...credentials,
          siteid: Number(data),
        },
      }),
    }),
  }),
});

export const { useGetDCUsMutation } = DCUAPI;
