import { useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import AddDCUToSite from "../modals/AddDCUToSite";
import DCUInfoModal from "../modals/DCUInfoModal";
import { CustomButton } from "../../../CustomButton";
import toast from "react-hot-toast";
import { errorBlock } from "../../../../controllers/errorBlock";
import axios from "../../../../utils/axios";
import { meterController } from "../../../../controllers/MeterController";
import ViewMeterOnDCU from "../modals/ViewMeterOnDCU";
import { tableCustomStyles } from "../../../../helperFuncs";

const AllDCUInSite = ({ data, loading, refreshbtn, tabstate }) => {
  const [details, setdetails] = useState(null);
  const [showinfo, setshowinfo] = useState(false);
  const [selected, setselected] = useState(null);
  const [loadingDCU, setloadingDCU] = useState(false);
  const [open, setopen] = useState(false);
  const { siteid } = useSelector((state) => state.projectSite);

  const handleRemoveDCU = async (data) => {
    try {
      setloadingDCU(true);
      let res = await axios.post("/sitemanagement/dcu/removedcufromsite", {
        siteid: Number(siteid),
        dcuid: data?.dcuid,
      });

      let result = meterController(res);

      if (result.type !== "success") {
        setloadingDCU(false);
        toast[result.type](result.message);
        return;
      }
      toast.success("DCU added to site succefully.");
      setopen(false);
    } catch (err) {
      errorBlock(err);
      setloadingDCU(false);
    }
  };

  const columns = [
    {
      name: "DCU Alias",
      center: true,
      selector: (row) => row.dcualias,
      grow: 2,
    },
    {
      name: "DCU Id",
      center: true,
      selector: (row) => row.dcuid,
      grow: 3,
    },
    {
      name: "Date Added",
      center: true,
      selector: (row) => row.dateadded,
      grow: 2,
    },
    {
      name: "Action",
      selector: (row) => row.id, // Assuming you have an id field for each row
      grow: 4,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <div
              onClick={() => {
                setselected(item);
                setopen(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-secondary text-[12px] text-sky-600 font-light px-4 py-2"
            >
              <p className="m-0 text-secondary">View meters on DCU</p>
            </div>
            <CustomButton
              loading={selected?.dcu == item?.dcu && loadingDCU ? true : false}
              className={
                "border-[1px] cursor-pointer ml-2 bg-red-400 rounded border-red-400 text-white text-[12px] font-light px-4 py-2"
              }
              onClick={() => {
                setselected(item);
                handleRemoveDCU(item);
              }}
            >
              <p className="m-0">Remove DCU</p>
            </CustomButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {open && (
        <ViewMeterOnDCU detials={selected} open={open} setopen={setopen} />
      )}

      {details && (
        <DCUInfoModal open={showinfo} setopen={setshowinfo} dcuinfo={details} />
      )}

      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        customStyles={tableCustomStyles}
        paginationPerPage={10}
      />
    </>
  );
};

export default AllDCUInSite;
