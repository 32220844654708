import Layout from "../../../../components/Layout";
import PageTitle from "../../../../components/PageTitle";
import moment from "moment";
import { useEffect, useState } from "react";
import SalesLineChart from "../../../../components/Dashboards/SalesAgent/Charts/SalesLineChart";
import DateRangePicker from "../../../../components/DateRangePicker";
import CustomerTable from "../../../../components/Dashboards/SalesAgent/Table/CustomerTable";
import { HiBuildingLibrary } from "react-icons/hi2";
import { GiPayMoney, GiWallet } from "react-icons/gi";
import { themeColor } from "../../../../constant/color";
import { useNavigate, useParams } from "react-router-dom";
import SalesAgentRevenueChart from "../../../../components/Dashboards/SalesAgent/Charts/doughnutChart";
import SalesAgentsCategoryLineChart from "../../../../components/Dashboards/SalesAgent/Charts/SalesAgentsCategoryLineChart";
import TopSalesAgent from "../../../../components/Dashboards/SalesAgent/Cards/TopSalesAgent";
import toast from "react-hot-toast";
import axios from "../../../../utils/axios";
import SalesAgentTable from "../../../../components/Dashboards/SalesAgent/Table/SalesAgentTable";
import BackBtn from "../../../../components/BackBtn";
import { Select } from "antd";
import { useSelector } from "react-redux";
import SaleRecordsLineChart from "../../../../components/SalesAgent/Charts/SaleRecordsLineChart";
import { countryList } from "../../../../helperFuncs/countryList";
import {
  useGetSalesBySysAdminMutation,
  useGetTopPerformingAgentSiteWideMutation,
} from "../../../../services/salesanalytics.services";
import { errorBlock } from "../../../../controllers/errorBlock";
import { currencyOptions } from "../../../Wallet/walletData";
import AdminSiteRevenue from "../../../../components/Dashboards/SystemAdmin/Chart/AdminSiteRevenue";
import WalletCard from "../../../../components/Wallet/Cards/WalletCards";
import Rule from "../../../../components/Rule/Index";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { CustomButton } from "../../../../components/CustomButton";
import { GoArrowBoth } from "react-icons/go";
import {
  formatTransactionTitle,
  formatUnits,
  revenueFields,
} from "../../../../helperFuncs";
import FilterRevenue from "../../../../components/sysadmin/modals/FilterRevenue";

const DomainRevenue = () => {
  const [tabindex, settabindex] = useState(0);
  const [selectedRevenue, setselectedRevenue] = useState(revenueFields[0]);

  const [tabstate, settabstate] = useState(1);
  const [currency, setcurrency] = useState("");
  const [datefilter, setdatefilter] = useState("today");

  const [startdate, setStartDate] = useState(
    moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [refresh, setrefresh] = useState(false);

  const [getSalesBySysAdmin, { isLoading, error, data }] =
    useGetSalesBySysAdminMutation();

  useEffect(() => {
    getSalesBySysAdmin({ startdate, enddate }).unwrap();
  }, [refresh]);

  useEffect(() => {
    if (error) {
      errorBlock(error);
    }
  }, [error]);

  const [
    getTopPerformingAgentSiteWide,
    { isLoading: agentLoading, error: agentError, data: agentData },
  ] = useGetTopPerformingAgentSiteWideMutation();

  useEffect(() => {
    getTopPerformingAgentSiteWide({ limit: 10, startdate, enddate }).unwrap();
  }, [refresh]);

  useEffect(() => {
    if (agentError) {
      errorBlock(agentError);
    }
  }, [agentError]);

  const [loading, setloading] = useState(isLoading ?? true);
  const [modal, setmodal] = useState(false);

  const formattedValue = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "decimal",
      // minimumFractionDigits: 2,
      // maximumFractionDigits: 2,
    }).format(value);

  useEffect(() => {
    setloading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (data?.cumulativeStats) {
      if (Object.keys(data?.cumulativeStats).length > 0) {
        setcurrency(Object.keys(data?.cumulativeStats)[0]);
      }
    }
  }, [data]);

  const handleRevenuefilter = (e) => {
    switch (e) {
      case "today":
        setStartDate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );

        setEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        break;
      case "week":
        setStartDate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        setEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        break;
      case "month":
        setStartDate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        setEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        break;
      default:
        break;
    }
    setrefresh((prev) => !prev);
  };

  const SalesBalanceCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="flex items-start justify-between">
          <div className="">
            <div className="">
              <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
                {title}
              </div>
              <p className="text-[14px] text-gray-600">{caption}</p>
            </div>
          </div>
          <div className="font-semibold text-[13px] bg-secondary text-white py-1 px-2 rounded">
            {/* {country ? countryList[country]["Currency Code"] : "-Nil-"} */}
            {currency}
          </div>
        </div>
        <div className="text-3xl font-semibold">
          {Number(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          <sup className="mt-[-10px] text-[12px]">
            {/* {currencyOptions[currency].tag}{" "} */}
            {currency}
          </sup>
        </div>
      </div>
    );
  };

  const AgentTopCard = ({ title, value, caption, icon, type }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="">
          <div className="">
            <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
              {title}
            </div>
            <p className="text-[14px] text-gray-600">{caption}</p>
          </div>
        </div>
        <div className="text-3xl font-semibold">
          {type == "count" ? value : formattedValue(value)}
          {(type == "sales" || type == "balance" || type == "spent") && (
            <sup className="mt-[-10px] text-[12px]">
              {/* {currencyOptions[currency].tag}{" "} */}
              {currency}
            </sup>
          )}
          {type == "units" && <sup className=" text-[12px] font-bold">kWh</sup>}
        </div>
      </div>
    );
  };

  return (
    <Layout>
      {modal && (
        <FilterRevenue
          open={modal}
          setopen={setmodal}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          refreshBtn={setrefresh}
        />
      )}
      {loading ? (
        <div className="bg-white rounded drop-shadow  w-fit p-2">
          Loading...
        </div>
      ) : (
        <>
          {/* first vp */}
          <div className="bg-white p-2 shadow rounded">
            <BackBtn />
            <div className="flex items-center justify-between">
              <div className="flex items-center ">
                <div className="h-[30px]  mr-2 w-[20px] rounded bg-sky-200"></div>
                <PageTitle
                  title={"Overview of sales"}
                  caption={"Insight of sales activities across in your company"}
                />
              </div>

              <div className="flex">
                <Select
                  className="w-[180px]"
                  onChange={(e) => {
                    if (e == "custom") {
                      return setmodal(true);
                    }
                    handleRevenuefilter(e);
                  }}
                  placeholder={"Select a date range"}
                  options={[
                    { value: "today", label: "Today" },
                    { value: "week", label: "This week" },
                    { value: "month", label: "This month" },
                    { value: "custom", label: "Use custom date" },
                  ]}
                />
              </div>
            </div>
            <Rule />
            <div className="flex items-center justify-between">
              <div className="">
                <p className="m-0">Showing search result for: </p>
                <p className="m-0 flex">
                  <span className="font-semibold">
                    {moment(startdate).format("lll")}
                  </span>{" "}
                  <div className="flex items-center justify-center px-3">
                    <GoArrowBoth />
                  </div>
                  <span className="font-semibold">
                    {moment(enddate).format("lll")}
                  </span>{" "}
                </p>
              </div>
              {/* Currency picker */}
              <div className="">
                <p className="m-0 text-end text-[12px]">Currency</p>
                <Select
                  defaultValue={
                    data?.cumulativeStats
                      ? Object.keys(data?.cumulativeStats)[0]
                      : ""
                  }
                  className="w-[150px]"
                  onChange={(e) => {
                    setcurrency(e);
                  }}
                  options={
                    data?.cumulativeStats
                      ? Object.keys(data?.cumulativeStats).map((i) => {
                          return {
                            value: i,
                            label: i,
                          };
                        })
                      : []
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-4 mt-2">
              <SalesBalanceCard
                title={"Total amount of sales"}
                value={data?.cumulativeStats[currency]?.totalRevenue ?? 0}
                caption={`Summary of sales made across`}
                icon={"balance"}
                type={"sales"}
              />
              <AgentTopCard
                title={"Number of transactions"}
                value={data?.cumulativeStats[currency]?.transactionCount ?? 0}
                caption={`Total number sales made `}
                icon={"balance"}
                type={"count"}
              />
              <AgentTopCard
                title={"Total units sold (kWh)"}
                type={"units"}
                value={data?.cumulativeStats[currency]?.totalKWh ?? 0}
                caption={`Total units recharged acroos all meters`}
                icon={"credit"}
              />
              <AgentTopCard
                title={"Total paid out commission"}
                value={data?.cumulativeStats[currency]?.totalCommission ?? 0}
                caption={`Total units recharged acroos all meters`}
                icon={"credit"}
                type={"sales"}
              />
              {/* <AgentTopCard title={"Pending sales"} value={id} /> */}
            </div>
          </div>
          {/* Revenue Vp */}
          <div className="bg-white p-2 mt-3 rounded shadow">
            <div className="flex my-2 justify-between items-center">
              <div className="">
                <div className="font-semibold text-[16px] ">
                  Revenue by payment methods
                </div>
                <div className="text-gray-600">
                  Stats of revenue generate from different payment methods
                </div>
              </div>
            </div>
            <Rule />
            {/* GATEWAY STATS */}
            {!data?.gatewayStats ? (
              <div className="">No site stats</div>
            ) : Object.keys(data?.gatewayStats).length == 0 ? (
              <div className=" text-center p-10">
                No sales made within the selected date range. Select a wider
                date range{" "}
              </div>
            ) : (
              Object.keys(data?.gatewayStats).map((i, index) => (
                <div className="" key={index}>
                  <div className="flex my-2 justify-between bg-gray-100 p-2 items-center">
                    <div className="font-semibold ">
                      {i[0].toUpperCase() + i.substring(1)}
                    </div>
                    <CustomButton onClick={() => settabindex(index)}>
                      {tabindex == index ? (
                        <IoIosArrowDown size={18} />
                      ) : (
                        <IoIosArrowUp size={18} />
                      )}
                    </CustomButton>
                  </div>
                  {data?.gatewayStats ? (
                    <div className="">
                      {tabindex == index && (
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
                          {Object.keys(data?.gatewayStats[i][currency]).map(
                            (value) => (
                              <WalletCard
                                value={
                                  data?.gatewayStats[i][currency][value] ?? 0
                                }
                                title={formatTransactionTitle(value)}
                                type={formatUnits(value)}
                                currencyOptions={currencyOptions}
                                currency={currency}
                                setcurrency={setcurrency}
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    "No items"
                  )}
                </div>
              ))
            )}
          </div>
          {/* second vp */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-2 mt-2">
            <div className="h-[500px] p-4 border-[1px] bg-white shadow-sm rounded">
              <div className=" items-center justify-between">
                <h2 className=" my-0 text-[15px] font-semibold">
                  Sales by customer type
                </h2>
                <p className="my-0 text-[13px]">
                  Summary of site with most sales activity
                </p>
              </div>
              <div className="w-full h-full flex items-center justify-center">
                <SalesAgentRevenueChart
                  currency={currency}
                  data={data?.siteTypeStats ?? {}}
                />
              </div>
            </div>

            <div className="h-[500px] border-[1px] bg-white shadow-sm rounded">
              <div className=" items-center justify-between p-3">
                <h2 className=" my-0 text-[15px] font-semibold">
                  Top sales agent{" "}
                </h2>
                <p className="my-0 text-[13px]">Summary of sales by projects</p>
              </div>
              <div className="">
                <TopSalesAgent
                  data={agentData?.topAgentsByCurrency ?? []}
                  currency={currency}
                />
              </div>
            </div>
          </div>
          {/* third vp */}
          <div className="grid grid-cols-1 md:grid-cols-1 gap-3 lg:grid-cols-1 mt-2">
            <div className="h p-2 bg-white shadow-sm rounded">
              <div className="flex items-center justify-between">
                <div className=" items-center justify-between">
                  <h2 className=" my-0 text-[15px] font-semibold">
                    Sales by project site{" "}
                  </h2>
                  <p className="my-0 text-[13px]">
                    Summary of sales across your site project site type
                  </p>
                </div>
                <div className="">
                  {/* tab state */}
                  <div className="px-3 ">
                    <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
                      <div
                        onClick={() => settabstate(1)}
                        className={
                          tabstate == 1
                            ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                            : `text-center cursor-pointer px-2 py-1 text-[14px]`
                        }
                      >
                        Chart
                      </div>
                      <div
                        onClick={() => settabstate(2)}
                        className={
                          tabstate == 2
                            ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                            : `text-center cursor-pointer text-[14px] px-2 py-1 `
                        }
                      >
                        Table
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center  mt-3 border-gray-200 w-full">
                <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
                  {tabstate == 1 &&
                    revenueFields.map((i) => (
                      <div className="px-1">
                        <div
                          onClick={() => setselectedRevenue(i)}
                          className={
                            selectedRevenue.id == i.id
                              ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                              : `text-center cursor-pointer text-gray-500 px-2 py-1 text-[14px]`
                          }
                        >
                          {i.title}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <Rule />
              <div className="h-[500px]">
                {/* <SalesLineChart data={sampleData.siteStats} /> */}
                {tabstate == 1 && (
                  <AdminSiteRevenue
                    selectedRevenue={selectedRevenue}
                    currency={currency}
                    data={data?.siteStats}
                  />
                )}
                {tabstate == 2 && (
                  <SalesAgentTable
                    currency={currency}
                    selectedTab={selectedRevenue}
                    data={data?.siteStats}
                  />
                )}
              </div>
            </div>

            <div className="h-[500px]  hidden p-4 border-[1px] bg-white shadow-sm rounded">
              <div className="flex items-center justify-between">
                <div className="">
                  <div className=" items-center justify-between">
                    <h2 className=" my-0 text-[15px] font-semibold">
                      Sales records
                    </h2>
                    <p className="my-0 text-[13px]">Summary of sales records</p>
                  </div>
                </div>
                <div className=""></div>
              </div>
              <SaleRecordsLineChart data={[]} height={430} />
              {/* <SalesAgentsCategoryLineChart height={400} data={[]} /> */}
            </div>
          </div>
          {/* sales tables */}
          <div className=" p-2 bg-white hidden mt-2 rounded shadow-sm ">
            <div className="">
              <h2 className=" my-0 text-[15px] font-semibold">Sales History</h2>
              <p className="my-0 text-[13px]">Record of sales across project</p>
            </div>
            <div className="overflow-x-auto hidden overflow-y-hidden">
              <div className="h-[600px] ">
                <SalesAgentTable data={[]} />
              </div>
            </div>
          </div>
        </>
      )}

      <div className="h-[100px] w-full"></div>
    </Layout>
  );
};

export default DomainRevenue;
