const Rule = ({ styles, ...rest }) => {
  return (
    <hr
      style={styles}
      {...rest}
      className="my-2 border-[0.5px] border-gray-200"
    />
  );
};

export default Rule;
