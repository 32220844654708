import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import moment from "moment";
import { useEffect, useState } from "react";
import SalesLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesLineChart";
import DateRangePicker from "../../../components/DateRangePicker";
import CustomerTable from "../../../components/Dashboards/SalesAgent/Table/CustomerTable";
import { HiBuildingLibrary } from "react-icons/hi2";
import { GiPayMoney, GiWallet } from "react-icons/gi";
import { themeColor } from "../../../constant/color";
import { useNavigate, useParams } from "react-router-dom";
import SalesAgentRevenueChart from "../../../components/Dashboards/SalesAgent/Charts/doughnutChart";
import SalesAgentsCategoryLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesAgentsCategoryLineChart";
import TopSalesAgent from "../../../components/Dashboards/SalesAgent/Cards/TopSalesAgent";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import SalesAgentTable from "../../../components/Dashboards/SalesAgent/Table/SalesAgentTable";
import BackBtn from "../../../components/BackBtn";
import { Select } from "antd";
import { useSelector } from "react-redux";
import SaleRecordsLineChart from "../../../components/SalesAgent/Charts/SaleRecordsLineChart";
import { countryList } from "../../../helperFuncs/countryList";
import { useGetSalesBySysAdminMutation } from "../../../services/salesanalytics.services";
import { errorBlock } from "../../../controllers/errorBlock";
const SalesAgentDashboardSys = () => {
  const [tabstate, settabstate] = useState(1);
  const navigate = useNavigate();

  const [startdate, setStartDate] = useState(
    moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const { role, country } = useSelector((state) => state.userDetails);
  const [refresh, setrefresh] = useState(true);

  // get sales logs
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await axios.post(
  //         "/salesanalytics/filterallsalesandpayments",
  //         {
  //           usernames,
  //           purpose,
  //           paymentsource,
  //           startamount,
  //           startamountcomparisonop,
  //           endamount,
  //           endamountcomparisonop,
  //           amountandor,
  //           projectzone: JSON.stringify(projectzone),
  //           projectmanagers: JSON.stringify(projectmanagers),
  //           assettype,
  //           assetid,
  //           startdate,
  //           enddate,
  //           operation,
  //           limit,
  //           offset,
  //         }
  //       );
  //       // Handle the response as needed
  //       console.log(res.data);
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //   })();
  // }, []);

  const percentage = 66;
  const { id } = useParams();
  const [revenueStartDate, setRevenueStartDate] = useState(
    moment(Date.now()).subtract("1", "week").format("YYYY-MM-DD HH:mm:ss")
  );
  const [revenueEndDate, setRevenueEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD H:mm:ss")
  );

  const [getSalesBySysAdmin, { isLoading, error, data }] =
    useGetSalesBySysAdminMutation();

  useEffect(() => {
    getSalesBySysAdmin({ startdate, enddate })
      .then((res) => {
        console.log(res.data, "response");
      })
      .catch((err) => {
        errorBlock(err);
      });
  }, []);

  const handleRevenuefilter = (e) => {
    switch (e) {
      case "today":
        setRevenueStartDate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );

        setRevenueEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        break;
      case "week":
        setRevenueStartDate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        setRevenueEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        break;
      case "month":
        setRevenueStartDate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        setRevenueEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
        break;
      default:
        break;
    }
    setrefresh((prev) => !prev);
  };

  const SalesBalanceCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="flex items-start justify-between">
          <div className="">
            <div className="">
              <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
                {title}
              </div>
              <p className="text-[14px] text-gray-600">{caption}</p>
            </div>
          </div>
          {/* <div className="font-semibold text-[13px] bg-secondary text-white py-1 px-2 rounded">
            {country ? countryList[country]["Currency Code"] : "-Nil-"}
          </div> */}
        </div>
        <div className="text-3xl font-semibold">{value}</div>
      </div>
    );
  };

  const AgentTopCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="">
          <div className="">
            <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
              {title}
            </div>
            <p className="text-[14px] text-gray-600">{caption}</p>
          </div>
        </div>
        <div className="text-3xl font-semibold">{value}</div>
      </div>
    );
  };

  const sampleData = {
    cumulativeStats: {
      transactionCount: 7500,
      totalSales: 1200000.0,
      totalKWh: 600000.5,
      avgTransactionValue: 160.0,
    },
    siteTypeStats: [
      {
        siteType: "Residential",
        transactionCount: 5000,
        totalSales: 500000.0,
        totalKWh: 250000.5,
        avgTransactionValue: 100.0,
      },
      {
        siteType: "Commercial",
        transactionCount: 2000,
        totalSales: 400000.0,
        totalKWh: 200000.25,
        avgTransactionValue: 200.0,
      },
      {
        siteType: "Industrial",
        transactionCount: 500,
        totalSales: 300000.0,
        totalKWh: 150000.75,
        avgTransactionValue: 600.0,
      },
    ],
    siteStats: [
      {
        siteId: 1,
        siteName: "Downtown Residential Complex",
        siteType: "Residential",
        transactionCount: 3000,
        totalSales: 300000.0,
        totalKWh: 150000.3,
        avgTransactionValue: 100.0,
      },
      {
        siteId: 2,
        siteName: "Central Business District",
        siteType: "Commercial",
        transactionCount: 1500,
        totalSales: 300000.0,
        totalKWh: 150000.15,
        avgTransactionValue: 200.0,
      },
      {
        siteId: 3,
        siteName: "Industrial Park A",
        siteType: "Industrial",
        transactionCount: 300,
        totalSales: 180000.0,
        totalKWh: 90000.45,
        avgTransactionValue: 600.0,
      },
    ],
    gatewayStats: [
      {
        paymentGateway: "sales agent",
        transactionCount: 4000,
        totalSales: 600000.0,
        totalKWh: 300000.3,
      },
      {
        paymentGateway: "paystack",
        transactionCount: 2500,
        totalSales: 400000.0,
        totalKWh: 200000.2,
      },
      {
        paymentGateway: "mtn momo rwanda",
        transactionCount: 1000,
        totalSales: 200000.0,
        totalKWh: 100000.1,
      },
    ],
  };

  return (
    <Layout>
      {/* first vp */}
      <div className="bg-white p-2 shadow rounded">
        <BackBtn />
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <div className="h-[30px]  mr-2 w-[20px] rounded bg-sky-200"></div>
            <PageTitle
              title={"Overview of Sales Agent activity"}
              caption={"Insight of sales activities across in your company"}
            />
          </div>

          <div className="">
            <DateRangePicker
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-4 mt-6">
          <SalesBalanceCard
            title={"Total amount of sales"}
            value={"0"}
            caption={`Summary of sales made across {sitename}s`}
            icon={"balance"}
          />
          <AgentTopCard
            title={"Numbers of sales"}
            value={"0"}
            caption={`Total number sales made in {sitename}`}
            icon={"balance"}
          />
          <AgentTopCard
            title={"Wallet topup"}
            value={"0"}
            caption={`Total amount of topup issued by project managers`}
            icon={"credit"}
          />
          <AgentTopCard title={"Pending sales"} value={id} />
        </div>

        {/* Switch tabs */}
        <div className="mt-2 p-2">
          <h3 className="mb-0">Switch tabs</h3>
          <hr className="my-2" />
          <div className="">
            <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
              <div
                onClick={() => {
                  settabstate(1);
                  // navigate("/dashboard/projectmanager");
                }}
                className={
                  tabstate == 1
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center border-[1px] border-gray-300 rounded-md cursor-pointer px-2 py-[3px] mx-1 text-gray-400 text-[14px]`
                }
              >
                Sales Overview
              </div>
              <div
                onClick={() => {
                  settabstate(2);
                  // navigate("/dashboard/projectmanager/revenue");
                }}
                className={
                  tabstate == 2
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center border-[1px] border-gray-300 rounded-md cursor-pointer px-2 py-[3px] mx-1 text-gray-400 text-[14px]`
                }
              >
                Top ups
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* second vp */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-2 mt-2">
        <div className="h-[500px] p-4 border-[1px] bg-white shadow-sm rounded">
          <div className=" items-center justify-between">
            <h2 className=" my-0 text-[15px] font-semibold">
              Sales by customer type
            </h2>
            <p className="my-0 text-[13px]">
              Summary of site with most sales activity
            </p>
          </div>
          <div className="w-full h-full flex items-center justify-center">
            <SalesAgentRevenueChart data={[]} />
          </div>
        </div>

        <div className="h-[500px] border-[1px] bg-white shadow-sm rounded">
          <div className=" items-center justify-between p-3">
            <h2 className=" my-0 text-[15px] font-semibold">
              Top sales agent{" "}
            </h2>
            <p className="my-0 text-[13px]">Summary of sales by projects</p>
          </div>
          <div className="">
            <TopSalesAgent data={[]} />
          </div>
        </div>
      </div>
      {/* third vp */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:grid-cols-2 mt-2">
        <div className="h p-2 bg-white shadow-sm rounded">
          <div className=" items-center justify-between p-3">
            <h2 className=" my-0 text-[15px] font-semibold">
              Sales by project site type{" "}
            </h2>
            <p className="my-0 text-[13px]">
              Summary of sales for project site type
            </p>
          </div>
          <div className="h-[400px]">
            <SalesLineChart data={[]} />
          </div>
        </div>

        <div className="h-[500px] p-4 border-[1px] bg-white shadow-sm rounded">
          <div className="flex items-center justify-between">
            <div className="">
              <div className=" items-center justify-between">
                <h2 className=" my-0 text-[15px] font-semibold">
                  Sales records
                </h2>
                <p className="my-0 text-[13px]">Summary of sales records</p>
              </div>
            </div>
            <div className="">
              {/* <Select
                defaultValue={"today"}
                style={{
                  width: 150,
                }}
                onChange={(e) => handleRevenuefilter(e)}
                options={[
                  { value: "today", label: "Today" },
                  { value: "week", label: "This week" },
                  { value: "month", label: "This month" },
                  { value: "year", label: "This year" },
                ]}
              /> */}
            </div>
          </div>
          <SaleRecordsLineChart data={[]} height={430} />
          {/* <SalesAgentsCategoryLineChart height={400} data={[]} /> */}
        </div>
      </div>
      {/* sales tables */}
      <div className=" p-2 bg-white mt-2 rounded shadow-sm ">
        <div className="">
          <h2 className=" my-0 text-[15px] font-semibold">Sales History</h2>
          <p className="my-0 text-[13px]">Record of sales across project</p>
        </div>
        <div className="overflow-x-auto overflow-y-hidden">
          <div className="h-[600px] ">
            <SalesAgentTable data={[]} />
          </div>
        </div>
      </div>
      <div className="h-[100px] w-full"></div>
    </Layout>
  );
};

export default SalesAgentDashboardSys;

//  <div className="bg-white h-[460px] hidden rounded drop-shadow-md my-6 p-4">
//         <div className="flex items-start">
//           <div className="font-semibold">
//             Wallet balance and Credit logs analytics
//           </div>
//         </div>
//         <div className="h-[400px]">
//           <SalesLineChart />
//         </div>
//       </div>
//       <div className="bg-white h-[700px] rounded drop-shadow-md pt-4 my-6 hidden">
//         <div className="flex items-start justify-between px-6">
//           <div className="font-semibold">Customer in project site</div>
//           <div className=""></div>
//         </div>
//         {/* <div className="">
//           <CustomerTable />
//         </div> */}
//       </div>
