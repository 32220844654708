import axios from "../../../utils/axios";
import moment from "moment";
import { useState, useEffect } from "react";
import CustomerCardMeter from "../../../components/Customer/Cards/CustomerCardMeter";
import DateRangePicker from "../../../components/DateRangePicker";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import AllMetersTab from "../../../components/ProjectManager/ProjectMeter/Tab/AllMetersTab";
import Rule from "../../../components/Rule/Index";
import { channelController } from "../../../controllers/channelController";
import { toast } from "react-hot-toast";
import ViewProjectMeter from "../../../components/ProjectManager/ProjectMeter/Modals/ViewProjectMeter";
import TableSkimmer from "../../../components/TableSkimmer";
import { useSelector } from "react-redux";
import AllMeterTable from "../../../components/Meter/Table/AllMeterTable";
import AssignMeter from "../../../components/Meter/modals/AssignMeter";
import AssignedMetersTable from "../../../components/CustomerManager/Tables/AssignedMetersTable";
import MeterInfoModal from "../../../components/Meter/modals/MeterInfoModal";
import { errorBlock } from "../../../controllers/errorBlock";
import { Button } from "../../../components/Button";
const CustomerManagerDashboard = () => {
  const [tabstate, settabstate] = useState(1);
  const [count, setCount] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  const siteDetails = useSelector((state) => state.projectSite);
  const userDetails = useSelector((state) => state.userDetails);

  const [loading, setloading] = useState(true);
  const [assignmodal, setassignmodal] = useState(false);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState(null);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const controller = new AbortController();
  const [addMeterModal, setAddMeterModal] = useState(false);
  const [assignedcount, setassignedcount] = useState(0);
  const [recall, setrecall] = useState(false);
  const [infomodal, setinfomodal] = useState(false);
  const [meterinfo, setmeterinfo] = useState({});
  const [meternumber, setmeternumber] = useState("");
  const [userRole, setUserRole] = useState("");
  const [customercount, setcustomercount] = useState(0);
  // const { id } = useParams();
  // get all customer count
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(`/customer/getallmetercustomerscount`, {
          zone: Number(siteDetails.siteid),
          column: "agent",
          columnparameter: userDetails.username,
        });
        setcustomercount(res.data.count);
      } catch (error) {
        errorBlock(error);
      }
    })();
  }, []);

  // get all customer analytics
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          `/customer/getallmetercustomersbyzone`,
          // `/customer/getallmetercustomeraccountsbycustomermanagerandzone`,
          {
            zone: Number(siteDetails?.siteid),
            // startdatecreated: startDate,
            // enddatecreated: endDate,
            // customermanager: userDetails.username,
          }
        );
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setloading(false);
          return;
        }
        setData(result.message.body);
        setassignedcount(result.message.meta.count);
        setloading(false);

        // setcustomercount(res.data.count);
      } catch (error) {
        errorBlock(error);
        setloading(false);
      }
    })();
  }, [recall]);

  // get all meters
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setloading(true);
  //       let res = await axios.post("/meter/getallmetersinzone", {
  //         zone: siteDetails.siteid,
  //       });
  //       let result = channelController(res);
  //       if (result.type !== "success") {
  //         toast[result.type](result.message);
  //         setloading(false);
  //         return;
  //       }
  //       setData(result.message.body);
  //       setassignedcount(result.message.meta.count);
  //       setloading(false);
  //     } catch (err) {
  //       toast.error(err.message);
  //       setloading(false);
  //     }
  //   })();
  //   return () => {
  //     controller.abort();
  //   };
  // }, [recall]);
  // meters in service, registered amd decommissioned
  // useEffect(() => {
  //   Promise.all([
  //     axios.post("/meter/countalldecommisionedmeters", {
  //       startdate: startDate,
  //       enddate: endDate,
  //     }),
  //     axios.post("/meter/countallmetersinservice", {
  //       startdate: startDate,
  //       enddate: endDate,
  //     }),
  //     axios.post("/meter/countallregisteredmeters", {
  //       startdate: startDate,
  //       enddate: endDate,
  //     }),
  //   ])
  //     .then((res) => {
  //       let response = res.map((item) => channelController(item));
  //       setCount(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   return () => {
  //     controller.abort();
  //   };
  // }, [refresh]);

  return (
    <Layout>
      {modal && (
        <ViewProjectMeter open={modal} setopen={setmodal} details={details} />
      )}

      {assignmodal && (
        <AssignMeter
          refresh={setrecall}
          setopen={setassignmodal}
          user={null}
          open={assignmodal}
        />
      )}

      {infomodal && (
        <MeterInfoModal
          meterNumber={meternumber}
          setopen={setinfomodal}
          open={infomodal}
        />
      )}

      <div className="w-full bg-white rounded-md drop-shadow-md px-2 mb-10">
        <div className="my-2 p-2 flex items-start justify-between">
          <PageTitle
            title={`Overview of customers in (${siteDetails.sitename})`}
          />
          <div className="flex">
            {/* <hr className="my-4" /> */}
            <div className="flex items-center justify-end mb-4">
              <DateRangePicker
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                refreshBtn={setRefresh}
                desc={""}
              />
            </div>
            <div className="w-[180px] ">
              <Button
                height={25}
                text={"Assign meter"}
                onClick={() => {
                  setassignmodal(true);
                }}
              />
            </div>
          </div>
        </div>

        <div className="border rounded-md py-3">
          <div className="grid grid-cols-3 gap-10 mx-auto w-[90%] p-3">
            <CustomerCardMeter
              title={"Assigned Meter"}
              caption={"Count of meters assigned."}
              value={assignedcount}
            />
            <CustomerCardMeter
              title={"Customer"}
              caption={"Count of customers in your project site."}
              value={customercount}
            />
          </div>
        </div>

        {/* table and graph */}
        <div className="hidden justify-end">
          <div className="mt-4 px-3 mb-4">
            <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
              <div
                onClick={() => settabstate(1)}
                className={
                  tabstate == 1
                    ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                    : `text-center cursor-pointer px-2 py-1 text-[14px]`
                }
              >
                Table
              </div>
              <div
                onClick={() => settabstate(2)}
                className={
                  tabstate == 2
                    ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                    : `text-center cursor-pointer text-[14px] px-2 py-1 `
                }
              >
                Chart
              </div>
            </div>
          </div>
        </div>

        {/* <Rule /> */}
        <div className="p-3 overflow-x-auto overflow-y-hidden">
          {tabstate == 1 &&
            (loading ? (
              <TableSkimmer entries={12} />
            ) : (
              <AssignedMetersTable
                data={data}
                refresh={setrecall}
                setinfomodal={setinfomodal}
                setmeternumber={setmeternumber}
              />
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default CustomerManagerDashboard;
