import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useEffect, useState, useRef } from "react";
import { Link, navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken, setToken } from "../../../utils/token";
import { toast } from "react-hot-toast";
import { errorBlock } from "../../../controllers/errorBlock";
import AuthBg from "../../../assets/png/1162369_ORH93N1345678.png";
import Typed from "typed.js";
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "../../../redux/actions/auth.action";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";
import { headers } from "../../../utils/headers";
import { userLoggedIn } from "../../../redux/slice/authslice";
import {
  updateClientId,
  updateDomain,
  updateRole,
  updateRoleCount,
  updateUsername,
} from "../../../redux/slice/userDetailSlice";
import {
  setCountry,
  setProjectSiteId,
  setProjectSiteName,
} from "../../../redux/slice/projectsiteSlice";
import Rule from "../../../components/Rule/Index";

const SignIn = () => {
  const controller = new AbortController();
  const [resetpassword, setresetpassword] = useState(true);
  const authState = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
  const [eye, seteye] = useState(false);
  let navigate = useNavigate();

  const [installPrompt, setInstallPrompt] = useState(null);

  const [prompt, setPrompt] = useState(false);

  const { role, username: user } = useSelector((state) => state.userDetails);
  const { sitename, country } = useSelector((state) => state.projectSite);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const token = localStorage.getItem("spiral_token");

  const hanldeSwitch = async (role) => {
    try {
      switch (role) {
        case "System Manager":
          navigate(`/dashboard/systemadmin`);
          return;
        case "Project Manager":
          navigate(`/dashboard/projectmanager`);
          return;
        case "Customer Representative":
          navigate(`/dashboard/customerrepresentative`);
          return;
        case "Customer Manager":
          navigate(`/dashboard/customermanager`);
          return;
        case "Sales Agent":
          navigate(`/dashboard/salesagent`);
          return;
        default:
          break;
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (token && isLoggedIn) {
      hanldeSwitch(role);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setInstallPrompt((prev) => e);
      // console.log(e);
      setPrompt((prev) => true);
      // console.log(`'beforeinstallprompt' event was fired.`);
    });
  }, []);

  const handleInstall = async () => {
    try {
      installPrompt.prompt();
      const { outcome } = await installPrompt.userChoice;
      if (outcome.includes("accepted")) {
        toast.success("App installed succesfully");
        return;
      } else if (outcome.includes("dismissed")) {
        return null;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const [tabState, settabState] = useState(1);
  let domain = "ikj";

  const handleLogin = async () => {
    try {
      if (!username || !password) {
        toast.error("All values are required.");
        return;
      }

      let clientid = String(Date.now());

      setloading(true);
      let res = await axios.post(
        "https://api.mms.chanels.io/auth/login",
        {
          username: username,
          password,
          subdomain: "ikj",
          clientid,
        },
        headers
      );

      if (res.data.response == "invalid identity") {
        setloading(false);
        return toast.error("Invalid details. Please try again.");
      }

      if (res.data.response == "disabled") {
        setloading(false);
        return toast.error("Account disabled. Contact your admin");
      }

      if (res.data.response == "reset password") {
        toast.error("Please reset your default password.");
        setloading(false);
        await setToken("spiral_registration", 0);
        await setToken("spiral_token", res.data?.sessionid);
        await setToken("spiral_username", username);
        navigate("/resetpassword");
        return;
      }

      if (res.data.response === "success") {
        if (!res.data?.registration) {
          toast.success("Please complete your registration");
          await setToken("spiral_registration", 0);
          await setToken("spiral_token", res.data?.sessionid);
          await setToken("spiral_username", username);
          setloading(false);
          navigate("/completeregistration");
          return;
        } else {
          setloading(false);
          await setToken("spiral_registration", 1);
          await setToken("spiral_token", res.data?.sessionid);
          await setToken("spiral_username", username);
          // navigate("/dashboard/systemadmin");

          // Make request to get User Role
          let rolesReq = await axios.post(
            "https://api.mms.chanels.io/roles/getuserroles",
            {
              callerid: username,
              targetusername: username,
              sessionid: res.data?.sessionid,
            },
            headers
          );
          let userRoles = rolesReq.data.body;
          if (!userRoles.length) {
            toast.error("A role has not been assigned to your account.");
            setloading(false);
            return;
          }

          dispatch(postLogin());

          dispatch(updateRoleCount(rolesReq.data.meta.count));

          dispatch(updateClientId(clientid));

          // check the length of the role
          if (userRoles.length == 1) {
            const {
              creator,
              role,
              roleid,
              country,
              siteid,
              sitename,
              username,
            } = userRoles[0];

            if (role == "System Manager") {
              dispatch(setProjectSiteName(""));
              dispatch(setProjectSiteId(""));
              dispatch(updateUsername(username));
              dispatch(updateDomain("Ikeja"));
              dispatch(setCountry(""));
              dispatch(updateRole(""));
            }
            // upate the siteid, sitename, and username
            dispatch(setProjectSiteName(sitename));
            dispatch(setProjectSiteId(siteid));
            dispatch(setCountry(country));
            dispatch(updateUsername(username));

            dispatch(updateDomain("Ikeja"));
            dispatch(updateRole(role));

            if (role == "Sales Agent") {
              window.location.pathname = "/dashboard/salesagent";
            } else if (role == "Project Manager") {
              window.location.pathname = "/dashboard/projectmanager";
            } else if (role == "Customer Manager") {
              window.location.pathname = "/dashboard/customermanager";
            } else if (role == "Customer Representative") {
              window.location.pathname = "/dashboard/customerrepresentative";
            }
            setloading(false);
            return;
          }
          dispatch(updateDomain("Ikeja"));
          window.location.pathname = "/userroles";
          setloading(false);
          controller.abort();
        }
      }
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };

  return (
    // bg-[#f4f4f4]
    <div className="h-screen grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-items-center place-content-center gap-10">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="w-[90%] z-50 md:w-[500px] mx-auto bg-white rounded-md shadow-md">
        {prompt && (
          <div className="block md:flex p-2 bg-[#e8effa] items-center justify-between border-b-[1px] border-blue-300">
            <div className="md:text-start text-center">
              Install this software on your device ?
            </div>
            <div className="w-ful md:flex flex mt-2 md:mt-0 items-center justify-center">
              <button
                onClick={() => handleInstall()}
                className="text-white cursor-pointer px-2 py-1 rounded-sm bg-green-600 mx-3 "
              >
                Install
              </button>
              <button
                onClick={() => setPrompt(!prompt)}
                className="text-white cursor-pointer px-2 py-1 rounded-sm bg-red-600 mx-3 "
              >
                Not now
              </button>
            </div>
          </div>
        )}
        <div className="px-4">
          <div className="pt-6">
            <div className="text-center text-primary font-semibold text-2xl">
              Welcome to Chanels <br />
              Management System for Utilities
            </div>
          </div>
          <div className="w-[full] h-auto rounded-md px-6 pt-4 pb-6">
            <div className="grid grid-cols-3 place-content-center gap-0">
              <div className="flex items-center justify-center ">
                <div className="border-t-[0.7px] border-gray-400 w-full"></div>
              </div>
              <div className="font-light text-center text-gray-600">
                Sign in below
              </div>
              <div className="flex items-center justify-center ">
                <div className="border-t-[0.7px] border-gray-400 w-full"></div>
              </div>
            </div>

            <div className="">
              {/* Lastname */}
              <div className="mt-4">
                <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded border-gray-300 mt-2 h-[45px]">
                  <div className="d-inline flex items-center justify-center px-2">
                    <AiOutlineUser
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="ml-2 w-full">
                    <input
                      required
                      value={username}
                      onChange={(e) => setusername(e.target.value)}
                      type={"text"}
                      placeholder={"Enter your username"}
                      className="block bg-transparent placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                    />
                  </div>
                </div>
              </div>

              {/* Password */}
              <div className="mt-4">
                <div className="flex items-center justify-end">
                  <label
                    onClick={() => navigate("/forgotpassword")}
                    className="flex text-primary underline cursor-pointer font-normal text-sm"
                  >
                    Forgot Password?
                  </label>
                </div>

                <div className="flex items-center justify-between w-100 border-[1px] rounded bg-gray-100 border-gray-300 mt-2 h-[45px]">
                  <div className="flex w-full">
                    <div className="d-inline flex items-center justify-center px-2">
                      <AiOutlineKey
                        size={20}
                        color="text-primary"
                        className="text-primary"
                      />
                    </div>
                    <div className="ml-2 w-full">
                      <input
                        value={password}
                        onChange={(e) => setpassword(e.target.value)}
                        type={eye ? "text" : "password"}
                        placeholder={"Enter your password"}
                        className=" block bg-transparent placeholder:text-sm placeholder:font-thin outline-none border-[0px] w-full"
                      />
                    </div>
                  </div>

                  <div
                    className="px-3 cursor-pointer"
                    onClick={() => seteye(!eye)}
                  >
                    {eye ? (
                      <AiOutlineEye
                        color="text-primary"
                        className="text-primary"
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        color="text-primary"
                        className="text-primary"
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* Button */}
              <div className=" w-100 mt-7">
                <Button
                  text={"Login"}
                  loading={loading}
                  onClick={handleLogin}
                />
              </div>

              <div className="w-100 mt-4">
                <div className="text-primary text-center font-normal py-3">
                  Need help with your account?{" "}
                  <a
                    className="text-secondary underline cursor-pointer"
                    href="mailto:hello@chanels.io"
                  >
                    Click here
                  </a>
                </div>
                {/* <Link
                  to="/createaccount"
                  className="underline w-100 block cursor-pointer text-sm font-light text-secondary text-center"
                >
                  <span className="font-semibold text-1xl pl-2">
                    Don't have an account? Create one
                  </span>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
