import { useState } from "react";
import { themeColor as color } from "../../../constant/color";
import CardTitle from "../../CardTitle";
import LineCharts from "../Charts/LineCharts";
import "./styles.css";
import { Select } from "antd";

const PowerQuality = () => {
  return <LineCharts />;
};

export default PowerQuality;
