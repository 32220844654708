export const stringShorter = (item, len) =>
  item.substring(0, item.length - len);

export const touFormatter = (item) =>
  item
    .substring(1)
    .split("#")
    .map((item) => {
      return {
        pt: parseInt(item.substring(item.length - 3, item.length)) + "%",
        st:
          item.substring(0, 6).substring(0, 2) +
          ":" +
          item.substring(0, 6).substring(2, 4) +
          ":" +
          item.substring(0, 6).substring(4, 6),
        et:
          item.substring(6, 13).substring(0, 2) +
          ":" +
          item.substring(6, 13).substring(2, 4) +
          ":" +
          item.substring(6, 13).substring(4, 6),
      };
    });

export const NotificationFormatter = (item) => {
  switch (item) {
    case "TON":
      return "Turn on";
    case "CMC":
      return "Create meter class";
    case "DMC":
      return "Delete meter class";
    case "SDE":
      return "SAIDi enquiry";
    case "SFE":
      return "SAIFI enquiry";
    case "RSTDC":
      return "Reset energy logging interval on dcu";
    case "RST01":
      return "Reset energy logging interval on device";
    case "RST03":
      return "Reset SAIDI ";
    case "RST04":
      return "Reset SAIFI ";
    case "RST10":
      return "Erase control command.";
    case "RST14":
      return "Active panel source.";
    case "OFF":
      return "Turn off";
    case "INT":
      return "Update logging interval";
    case "INT00":
      return "Request for energy logging interval";
    case "LUP":
      return "Loop timer";
    case "APP":
      return "Measure energy by APP. power";
    case "REP":
      return "Measure energy by real power";
    case "PSS05":
      return "Power supply status";
    case "PSS00":
      return "Power-on on supply ";
    case "PSS01":
      return "Power-off on supply ";
    case "CON":
      return "Schedule connection";
    case "DIS":
      return "Schedule disconnection";
    case "CTP":
      return "Clear hardware temper";
    case "AMC":
      return "Add meter class";
    case "CLR":
      return "Clear command on meter";
    case "GTP":
      return "Get hardware temper status";
    case "ENB":
      return "Daily power plan";
    case "ECA":
      return "Created Daily energy limit";
    case "END":
      return "Untimed Energy";
    case "ENC":
      return "Cummulative energy plan";
    case "ECC":
      return "Created cummulative energy limit";
    case "MCE":
      return "Meter class enquiry.";
    case "RUP":
      return "Real time update";
    case "LEN":
      return "Last recharge enquiry";
    case "BEN":
      return "Balance enquiry";
    case "GEN":
      return "Power state of device";
    case "PSS03":
      return "Power up sequence";
    case "PSS04":
      return "Power up sequence";
    case "RST13":
      return "Default input source.";
    case "RST15":
      return "Power state on panel";
    case "RST16":
      return "Panel source selection";
    default:
      return "Unknown Command";
  }
};

export const filterDouble = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const alertFormatter = (item) => {
  if (item == "PHASE_LOSS") {
    return {
      title: "Phase loss",
      caption: "One of your devices has experienced a phase loss",
    };
  } else if (item == "PHASE_RESTORE") {
    return {
      title: "Phase Restored",
      caption: "Phase has been restored on your device.",
    };
  } else if (item == "OVERVOLTAGE") {
    return {
      title: "Over voltage",
      caption: "Your device has experienced an over voltage.",
    };
  } else if (item == "UNDERVOLTAGE") {
    return {
      title: "Under voltage",
      caption: "Your device has experienced an under voltage.",
    };
  } else if (item == "OVERCURRENT") {
    return {
      title: "Over current",
      caption: "The current on one of your devices is too high.",
    };
  } else if (item == "UNDERCURRENT") {
    return {
      title: "Under current",
      caption: "The current on one of your devices is too low.",
    };
  } else if (item == "POWER_OVERLIMIT") {
    return {
      title: "Power overlimit",
      caption: "The power on one of your devices has passed it limit.",
    };
  } else if (item == "POWER_OVERLIMIT") {
    return {
      title: "Power overlimit",
      caption: "Your device has experienced a power overlimt.",
    };
  } else if (item == "CREDIT_EXPIRED") {
    return {
      title: "Credit expired",
      caption: "Credit has expired on your device. Please recharge.",
    };
  } else if (item == "CUMULATIVE_ENERGY_OVERLIMIT") {
    return {
      title: "Cummulative energy overlimit",
      caption: "The cummulative energy on your device has exceeded it's limit.",
    };
  } else if (item == "DAILY_POWER_OVERLIMIT") {
    return {
      title: "Daily power limit",
      caption: "The daily power limit on your device has exceeded it's limit.",
    };
  } else if (item == "ENA") {
    return {
      title: "Daily energy limit recharge",
      caption:
        "You have successfully purchased a daily energy limit plan on your device.",
    };
  } else if (item == "ENB") {
    return {
      title: "Daily power limit recharge",
      caption:
        "You have successfully purchased a daily power limit plan on your device.",
    };
  } else if (item == "ENC") {
    return {
      title: "Commulative energy limit recharge",
      caption:
        "You have successfully purchased a cumulative energy limit plan on your device",
    };
  } else if (item == "END") {
    return {
      title: "Unlimited Energy recharge",
      caption: "You have purchased an untimed energy plan.",
    };
  } else if (item == "HARDWARE_TAMPER") {
    return {
      title: "Hardware temper",
      caption: "Someone has tempered with device. Please check it out.",
    };
  } else if (item == "RST08") {
    return {
      title: "Billing",
      caption: "You have successfully activated billing on your device.",
    };
  } else if (item == "RST07") {
    return {
      title: "Billing",
      caption: "You have successfully deactivated billing on your device.",
    };
  } else {
    return {
      title: "",
      caption: "",
    };
  }
};

export const dateFilter = [
  {
    id: 1,
    title: "Today",
    tag: "today",
  },
  {
    id: 2,
    title: "This week",
    tag: "week",
  },
  {
    id: 3,
    title: "This month",
    tag: "month",
  },
  {
    id: 4,
    title: "This year",
    tag: "year",
  },
];

export function findCenterPoint(coordinates) {
  if (coordinates.length === 0) {
    return null;
  }
  let sumLat = 0;
  let sumLng = 0;
  for (const coord of coordinates) {
    sumLat += +coord[0];
    sumLng += +coord[1];
  }
  const centerLat = sumLat / coordinates.length;
  const centerLng = sumLng / coordinates.length;
  return [centerLat, centerLng];
}

export const durationValues = [
  { value: "day", label: "Day(s)" },
  { value: "week", label: "Week(s)" },
  { value: "month", label: "Month(s)" },
  { value: "year", label: "Year(s)" },
];

export const durationValuesOld = [
  { value: "day(s)", label: "Day(s)" },
  { value: "week(s)", label: "Week(s)" },
  { value: "month(s)", label: "Month(s)" },
  { value: "year(s)", label: "Year(s)" },
];

export const tableCustomStyles = {
  headRow: {
    style: {
      color: "black",
      backgroundColor: "#ecf4f9",
    },
  },
  rows: {
    style: {
      color: "STRIPEDCOLOR",
      backgroundColor: "STRIPEDCOLOR",
    },
    stripedStyle: {
      color: "NORMALCOLOR",
      backgroundColor: "NORMALCOLOR",
    },
  },
};

export const revenueFields = [
  {
    id: 1,
    key: "transactionCount",
    title: "Transaction count",
  },
  {
    id: 2,
    key: "totalRevenue",
    title: "Total Revenue",
  },
  {
    id: 3,
    key: "totalCommission",
    title: "Total Commission",
  },
  {
    id: 4,
    key: "netRevenue",
    title: "Net Revenue",
  },
  {
    id: 5,
    key: "totalKWh",
    title: "Total kWh",
  },
  {
    id: 6,
    key: "avgTransactionValue",
    title: "Average Transaction Value",
  },
];

export const formatTransactionTitle = (title) => {
  switch (title) {
    case "transactionCount":
      return "Transaction Count";
    case "totalRevenue":
      return "Total Revenue";
    case "totalCommission":
      return "Total Commission";
    case "netRevenue":
      return "Net Revenue";
    case "totalKWh":
      return "Total KWh";
    case "totalSales":
      return "Total Revenue";
    case "avgTransactionValue":
      return "Avg Transaction Value";
    default:
      break;
  }
};

// : "100.00";
// totalCommission: "44.00";
// totalKWh: "0.00";
// totalSales: "400.00";
// transactionCount: 4;

export const formatUnits = (title) => {
  switch (title) {
    case "transactionCount":
      return "count";
    case "totalRevenue":
      return "sales";
    case "totalCommission":
      return "sales";
    case "netRevenue":
      return "sales";
    case "totalKWh":
      return "units";
    default:
      break;
  }
};
