import { css } from "@emotion/react";
import { load } from "@syncfusion/ej2-react-grids";
import { MoonLoader, PulseLoader } from "react-spinners";
import Backdrop from "../Backdrop";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const AppLoader = ({
  className,
  open,
  setopen,
  children,
  color,
  ...rest
}) => {
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[fit-content] p-1 bg-white rounded">
          <div className="text-center ">
            <PulseLoader
              css={override}
              color={color ?? "black"}
              size={5}
              speedMultiplier={1}
            />
          </div>
          <p className="m-0">processing...</p>
        </div>
      </div>
    </Backdrop>
  );
};
