import Backdrop from "../../../../Backdrop";
import CloseButton from "../../../../CloseButton";
import Rule from "../../../../Rule/Index";
import { useForm } from "react-hook-form";
import ErrorText from "../../../../Errors/ErrorText";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../../../utils/axios";
import { Select } from "antd";
import { CustomButton } from "../../../../CustomButton";
import { meterController } from "../../../../../controllers/MeterController";
import { useSelector } from "react-redux";
import { errorBlock } from "../../../../../controllers/errorBlock";
import Skimmer from "../../../../Loader/Skimmer";
import BottomModal from "../../../../GeneralModals/BottomModal";
import AllMetersOnDCU from "../../Table/AllMetersOnDCU";
import TableSkimmer from "../../../../TableSkimmer";

const ViewMeterOnDCU = ({ open, setopen, detials, setrefresh }) => {
  const [loading, setloading] = useState(false);
  const [loadingmeter, setloadingmeter] = useState(false);
  const [addmeter, setaddmeter] = useState(false);
  const [inputfield, setinputfield] = useState("");
  const [data, setdata] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { siteid } = useSelector((state) => state.projectSite);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/sitemanagement/dcu/getmetersondcu", {
          dcuid: detials?.dcuid,
        });

        setdata(res.data.body);
        setloading(false);
      } catch (error) {
        errorBlock(error);
        setloading(false);
      }
    })();
  }, []);

  const handleAddMeter = async (data) => {
    try {
      setloadingmeter(true);
      let res = await axios.post("/sitemanagement/dcu/addmetertodcu", {
        siteid: siteid,
        dcuid: detials?.dcuid,
        meterid: data?.meterid,
      });
      let result = meterController(res);

      if (result.type !== "success") {
        setloadingmeter(false);
        toast[result.type](result.message);
        return;
      }
      setaddmeter(false);
      toast.success("Meter added to DCU succefully.");
      // setopen(false);
      setrefresh((prev) => !prev);
    } catch (err) {
      errorBlock(err);
      setloadingmeter(false);
    }
  };

  return (
    <BottomModal open={open}>
      <div className="flex  items-start w-full justify-between">
        <div className="">
          <div className="font-semibold text-[17px]">Meters in DCU</div>
          <div className="text-gray-700 font-light">
            List of meters available on DCU
          </div>
        </div>
        <div className="flex justify-end">
          <CloseButton closeBtn={setopen} />
        </div>
      </div>
      <Rule />
      <div className="">
        <div className="flex justify-between items-center">
          <div className="flex">
            <div className="">
              DCU Id: <span className="font-semibold">{detials?.dcuid}</span>{" "}
            </div>
            <div className="border-l border-gray-300 mx-2 border-[1px]"></div>
            <div className="">
              DCU name:{" "}
              <span className="font-semibold">{detials?.dcualias}</span>{" "}
            </div>
          </div>
          <div className="flex items-center">
            <div className="ml-2 flex overflow-hidden md:w-[200px] lg:w-[200px] items-center w-full border-[1px] rounded-md border-gray-300  h-[40px] w-100">
              <input
                type={"text"}
                placeholder={"Search for meter..."}
                onChange={(e) => setinputfield(e.target.value)}
                className="p-2 w-full placeholder:text-sm block w-100 text-[13] placeholder:font-normal outline-none border-[0px]"
              />
            </div>

            {/* <CustomButton
              onClick={() => {
                setaddmeter(true);
              }}
              className={
                "border-[1px] cursor-pointer ml-2 bg-black rounded h-[40px] text-white text-[12px] font-light px-4 py-2"
              }
            >
              <p className="m-0">Add new meter</p>
            </CustomButton> */}
          </div>
        </div>

        {loading && (
          <div className="">
            {Array.from(Array(4)).map((i, index) => (
              <div key={index} className="my-2">
                <TableSkimmer entries={3} />
              </div>
            ))}
          </div>
        )}
      </div>
      <Rule />

      {!addmeter && (
        <div className="">
          <AllMetersOnDCU data={[]} />
        </div>
      )}

      {addmeter && (
        <form
          onSubmit={handleSubmit(handleAddMeter)}
          className="w-[430px] mx-auto"
        >
          <div className="mt-4">
            <label>Enter meter number</label>
            <div className="flex w-full bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[40px]">
              <input
                required
                {...register("meterid", { required: true })}
                type={"text"}
                placeholder={"Enter"}
                className="bg-gray-100  p-2 placeholder:font-thin w-full outline-none border-[0px]"
              />
            </div>
            {errors.meterid && <ErrorText />}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <CustomButton
              onClick={(e) => {
                e.preventDefault();
                setaddmeter(false);
              }}
              className={
                "border border-black flex items-center justify-center h-[40px] w-full mt-4 rounded-md"
              }
            >
              <p className="m-0">Cancel</p>
            </CustomButton>
            <CustomButton
              loading={loadingmeter}
              className={
                "bg-black flex items-center justify-center h-[40px] w-full mt-4 rounded-md"
              }
            >
              <p className="text-white m-0">{"Add"}</p>
            </CustomButton>
          </div>
        </form>
      )}
    </BottomModal>
  );
};

export default ViewMeterOnDCU;
