import { useSelector } from "react-redux";
import Rule from "../../../Rule/Index";
import CloseButton from "../../../CloseButton";
import Backdrop from "../../../Backdrop";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { CustomButton } from "../../../CustomButton";
import { CiCirclePlus } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import toast from "react-hot-toast";
import { errorBlock } from "../../../../controllers/errorBlock";
import axios from "../../../../utils/axios";
import { Select } from "antd";
import { channelController } from "../../../../controllers/channelController";
import { precache } from "workbox-precaching";

const AddUnlimitedPlan = ({ open, setopen, refreshbtn, customerclass }) => {
  const [entries, setEntries] = useState([]);
  const [loading, setloading] = useState(false);
  const [classtype, setclasstype] = useState("");
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const { username } = useSelector((state) => state.userDetails);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const addEntry = (e) => {
    e.preventDefault();
    const newEntry = {
      price_per_kWh: 0,
      min_units: entries.length > 0 ? entries[entries.length - 1].max_units : 0,
      max_units: "null",
    };
    setEntries([...entries, newEntry]);
  };

  const removeEntry = (index) => {
    const updatedEntries = entries.filter((_, i) => i !== index);
    if (index > 0 && index < entries.length) {
      updatedEntries[index].min_units = entries[index - 1].max_units;
    }
    setEntries(updatedEntries);
  };

  const updateEntry = (index, field, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index][field] = value;
    if (field === "max_units" && index < entries.length - 1) {
      updatedEntries[index + 1].min_units = value;
    }
    setEntries(updatedEntries);
  };

  const onSubmit = async (data) => {
    try {
      if (entries.length === 0) {
        return toast.error("Select at least one tier");
      }
      setloading(true);

      let formattedres = entries.map((i) => {
        return {
          price_per_kWh: Number(i.price_per_kWh),
          min_units: Number(i.min_units),
          max_units: Number(i.max_units),
        };
      });

      let res = await axios.post("/vending/tieredpricing/settieredprice", {
        siteid: Number(siteid),
        customerclass: classtype,
        pricingtierjson: JSON.stringify({ tiers: formattedres }),
        setby: username,
      });

      const result = channelController(res);

      // console.log(result);

      if (result.type !== "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }

      toast.success(result?.message?.message);

      setloading(false);
      setopen(false);
      refreshbtn((prev) => !prev);

      // console.log(res.data, "response");
      // Add your API call logic here
      // setLoading(false);
    } catch (error) {
      setloading(false);
      errorBlock(error);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[500px] rounded-md bg-white drop-shadow-md p-3">
          <div className="flex items-start justify-between">
            <div className="">
              <div className="font-semibold text-[17px]">
                Create Unlimited Plan for customers in{" "}
                <span className="text-secondary">{sitename}</span>
              </div>
              <div className="w-[100%] text-gray-700">
                Fill in the fields to create customer class for customers
              </div>
            </div>
            <CloseButton closeBtn={setopen} />
          </div>
          <Rule />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Select
              placeholder={"Select customer class"}
              style={{
                marginTop: 10,
                width: "100%",
                borderRadius: "10 !important",
                fontSize: 13,
              }}
              onChange={(e) => {
                setclasstype(e);
              }}
              size="large"
              options={customerclass.map((i) => {
                return {
                  value: i.customerClass,
                  label: `${i.customerClass} - ${i.customerType}`,
                };
              })}
            />
            {entries.map((entry, index) => (
              <div className="flex mt-2 items-center" key={index}>
                <div className="bg-gray-100 w-[95%] flex h-[40px] border-[1px] rounded-sm placeholder:font-light outline-none">
                  {/* min_units */}
                  <input
                    placeholder="Enter units (min_units)"
                    value={entry.min_units}
                    onChange={(e) =>
                      updateEntry(index, "min_units", e.target.value)
                    }
                    className="bg-transparent p-1 w-[35%] placeholder:text-sm placeholder:font-light outline-none border-[0px]"
                    readOnly={index > 0} // make the min_units field readonly for all but the first entry
                  />
                  <div className="border-l-[1px] border-gray-300"></div>
                  {/* max_units */}
                  <input
                    placeholder="Enter units (max_units)"
                    value={entry.max_units}
                    onChange={(e) =>
                      updateEntry(index, "max_units", e.target.value)
                    }
                    className="bg-transparent p-1 w-[35%] placeholder:text-sm placeholder:font-light outline-none border-[0px]"
                  />
                  <div className="border-l-[1px] border-gray-300"></div>
                  {/* price_per_kWh */}
                  <input
                    placeholder="Enter price_per_kWh"
                    value={entry.price_per_kWh}
                    onChange={(e) =>
                      updateEntry(index, "price_per_kWh", e.target.value)
                    }
                    className="bg-transparent p-1 w-[30%] placeholder:text-sm placeholder:font-light outline-none border-[0px]"
                  />
                </div>
                <CustomButton
                  onClick={(e) => {
                    e.preventDefault();
                    removeEntry(index);
                  }}
                  className="h-[34px] w-[34px] p-1 rounded flex items-center justify-center"
                >
                  <MdDeleteOutline color="tomato" size={20} />
                </CustomButton>
              </div>
            ))}

            <div className="flex items-center mt-4">
              <CustomButton
                className="p-1 rounded border border-green-700 flex items-center justify-center"
                onClick={addEntry}
              >
                <CiCirclePlus color="green" size={20} />
                <p className="m-0 text-[13px] text-green-700">Add Tiers</p>
              </CustomButton>
            </div>
            <div className="border-[1px] rounded-md p-2 mt-2">
              <p className="m-0 underline">
                Tiered pricing for unlimited energy:
              </p>
              <ul className="list-none list-inside">
                <li className="font-light text-[12px]">
                  The TOU refers to the time the settings of the customer class
                  would be active on the customer's meter
                </li>
              </ul>
            </div>
            <hr className="mt-3" />
            <CustomButton
              loading={loading}
              type="submit"
              className={
                "mt-4 border-[1px] bg-black text-center w-full p-2 border-gray-300 rounded-md flex items-center justify-center"
              }
            >
              <p className="m-0 text-white pr-2"> Submit</p>
            </CustomButton>
          </form>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddUnlimitedPlan;
