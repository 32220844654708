import Backdrop from "../../../../Backdrop";
import CloseButton from "../../../../CloseButton";
import Rule from "../../../../Rule/Index";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Button } from "../../../../Button";
import { toast } from "react-hot-toast";
import axios from "../../../../../utils/axios";
import { countryList } from "../../../../../helperFuncs/countryList";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { errorBlock } from "../../../../../controllers/errorBlock";
const AddDiscountOptions = ({
  setopensalesingle,
  setopensitesingle,
  setopensitesmultiple,
  open,
  setopen,
  refresh,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { username } = useParams();
  const [amount, setamount] = useState(0);

  const { country, sitename } = useSelector((state) => state.projectSite);

  const [loading, setLoading] = useState(false);

  return (
    <Backdrop open={open} setopen={setopen}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] m-auto md:w-[520px] bg-white drop-shadow-md rounded-sm p-4">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-semibold text-[16px]">
                Create discount for Sales agent
              </div>
            </div>
            <div className="flex  mt-[-5px] justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <div className="text-gray-700 font-normal">
            Select an option to create sales discount for agents in{" "}
            <span className="font-semibold">{sitename}</span>
          </div>
          <Rule />
          <div className="">
            <div className="grid grid-cols-2 gap-4">
              <div
                onClick={() => {
                  setopen(false);
                  setopensalesingle(true);
                }}
                className="border-[1px] rounded-md p-2 border-gray-200 cursor-pointer hover:bg-gray-50"
              >
                <h3 className="font-semibold">Agent Discount</h3>
                <p>
                  Apply a sales discount to a sales agent account in a site.
                </p>
              </div>
              <div
                onClick={() => {
                  setopen(false);
                  setopensitesingle(true);
                }}
                className="border-[1px] rounded-md p-2 border-gray-200 cursor-pointer hover:bg-gray-50"
              >
                <h3 className="font-semibold">Site-wide Discount</h3>
                <p>
                  Apply a sales discount to all sales agents account in a site.
                </p>
              </div>
              <div className="border-[1px] rounded-md p-2 border-gray-200 cursor-pointer hover:bg-gray-50">
                <h3 className="font-semibold">Bulk Add Agent Discount</h3>
                <p>
                  Apply a sales discount to a sales agent across multiple sites.
                </p>
              </div>
              <div
                onClick={() => {
                  setopen(false);
                  setopensitesmultiple(true);
                }}
                className="border-[1px] rounded-md p-2 border-gray-200 cursor-pointer hover:bg-gray-50"
              >
                <h3 className="font-semibold">Bulk Add Site Discount</h3>
                <p>
                  Apply a sales discount to a sales agent across multiple sites.
                </p>
              </div>
            </div>

            <div className="mt-6">
              <Rule />
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddDiscountOptions;
