import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const salesanalyticsAPI = createApi({
  reducerPath: "salesPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      //   const token = credentials.token;
      //   if (token) {
      //     headers.set("Version", `0.1`);
      //     return headers;
      //   }
    },
  }),
  endpoints: (build) => ({
    //
    getSalesBySysAdmin: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getsystemmanageranalytics",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    getSalesByProjectManager: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getprojectmanageranalytics",
        method: "POST",
        body: {
          ...credentials,
          projectmanagerusername: data?.username,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    getCustomerMeterVendHistory: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getcustomermeterpurchases",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
          meterid: data?.meterid,
        },
      }),
    }),
    getTopPerformingAgentSiteWide: build.mutation({
      query: (data) => ({
        url: "salesanalytics/gettopperformingsalesagentsorganizationwide",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
          limit: data?.limit,
        },
      }),
    }),
  }),
});

export const {
  useGetSalesBySysAdminMutation,
  useGetSalesByProjectManagerMutation,
  useGetCustomerMeterVendHistoryMutation,
  useGetTopPerformingAgentSiteWideMutation,
} = salesanalyticsAPI;
