import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { FcCalendar } from "react-icons/fc";
import Skimmer from "../../../Loader/Skimmer";

const SiteDiscoutTable = ({ data, loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      name: "Discount %",
      selector: (row) => row.discountPercentage,
      center: true,
      grow: 3,
    },
    {
      name: "Created by",
      selector: (row) => row.createdBy,
      center: true,
      grow: 3,
    },
    {
      name: "Start date",
      center: true,
      selector: (row) => row.startDate,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="">{moment(item.startDate).format("lll")}</div>
          </div>
        );
      },
      sortable: true,
    },

    {
      name: "End date",
      center: true,
      selector: (row) => row.endDate,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="">
              {item?.endDate == "null" ? (
                <span className="font-semibold text-green-700">
                  Currently active
                </span>
              ) : (
                <span className="flex">
                  <FcCalendar size={18} />
                  {moment(item.endDate).format("lll")}
                </span>
              )}
            </div>
          </div>
        );
      },
      sortable: true,
    },
  ];

  return (
    <div className="">
      <DataTable
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        progressComponent={<div className="mt-2">Fetching data..</div>}
        paginationPerPage={10}
      />
    </div>
  );
};

export default SiteDiscoutTable;
