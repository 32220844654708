export const meterController = (res) => {
  if (res.data.response == "invalid session") {
    return {
      message: "Invalid sessionid",
      type: "error",
    };
  } else if (res.data.response == "failure" || res.data.response == "Failure") {
    return {
      message: "Operation failed. Please try again",
      type: "error",
    };
  } else if (
    res.data.error == "invalid identity" ||
    res.data.response == "Invalid Identity"
  ) {
    return {
      message: res.data.response,
      type: "error",
    };
  } else if (res.data.response == false) {
    return {
      message: res.data.message,
      type: "error",
    };
  } else {
    return {
      message: res.data,
      type: "success",
    };
  }
};
