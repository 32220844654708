import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../../../helperFuncs";
import { countryList } from "../../../../helperFuncs/countryList";
import { useSelector } from "react-redux";

const SalesAgentRevenueTable = ({ data }) => {
  const { country } = useSelector((state) => state.projectSite);

  const columns = [
    {
      name: "Date",
      center: true,
      sortable: true,
      selector: (row) => row.date,
      grow: 1,
    },
    {
      name: `Total Revenue (${countryList[country]["Currency Code"] ?? ""})`,
      center: true,
      sortable: true,
      selector: (row) => row.totalSales,
      grow: 1,
      cell: (item) => (
        <div className="">
          {Number(item?.totalRevenue).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) ?? 0}
        </div>
      ),
    },
    {
      name: `Net Revenue (${countryList[country]["Currency Code"] ?? ""})`,
      center: true,
      sortable: true,
      selector: (row) => row.totalSales,
      grow: 1,
      cell: (item) => (
        <div className="">
          {Number(item?.netRevenue).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) ?? 0}
        </div>
      ),
    },
    {
      name: "Total KWh",
      center: true,
      sortable: true,
      selector: (row) => row.totalKWh,
      grow: 1,
      cell: (item) => (
        <div className="">
          {Number(item?.totalKWh).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) ?? 0}
        </div>
      ),
    },
    {
      //
      name: `Total Commission (${countryList[country]["Currency Code"] ?? ""})`,
      center: true,
      sortable: true,
      selector: (row) => row.totalCommission,
      grow: 1,
      cell: (item) => (
        <div className="">
          {Number(item?.totalCommission).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          }) ?? 0}
        </div>
      ),
    },

    {
      name: "Number of transactions",
      center: true,
      sortable: true,
      selector: (row) => row.transactionCount,
      grow: 1,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data} // Use the `data` prop passed to the component
      // data={(data = sampleData)} // Use the `data` prop passed to the component
      pagination={true}
      paginationPerPage={10}
      customStyles={tableCustomStyles}
    />
  );
};

export default SalesAgentRevenueTable;
