import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { FcCalendar, FcDeleteRow } from "react-icons/fc";
import { MdOutlineDeleteForever } from "react-icons/md";
import DataTable from "react-data-table-component";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import ViewPaymentPlan from "./modals/ViewPaymentPlan";
import { useSelector } from "react-redux";
import { channelController } from "../../../controllers/channelController";
import { errorBlock } from "../../../controllers/errorBlock";
import { CustomButton } from "../../CustomButton";

const PaymentPlanTable = ({ data, loading, refreshbtn, tabstate }) => {
  const [plandetails, setplandetails] = useState({});
  const [deleting, setdeleting] = useState(false);
  const [selectplan, setselectplan] = useState({});
  const [open, setopen] = useState(false);
  const { siteid } = useSelector((state) => state.projectSite);

  const deletePlan = async (item) => {
    try {
      setdeleting(true);
      let res = await axios.post("/vending/paymentplan/deletepaymentplan", {
        planalias: item.planalias,
        customerclass: item.meter_class,
        siteid: Number(siteid),
      });

      let result = channelController(res);

      if (result.type != "success") {
        toast.error(result.message);
        setdeleting(false);
        return;
      }
      toast.success("Plan deleted successfully.");
      setdeleting(false);
      refreshbtn((prev) => !prev);
    } catch (err) {
      errorBlock(err);
      setdeleting(false);
    }
  };
  const columns = [
    {
      name: "Plan Alias",
      center: false,
      selector: (row) => row.planalias,
      grow: 2,
    },
    // {
    //   name: "Day Binaries",
    //   center: true,
    //   selector: (row) => row.daybinaries,
    //   grow: 1,
    // },
    {
      name: "Plan Duration",
      center: true,
      selector: (row) => row.duration,
      grow: 1,
      cell: (item) => {
        return (
          <div className="">{`${item.duration} ${item.durationlength}`}</div>
        );
      },
    },
    {
      name: "Amount",
      center: true,
      selector: (row) => row.amount,
      grow: 1,
    },
    // {
    //   name: "Date Created",
    //   center: true,
    //   selector: (row) => row.datecreated,
    //   grow: 2,
    //   cell: (item) => {
    //     return (
    //       <div className="flex items-center">
    //         <div className="text-[13px]">
    //           {moment(item.datecreated).format("YYYY-MM-DD HH:mm:ss")}
    //         </div>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    // },
    // {
    //   name: "Start Time",
    //   center: true,
    //   selector: (row) => row.starttime,
    //   grow: 1,
    //   cell: (item) => {
    //     return (
    //       <div className="flex items-center">
    //         <div className="text-[13px]">
    //           {moment(item.starttime).format("HH:mm:ss")}
    //         </div>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    // },
    // {
    //   name: "End Time",
    //   center: true,
    //   selector: (row) => row.endtime,
    //   grow: 1,
    //   cell: (item) => {
    //     return (
    //       <div className="flex items-center">
    //         <div className="text-[13px]">
    //           {moment(item.endtime).format("HH:mm:ss")}
    //         </div>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    // },
    {
      name: "Meter Class",
      center: true,
      selector: (row) => row.meter_class,
      grow: 1,
    },
    {
      name: "Action",
      selector: (row) => row.id, // Assuming you have an id field for each row
      grow: 2,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            {tabstate == 1 && (
              <CustomButton
                loading={selectplan == item.planalias ? deleting : false}
                disabled={deleting}
                color={"tomato"}
                onClick={() => {
                  setselectplan(item.planalias);
                  deletePlan(item);
                }}
                className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
              >
                {selectplan == item.planalias && deleting ? (
                  ""
                ) : (
                  <MdOutlineDeleteForever size={15} />
                )}
              </CustomButton>
            )}
            {tabstate == 1 && (
              <Link
                to={`/paymentplan/edit/${item.planalias}/${item?.meter_class}`}
                onClick={() => {}}
                className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
              >
                <FaRegEdit size={15} />
              </Link>
            )}
            <div
              onClick={() => {
                setplandetails(item);
                setopen(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              <FaEye size={15} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {open && (
        <ViewPaymentPlan open={open} setopen={setopen} details={plandetails} />
      )}
      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        paginationPerPage={10}
      />
    </>
  );
};

export default PaymentPlanTable;
