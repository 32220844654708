import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const SalesBarChart = ({ data, currency, selectedRevenue }) => {
  if (!currency || !selectedRevenue || !selectedRevenue.key) {
    console.error("Missing required props in AdminSiteRevenue");
    return <div>Getting required data</div>;
  }

  const label = !data ? [] : data.map((i) => i?.date);
  const values = !data ? [] : data.map((i) => i[selectedRevenue?.key]);

  return (
    <Bar
      data={{
        labels: label,
        datasets: [
          {
            label: selectedRevenue?.title ?? "",
            backgroundColor: "rgba(255, 159, 64, 0.6)",
            borderColor: "rgba(255, 159, 64, 0.6)",
            data: values,
            borderWidth: 1,
            barThickness: 30,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                let label = tooltipItem.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                label += new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: `${currency}`,
                }).format(tooltipItem.raw);
                return label;
              },
            },
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text:
                selectedRevenue?.title.trim() === "Total kWh"
                  ? "Unit (kWh)"
                  : selectedRevenue?.key === "transactionCount"
                  ? ""
                  : currency,
            },
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default SalesBarChart;
