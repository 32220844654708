import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const SiteRevenueChart = ({ data, height, currency }) => {
  const labels = !data
    ? []
    : Object.keys(data).map((item) => data[item]?.orderDate);

  return (
    <Bar
      data={{
        labels: labels ? labels : [], // Site names as x-axis labels
        datasets: [
          {
            label: "Amount",
            type: "line",
            backgroundColor: "orange",
            borderColor: "orange",
            data: !data ? [] : data.map((i) => i?.amount),
            borderWidth: 1,
            barThickness: 30,
          },
          {
            label: "Commission",
            backgroundColor: "tomato",
            type: "line",
            borderColor: "tomato",
            data: !data ? [] : data.map((i) => i?.commission),
            borderWidth: 1,
            barThickness: 30,
          },
          {
            label: "Net revenue",
            type: "line",
            backgroundColor: "dodgerblue",
            borderColor: "dodgerblue",
            data: !data ? [] : data.map((i) => i?.netRevenue),
            borderWidth: 1,
            barThickness: 30,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                // Custom tooltip formatting
                let label = tooltipItem.dataset.label || "";

                if (currency) {
                  if (label) {
                    label += ": ";
                  }

                  label += new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: `${currency}`,
                  }).format(tooltipItem.raw);
                }

                return label;
              },
            },
          },
        },
        scales: {
          x: {
            // stacked: false, // Ensure bars are side by side
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false, // Ensure bars are not stacked
            display: true,
            title: {
              display: true,
              text: `${currency ?? ""}`,
            },
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default SiteRevenueChart;
