import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const projectSiteAPI = createApi({
  reducerPath: "sitePath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      //   const token = credentials.token;
      //   if (token) {
      //     headers.set("Version", `0.1`);
      //     return headers;
      //   }
    },
  }),
  endpoints: (build) => ({
    getSiteDetails: build.mutation({
      query: (data) => ({
        url: "sitemanagement/getspecificsitebysiteid",
        method: "POST",
        body: {
          ...credentials,
          siteid: Number(data?.siteid),
        },
      }),
    }),
  }),
});

export const { useGetSiteDetailsMutation } = projectSiteAPI;
