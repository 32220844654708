import CardTitle from "../../CardTitle";
import RevenueCharts from "../Charts/RevenueCharts";
import { BsArrowDownShort } from "react-icons/bs";
import { themeColor as color } from "../../../constant/color";
import "./styles.css";
import { useState } from "react";
import { Empty, Select } from "antd";
import DateRangePicker from "../../DateRangePicker";
import moment from "moment";

const Revenue = ({ paymentHistory, revenue, dateRange }) => {
  const [tabstate, setTabState] = useState(1);

  return (
    <div className="h-[500px]">
      <RevenueCharts paymentHistory={paymentHistory} />
    </div>
  );
};

export default Revenue;
