import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
import AddRoleImg from "../../../../assets/svg/adduser.svg";
import { useState, useEffect } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import Events from "events";
import toast from "react-hot-toast";
import axios from "../../../../utils/axios";
import { userController } from "../../../../controllers/UserController";
import { meterController } from "../../../../controllers/MeterController";
import { GiConsoleController } from "react-icons/gi";
import { getToken } from "../../../../utils/token";
import { themeColor as color } from "../../../../constant/color";
import { Button } from "../../../Button";
import CloseButton from "../../../CloseButton";
import { useSelector } from "react-redux";
import { errorBlock } from "../../../../controllers/errorBlock";
import { mqttEvents } from "../../../../context/MqttContext";
import { CustomButton } from "../../../CustomButton";
import Backdrop from "../../../Backdrop";
import {
  useAssignMeterMutation,
  useAssignRFMeterMutation,
  useGetMeterDetailsMutation,
} from "../../../../services/meter.services";
import { useGetDCUsMutation } from "../../../../services/dcu.services";
import { useGetCustomerClassInSiteMutation } from "../../../../services/customerclass.services";
import { useGetcustomerAddressMutation } from "../../../../services/customer.services";

const AssignMeter = ({ open, setopen, refresh, user }) => {
  const [showption, setShowption] = useState(false);
  const [tabState, setTabState] = useState(0);
  const [loading, setLoading] = useState(false);
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const [result, setresult] = useState(false);
  const [customerId, setcustomerId] = useState("");
  const [meterId, setMeterId] = useState("");
  const [address, setAddress] = useState([]);
  const [selectedAddress, setselectedAddress] = useState("");
  const [customerclass, setcustomerclass] = useState("");
  const [dcuid, setdcuid] = useState("");
  const [searchdata, setsearchdata] = useState([]);

  // get all DCU
  const [getDCUs, { isLoading: dculoading, error: dcuError, data: dcuData }] =
    useGetDCUsMutation();

  const [
    getcustomerAddress,
    { isLoading: addressloading, error: addressError, data: addressData },
  ] = useGetcustomerAddressMutation();
  // assign meter
  const [
    assignMeter,
    { isLoading: assignloading, error: assignError, data: assignData },
  ] = useAssignMeterMutation();

  // assign Rf meter
  const [
    assignRFMeter,
    { isLoading: assignRfloading, error: assignRfError, data: assignRfData },
  ] = useAssignRFMeterMutation();
  // get customer class
  const [
    getCustomerClassInSite,
    {
      isLoading: customerclassloading,
      error: customerclassError,
      data: customerclassData,
    },
  ] = useGetCustomerClassInSiteMutation();

  const [
    getMeterDetails,
    { isLoading: meterloading, error: meterError, data: meterData },
  ] = useGetMeterDetailsMutation();
  useEffect(() => {
    if (meterId.length === 13) {
      // Make the API call
      getMeterDetails(meterId).unwrap();
    }
  }, [meterId]);

  useEffect(() => {
    if (meterError) {
      errorBlock(meterError);
    }
  }, []);
  useEffect(() => {
    if (meterData?.rfmeter == true) {
      getDCUs(siteid).unwrap();
    }
  }, [meterData]);

  useEffect(() => {
    getCustomerClassInSite(siteid).unwrap();
  }, []);

  useEffect(() => {
    if (user) {
      getcustomerAddress(user?.customerid)
        .then((res) => {
          setAddress(res.data.body);
          // console.log(res.data, "response");
        })
        .then((err) => {
          errorBlock(err);
          // console.log(err);
        });
    }
  }, [user]);

  const fetchAddress = async (e) => {
    try {
      setTabState(1);
      let res = await axios.post("/customer/getallmetercustomeraddresses", {
        customerid: e,
      });
      setAddress(res.data.body);
    } catch (error) {
      errorBlock(error);
    }
  };

  useEffect(() => {
    if (user) {
      setcustomerId(user?.customerid);
      setTabState(1);
    }
  }, []);
  const handleUserName = async () => {
    try {
      setresult(false);
      if (!customerId) return toast.error("Please enter a username");
      setLoading(true);
      let res = await axios.post("/auth/utility/customeraccount/searchuser", {
        keyword: customerId,
      });

      let result = userController(res);

      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }

      setsearchdata(result.message.body);
      if (result.message.body.length == 0) {
        setShowption(true);
      }

      setresult(true);
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!(customerId && selectedAddress && customerclass))
        return toast.error("All values are required");

      if (meterData?.rfmeter) {
        assignRFMeter({
          meternumber: meterData?.meterid,
          dcuid,
          customerid: customerId,
          address: selectedAddress,
          siteid,
          customerclass,
        })
          .then((res) => {
            if (res.data.response != true) {
              if (res.data.response == false) {
                toast.error("Failed to assign meter");
              } else {
                toast.error(res.data.response);
              }
            } else if (res.data.response == true) {
              toast.success("Meter assigned successfully ");
            } else if (res.data == "success") {
              toast.success("Meter assigned successfully ");
            }
            setopen(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        assignMeter({
          meternumber: meterData?.meterid,
          customerid: customerId,
          address: selectedAddress,
          siteid,
          customerclass,
        })
          .then((res) => {
            if (res.data.response != true) {
              if (res.data.response == false) {
                toast.error("Failed to assign meter");
              } else {
                toast.error(res.data.response);
              }
            } else if (res.data.response == true) {
              toast.success("Meter assigned successfully ");
            }

            setopen(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[450px] lg:w-[450px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-start justify-between">
            <h1 className="font-semibold text-[15px]">
              Assign Meter to Customer
            </h1>
            <p
              className="text-secondary cursor-pointer"
              onClick={() => {
                setopen((prev) => false);
              }}
            >
              Close
            </p>
          </div>

          {tabState == 1 || tabState == 2 ? (
            <div className=" mb-2">
              Fill the input fields below to assign meter to customer.
            </div>
          ) : null}

          {tabState == 0 && (
            <div>
              <p className="m-0">
                To assign a meter to a customer, start by searching for the
                username of the account.
              </p>

              <div className=" w-full mt-3 ">
                <input
                  type="text"
                  className="w-full h-[45px] !border-gray-300  rounded bg-gray-100 outline-none px-2"
                  placeholder={"Enter the username of the user."}
                  value={customerId}
                  onChange={(e) => setcustomerId(e.target.value)}
                  style={{
                    border: `1px solid ${color.baseColor}`,
                  }}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength) {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    }
                  }}
                  maxLength={13}
                />
              </div>
              {result && (
                <div className="">
                  {searchdata.length == 0 ? (
                    <>
                      <p className="text-center mt-4">
                        No records of a user with username{" "}
                        <span className="font-semibold">`{customerId}`</span>{" "}
                        was found in {sitename}.
                        <br /> Would you like to create user
                      </p>
                      {showption && (
                        <div className="flex items-center justify-between">
                          <div className="addrole-btn">
                            <CustomButton
                              onClick={() => {
                                setShowption(false);
                                setresult(false);
                                setsearchdata([]);
                                setcustomerId("");
                              }}
                              className={
                                "border-[1px] p-2 w-full border-black rounded flex items-center justify-center"
                              }
                            >
                              <p className="m-0">No</p>
                            </CustomButton>
                          </div>
                          <div className="addrole-btn">
                            <Button
                              text={"Yes"}
                              onClick={() => {
                                setopen(false);
                                mqttEvents.emit("open", 2);
                              }}
                              bg={color.secondary}
                              color={color.darkColor}
                              height={"40px"}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="mt-3">
                      <p className="m-0">
                        Search results for{" "}
                        <span className="font-semibold text-secondary">
                          `{customerId}`
                        </span>
                      </p>
                      {searchdata.map((i) => (
                        <div
                          onClick={() => {
                            fetchAddress(i?.username);
                            setcustomerId(i?.username);
                          }}
                          className="flex items-center justify-between even:bg-gray-50 p-2 my-2 cursor-pointer"
                        >
                          <div className="">
                            <p className="m-0">
                              Fullname:{" "}
                              <span className="font-semibold">
                                {i.firstname[0].toUpperCase() +
                                  i.firstname.substring(1)}{" "}
                                {i?.lastname[0].toUpperCase() +
                                  i?.lastname.substring(1)}
                              </span>
                            </p>
                            <p className="m-0">
                              Username:{" "}
                              <span className="font-semibold">
                                {i?.username}
                              </span>
                            </p>
                          </div>
                          <div className="bg-white rounded ">
                            <p className="m-0 px-2 py-1 border  text-gray-600 text-[14px]">
                              {" "}
                              Select
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {tabState == 1 && (
            <>
              <div className="row">
                <input
                  className="border-[1px]  bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                  placeholder={"Enter customerId"}
                  value={customerId}
                  disabled={true}
                />

                <input
                  className="border-[1px] mt-2 bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                  placeholder={"Enter meterId"}
                  value={meterId}
                  onChange={(e) => setMeterId(e.target.value)}
                />
                {meterloading && <span>fetching meter details....</span>}
                {meterData?.rfmeter && (
                  <Select
                    size="large"
                    placeholder="Select DCU to add to meter"
                    showSearch
                    style={{
                      width: "100%",
                      marginTop: 8,
                    }}
                    onChange={(e) => {
                      setdcuid(e);
                    }}
                    options={
                      dcuData?.body.length == 0
                        ? []
                        : dcuData?.body.map((i) => {
                            return {
                              value: i.dcuid,
                              label: `DCU alias: ${i.dcualias} | DCU id: ${i.dcuid}`,
                            };
                          })
                    }
                  />
                )}
                {meterData && (
                  <>
                    <Select
                      size="large"
                      placeholder="Select customer address "
                      showSearch
                      style={{
                        width: "100%",
                        marginTop: 10,
                      }}
                      onChange={(e) => {
                        setselectedAddress(e);
                      }}
                      options={address.map((i) => {
                        return {
                          value: i,
                          label: i,
                        };
                      })}
                    />
                    <Select
                      size="large"
                      placeholder="Select customer class "
                      showSearch
                      style={{
                        width: "100%",
                        marginTop: 10,
                      }}
                      onChange={(e) => {
                        setcustomerclass(e);
                      }}
                      options={
                        customerclassData?.customerClasses.length == 0
                          ? []
                          : customerclassData?.customerClasses.map((i) => {
                              return {
                                value: i?.customerClass,
                                label: `Class name: ${i?.customerClass} | Class type: ${i?.customerType}`,
                              };
                            })
                      }
                    />{" "}
                  </>
                )}
              </div>
            </>
          )}

          {!showption && (
            <div className="flex items-center mt-3 justify-between w-full addrolebtn-cover pt-3">
              <div className="addrole-btn">
                <CustomButton
                  onClick={() => {
                    if (tabState == 1) {
                      setTabState(0);
                      setresult(false);
                    } else if (tabState == 2) {
                      return setTabState(1);
                    } else if (tabState == 0) {
                      if (searchdata.length > 0) {
                        setopen(false);
                      }
                      setresult(false);
                      return;
                    }
                  }}
                  className={
                    "border-[1px] p-2 w-full border-black rounded flex items-center justify-center"
                  }
                >
                  <p className="m-0">
                    {tabState == 1 ? "Back" : tabState == 0 ? "Cancel" : "Back"}
                  </p>
                </CustomButton>
              </div>

              <div className="addrole-btn">
                <CustomButton
                  loading={assignRfloading || loading}
                  onClick={() => {
                    if (tabState == 1) {
                      return handleSubmit();
                    } else if (tabState == 0) {
                      if (searchdata.length > 0) {
                        setShowption(false);
                        setresult(false);
                        setsearchdata([]);
                        setcustomerId("");
                      } else {
                        handleUserName();
                      }
                    }
                  }}
                  className={
                    "border-[1px] bg-black p-2 w-full rounded flex items-center justify-center"
                  }
                >
                  {!loading && (
                    <p className="m-0 text-white">
                      {tabState == 0 ? "Search" : tabState == 1 ? "Assign" : ""}
                    </p>
                  )}
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </Backdrop>
  );
};

export default AssignMeter;
