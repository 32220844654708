import { useSelector } from "react-redux";
import BackBtn from "../../../components/BackBtn";
import { CustomButton } from "../../../components/CustomButton";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import { useEffect, useState, useMemo } from "react";
import { errorBlock } from "../../../controllers/errorBlock";
import axios from "../../../utils/axios";
import PaymentPlanTable from "../../../components/ProjectManager/PaymentPlan/PaymentPlanTable";
import AddPlanModal from "../../../components/ProjectManager/PaymentPlan/modals/AddPlanModal";
import AddDailyEnergyPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddDailyEnergyPlan";
import AddDailyPowerPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddDailyPowerPlan";
import AddCumulativeEnergyPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddCumulativeEnergyPlan";
import AddUnlimitedPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddUnlimitedPlan";
import TieredPlanTable from "../../../components/ProjectManager/PaymentPlan/TieredPlanTable";

const TieredPlans = () => {
  const AgentTopCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="">
          <div className="">
            <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
              {title}
            </div>
            <p className="text-[14px] text-gray-600">{caption}</p>
          </div>
        </div>
        <div className="text-3xl font-semibold">{value}</div>
      </div>
    );
  };
  const [tabstate, settabstate] = useState(1);

  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);
  const [count, setcount] = useState(0);
  const [customerclass, setcustomerclass] = useState([]);
  const [openplanmodal, setopenplanmodal] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [openena, setopenena] = useState(false); // energy plan
  const [openenb, setopenenb] = useState(false); // power plan
  const [openenc, setopenenc] = useState(false); // cumulative plan
  const [openend, setopenend] = useState(false); // end

  // get all site plan
  useEffect(() => {
    (async () => {
      try {
        let res;
        if (tabstate == 1) {
          res = await axios.post(
            "/vending/tieredpricing/getactivepricingforsite",
            {
              siteid: Number(siteid),
            }
          );
          setdata(res.data.pricing);
          setcount(res.data.meta.count);
          setloading(false);
          return;
        }
        if (tabstate == 2) {
          res = await axios.post(
            "/vending/tieredpricing/getactivepricingforallsites",
            {}
          );
          setdata(res.data.body);
          setcount(res.data.meta.count);
          setloading(false);
          return;
        }
      } catch (error) {
        errorBlock(error);
        setloading(false);
      }
    })();
  }, [refresh, tabstate]);

  // get customer class
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/settings/customerclass/getallcustomerclassesforsite",
          {
            siteid: Number(siteid),
          }
        );
        setcustomerclass(res.data.customerClasses);
      } catch (err) {
        errorBlock(err);
      }
    })();
    return () => {};
  }, []);

  return (
    <Layout>
      {openend && (
        <AddUnlimitedPlan
          setopen={setopenend}
          open={openend}
          refreshbtn={setrefresh}
          customerclass={customerclass}
        />
      )}
      {/* first vp */}
      <div className="bg-white p-2 shadow rounded">
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <div className="h-[30px]  mr-2 w-[20px] rounded bg-sky-200"></div>
            <PageTitle title={"Available fixed units"} caption={""} />
          </div>
          <CustomButton
            onClick={() => setopenend(true)}
            className=" w-[120px] bg-gray-900 p-2 h-[40px] rounded-md flex items-center justify-center"
          >
            <p className="m-0 text-white"> Add fixed unit</p>
          </CustomButton>
        </div>

        <hr className="mt-2" />

        {/* tabs */}
        <div className="mt-2">
          <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
            <div
              onClick={() => settabstate(1)}
              className={
                tabstate == 1
                  ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                  : `text-center cursor-pointer px-2 py-1 text-[14px]`
              }
            >
              Fixed units in {sitename}
            </div>
            <div
              onClick={() => settabstate(2)}
              className={
                tabstate == 2
                  ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                  : `text-center cursor-pointer text-[14px] px-2 py-1 `
              }
            >
              Other site's fixed units
            </div>
          </div>
        </div>

        <hr className="mt-4 mb-2" />

        <PageTitle
          title={
            tabstate == 1
              ? `All fixed units per customer class in ${sitename}`
              : `All tiered plans created in other project site`
          }
          caption={
            "This determines the amount charged per unit when customers procure up to certain units in a calender month"
          }
        />

        <hr className="mb-2 mt-2" />
        <div className="">
          <TieredPlanTable
            data={data}
            tabstate={tabstate}
            loading={loading}
            refreshbtn={setrefresh}
          />
        </div>
      </div>
    </Layout>
  );
};

export default TieredPlans;
