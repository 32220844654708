import { Empty, Select } from "antd";
import axios from "../../utils/axios";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import AllCustomerTable from "../../components/SalesAgent/Table/AllCustomerTable";
import TableSkimmer from "../../components/TableSkimmer";
import { channelController } from "../../controllers/channelController";
import { customerFilterData } from "./data";
import ProjectSiteMeters from "../../components/ManageMeter/ProjectSiteMeters";
import CustomerMeterDetails from "../../components/SalesAgent/Modals/CustomerMeterDetails";
import { useSelector } from "react-redux";
import CustomerWithoutMeteTable from "../../components/CustomerManager/Tables/CustomerWithoutMeteTable";
import Rule from "../../components/Rule/Index";
import { errorBlock } from "../../controllers/errorBlock";
import AssignMeter from "../../components/Meter/modals/AssignMeter";

const ManagerMeter = () => {
  const [tabstate, settabstate] = useState(1);
  const [data, setData] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columnInput, setColumnInput] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState(null);
  const [sitename, setsitename] = useState("");

  const projectsiteName = useSelector((state) => state.projectSite).sitename;
  const projectsiteId = useSelector((state) => state.projectSite).siteid;

  const [search, setSearch] = useState("");
  const [dropDown, setDropDown] = useState([]);
  const [meter, setMeter] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const { Option } = Select;
  const controller = new AbortController();

  const handleSearch = async () => {
    try {
      if (!(columnInput && selectedColumn))
        return toast.error("Both search field and field value is required.");
      setLoading(true);

      let res = await axios.post("/customer/getallmetercustomers", {
        column: selectedColumn,
        columnparameter: columnInput,
        zone: projectsiteId,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast.error(result.message);
        setLoading(false);
        return;
      }
      setData(result.message.body);
      setLoading(false);
      settabstate(1);
    } catch (error) {
      errorBlock(error);
      setLoading(true);
    }
  };
  // get all meter customers
  useEffect(() => {
    (async () => {
      try {
        // 7654
        // if (!(columnInput && selectedColumn)) return;
        let res;
        setLoading(true);

        if (tabstate == 1) {
          res = await axios.post("/customer/getallmetercustomersbyzone", {
            zone: projectsiteId,
          });
        } else {
          res = await axios.post(
            "/customer/getallcustomeraccountswithoutmeter",
            {
              // zone: projectsiteId,
            }
          );
        }

        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        setData(result.message.body);
        setLoading(false);
        // 3456
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
        // 3456
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh, tabstate]);

  // get all site details
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/sitemanagement/getallsitesdetails", {});
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setDropDown(result.message.body.map((item) => item.sitename));
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Layout>
      {/* {modal && (
        <CustomerMeterDetails
          setopen={setmodal}
          open={modal}
          details={details}
        />
      )} */}

      <div className="bg-white rounded-md drop-shadow-md p-2">
        <div className="flex items-center justify-between">
          <PageTitle
            title={"Search for details of customer in " + projectsiteName}
            caption={
              "Select a search field criteria and enter the value of the field "
            }
          />
          <div className="">
            <div className="flex items-center justify-start my-2">
              <div className="flex items-center justify-between  rounded-md overflow-hidden border-gray-600">
                <div className="border w-[200px]">
                  <Select
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setSelectedColumn(e)}
                    options={customerFilterData.map((i) => {
                      return {
                        value: i.value,
                        label: i.label,
                      };
                    })}
                    bordered={false}
                    placeholder={"Select field criteria."}
                  />
                </div>
                <div className="flex border-[1px] w-auto mx-2 bg-white items-center w-100 h-full">
                  <input
                    required
                    value={columnInput}
                    onChange={(e) => setColumnInput(e.target.value)}
                    type={"text"}
                    placeholder={"Enter " + selectedColumn}
                    className="px-2 w-full placeholder:text-sm h-[33px] outline-none border-none"
                  />
                </div>
                <div className="border w-[150px] bg-secondary  flex items-center justify-center rounded-md">
                  {loading ? (
                    <button
                      disabled={loading}
                      className="px-4 py-2 text-gray-50"
                    >
                      Search...
                    </button>
                  ) : (
                    <button
                      onClick={handleSearch}
                      className="px-4 py-2 text-gray-50 cursor-pointer"
                    >
                      Search
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Rule />
        <div className="">
          <div className="">
            <div className="flex items-center mt-6 border-b-[1px] border-gray-300">
              <div
                className={
                  tabstate == 1
                    ? "px-8 py-1 border-b-[1.5px] border-gray-800 mx-2 cursor-pointer"
                    : "px-8 py-1 border-b-[1.5px] text-gray-400 border-gray-300 mx-2 cursor-pointer"
                }
                onClick={() => settabstate(1)}
              >
                Customer with meter
              </div>
              <div
                className={
                  tabstate == 2
                    ? "px-8 py-1 border-b-[1.5px] border-gray-800 mx-2 cursor-pointer"
                    : "px-8 py-1 border-b-[1.5px] text-gray-400 border-gray-300 mx-2 cursor-pointer"
                }
                onClick={() => settabstate(2)}
              >
                Customers without meter
              </div>
            </div>
          </div>
          <hr className="mt-2" />
          {tabstate == 1 && (
            <div className="">
              <hr />
              <div className="">
                {loading ? (
                  <TableSkimmer entries={10} col={7} />
                ) : data.length == 0 ? (
                  <div className="h-[70vh] flex items-center justify-center">
                    <Empty description={"No record found"} />
                  </div>
                ) : (
                  <div className=" overflow-y-hidden overflow-x-auto">
                    <AllCustomerTable
                      data={data}
                      setdetails={setdetails}
                      setmodal={setmodal}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {tabstate == 2 && <CustomerWithoutMeteTable data={data} />}
        </div>
      </div>
    </Layout>
  );
};

export default ManagerMeter;
