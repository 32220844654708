import { createPortal } from "react-dom";
import ReactDOM from "react-dom";

const Backdrop = ({ children, setopen, open }) => {
  return ReactDOM.createPortal(
    <div
      className="fixed h-screen w-screen bg-[#0000004a] top-0 right-0 z-50"
      style={{
        display: open ? "block" : "none",
      }}
    >
      {children}
    </div>,
    document.getElementById("modal")
  );
};

export default Backdrop;
