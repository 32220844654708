import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import Layout from "../../../components/Layout";
import SiteDiscoutTable from "../../../components/ProjectManager/Discount/Table/SiteDiscoutTable";
import { themeColor } from "../../../constant/color";
import { errorBlock } from "../../../controllers/errorBlock";
import axios from "../../../utils/axios";
import { useForm } from "react-hook-form";
import { FcCalendar } from "react-icons/fc";
import { useSelector } from "react-redux";
import AddDiscountOptions from "../../../components/ProjectManager/Discount/Modals/AddDiscountOptions";
import AgentdiscountSingle from "../../../components/ProjectManager/Discount/Modals/AgentdiscountSingle";
import { events } from "../../../utils/events";
import SitediscountSingle from "../../../components/ProjectManager/Discount/Modals/SitediscountSingle";
import SitediscountMultiple from "../../../components/ProjectManager/Discount/Modals/SitediscountMultiple";
import { useAsync } from "../../../utils/Hooks/useAsync";
import { client } from "../../../utils/api";
import { CustomButton } from "../../../components/CustomButton";
import SaleAgentsiteDiscountTable from "../../../components/ProjectManager/Discount/Table/SaleAgentsiteDiscountTable";
import toast from "react-hot-toast";
import { userController } from "../../../controllers/UserController";
import moment from "moment";

const Discount = () => {
  const [openoptions, setopenoptions] = useState(false);
  const [opensalesingle, setopensalesingle] = useState(false);
  const [opensitesingle, setopensitesingle] = useState(false);
  const [tabstate, settabstate] = useState(1);
  const [opensitesmultiple, setopensitesmultiple] = useState(false);
  const [data, setdata] = useState([]);
  const [agentname, setagentname] = useState("");
  const [salesdata, setsalesdata] = useState([]);
  const [loading, setloading] = useState(true);
  const [sitediscount, setsitediscount] = useState(null);
  const [loadingrevert, setloadingrevert] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  // get all site discount
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/salesdiscount/operation/viewdiscounthistoryforsite",
          {
            siteid: Number(siteid),
          }
        );

        setdata(res.data.body);
        setloading(false);
        // console.log(res.data);
      } catch (error) {
        errorBlock(error);
        setloading(false);
      }
    })();
  }, [refresh]);

  // get active discount in site
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await axios.post(
  //         "salesdiscount/operation/viewactivediscountforsite",
  //         {
  //           siteid: Number(siteid),
  //         }
  //       );

  //       // setdata(res.data.body);
  //       console.log(res.data, "active");
  //       // setloading(false);
  //       // console.log(res.data);
  //     } catch (error) {
  //       errorBlock(error);
  //       setloading(false);
  //     }
  //   })();
  // }, [refresh]);

  const {
    data: allsite,
    run: getSites,
    status: sitestatus,
    error: siteError,
  } = useAsync({
    data: [],
    status: "pending",
  });

  // get all site managed by user
  useEffect(() => {
    getSites(
      client(`/roles/getallusersbyrole`, {
        data: {
          role: "Project Manager",
        },
        method: "POST",
      })
    );
  }, [getSites]);

  // get active discount on site
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/salesdiscount/operation/viewactivediscountforsite",
          {
            siteid: Number(siteid),
          }
        );

        if (res.data.response == "success") {
          setsitediscount(res.data);
          return;
        }

        toast.error(res.data.response);
      } catch (error) {
        errorBlock(error);
        setloading(false);
      }
    })();
  }, [refresh]);
  // agent discount
  const handleDiscount = async (data) => {
    try {
      setloading(true);

      let res = await axios.post(
        "/salesdiscount/operation/viewdiscounthistoryforagent", //
        {
          siteid: Number(siteid),
          username: data?.username,
        }
      );

      let result = userController(res);

      if (result.type != "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }

      setsalesdata(res.data.body);
      setloading(false);
      setagentname(data.username);
      setrefresh((prev) => !prev);
    } catch (error) {
      errorBlock(error);
      setloading(false);
    }
  };

  // revert agent to site
  const handleRevert = async (data) => {
    try {
      setloadingrevert(true);
      let res = await axios.post(
        "/salesdiscount/operation/revertagenttositediscount", //
        {
          siteid: Number(siteid),
          username: agentname,
        }
      );

      let result = userController(res);

      if (result.type != "success") {
        toast.error(result.message);
        setloadingrevert(false);
        return;
      }

      if (result.message.response == "failure") {
        toast.error(result.message.message);
        setloadingrevert(false);
        setrefresh((prev) => !prev);
        return;
      }
      toast.success(result.message.message);
      setloadingrevert(false);
      getAgentdiscount();
      setrefresh((prev) => !prev);
    } catch (error) {
      errorBlock(error);
      setloadingrevert(false);
    }
  };

  // get all agent discount
  async function getAgentdiscount() {
    try {
      let res = await axios.post(
        "/salesdiscount/operation/viewdiscounthistoryforagent", //
        {
          siteid: Number(siteid),
          username: agentname,
        }
      );
      let result = userController(res);

      if (result.type != "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }

      setsalesdata(res.data.body);
    } catch (error) {
      errorBlock(error);
    }
  }

  const ActiveDiscount = ({ title, value, caption = "", date }) => {
    return (
      <div className="bg-white border-[1px] h-[130px] rounded p-3">
        <div className="flex items-start justify-between">
          <div className="">
            <div className="">
              <div className="font-semibold text-[13px] text-gray-800 w-[70%]">
                {title}
              </div>
              <p className="text-[14px] text-gray-600">{caption}</p>
            </div>
          </div>
          <div className="font-semibold text-[13px] bg-secondary text-white py-1 px-2 rounded"></div>
        </div>

        <div className="text-3xl font-semibold">{value} %</div>
        {/* <div className=" font-semibold flex items-center justify-end">
          <FcCalendar size={18} />
          {moment(date).format("lll")}
        </div> */}
      </div>
    );
  };

  return (
    <Layout>
      {opensitesmultiple && (
        <SitediscountMultiple
          sites={allsite}
          refreshBtn={setrefresh}
          setopen={setopensitesmultiple}
          open={opensitesmultiple}
        />
      )}
      {opensitesingle && (
        <SitediscountSingle
          refreshBtn={setrefresh}
          setopen={setopensitesingle}
          open={opensitesingle}
        />
      )}
      {opensalesingle && (
        <AgentdiscountSingle
          getAgentdiscount={getAgentdiscount}
          refreshBtn={setrefresh}
          setopen={setopensalesingle}
          open={opensalesingle}
        />
      )}
      {openoptions && (
        <AddDiscountOptions
          setopensitesmultiple={setopensitesmultiple}
          setopensitesingle={setopensitesingle}
          setopensalesingle={setopensalesingle}
          setopen={setopenoptions}
          open={openoptions}
        />
      )}
      <div className="mt-2 p-2 bg-white h-[90vh] rounded-md drop-shadow-sm">
        <div className="flex items-center pb-2 justify-between">
          <h3 className="font-semibold text-[16px]">
            List of discount created in
            <span className="text-secondary"> {sitename}</span>
          </h3>
          <CustomButton
            onClick={() => setopenoptions(true)}
            className={
              " bg-gray-900 p-2 h-[40px] rounded-md flex items-center justify-center"
            }
          >
            <p className="text-white m-0">Add Discount</p>
          </CustomButton>
        </div>
        <hr />
        <div className="grid grid-cols-4 gap-4 py-2">
          <ActiveDiscount
            title={`Active Discount on site ${sitename}`}
            value={
              sitediscount?.discountPercentage
                ? sitediscount?.discountPercentage
                : 0
            }
            date={sitediscount?.startDate ? sitediscount?.startDate : "-Nil-"}
          />
        </div>

        {/* switch tabs */}
        <div className="py-2">
          <div className="flex items-center pb-2 justify-between">
            <div className="">
              <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
                <div
                  onClick={() => settabstate(1)}
                  className={
                    tabstate == 1
                      ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                      : `text-center cursor-pointer px-2 py-1 text-[14px]`
                  }
                >
                  Site discount
                </div>
                <div
                  onClick={() => settabstate(2)}
                  className={
                    tabstate == 2
                      ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                      : `text-center cursor-pointer text-[14px] px-2 py-1 `
                  }
                >
                  Agent discount
                </div>
              </div>
            </div>
            {tabstate == 2 && (
              <div className="">
                <form
                  onSubmit={handleSubmit(handleDiscount)}
                  className="flex items-center border-[1px] ml-2 p-[2px] border-gray-200 rounded-md"
                >
                  <input
                    {...register("username", {
                      required: true,
                    })}
                    type="username"
                    className="px-[2px] text-[14px] placeholder:text-[13px] outline-none"
                    placeholder="Enter agent username"
                  />
                  <CustomButton
                    disable={loading}
                    className={
                      "bg-black flex items-center justify-center h-[33px] w-[100px] rounded-md"
                    }
                  >
                    <p className="text-white m-0">
                      {loading ? "Apply..." : "Apply"}
                    </p>
                  </CustomButton>
                </form>
                {errors.username && (
                  <span className="text-rose-400">Field is required</span>
                )}
              </div>
            )}
          </div>
        </div>
        <hr />
        {tabstate == 1 && (
          <div className="">
            <SiteDiscoutTable data={data} loading={loading} />
          </div>
        )}

        {tabstate == 2 && (
          <div className="">
            {agentname && (
              <div className="flex items-center justify-between py-2">
                <h2>Records of discount applied to {agentname}</h2>
                <CustomButton
                  disable={loadingrevert}
                  onClick={handleRevert}
                  title="This will apply site discount on the sales agent"
                  className={
                    "bg-secondary p-2 flex items-center justify-center h-[33px]  rounded"
                  }
                >
                  <p className="text-white m-0">
                    {loadingrevert
                      ? "Applying changes..."
                      : "Revert agent to site discount"}
                  </p>
                </CustomButton>
              </div>
            )}
            <hr />
            <SaleAgentsiteDiscountTable
              agentname={agentname}
              data={salesdata}
              loading={loading}
            />
          </div>
        )}
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default Discount;
