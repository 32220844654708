import { Link } from "react-router-dom";
import { Button } from "../../Button";

const RoleOverviewCard = ({ data }) => {
  return (
    <div
      className={
        "h-[200px]  decoration-none p-2 rounded bg-white drop-shadow-md"
      }
    >
      <div className="flex items-center justify-between h-full flex-col">
        <div className="flex item-center flex-col justify-between w-full">
          {/* <div className={`h-[30px] w-[20px] rounded `}></div> */}
          <div className="">
            <h2 className="my-0 text-center">{data.tag}</h2>
            {/* <p className="text-gray-700 text-center my-0">{data.link}</p> */}
          </div>
          <div></div>
        </div>
        <div className="h-[100px] flex items-center justify-center bg-gray-50 mt-2 w-[100px] rounded-full border-[1px]">
          {data?.icon}
        </div>
        <Link
          to={data.link}
          className={`p-2 mt-3  
           w-full cursor-pointer text-center 
          rounded text-[13px]
            text-gray-600
            underline
          `}
        >
          {data?.caption}
        </Link>
      </div>
    </div>
  );
};

export default RoleOverviewCard;
