import { useSelector } from "react-redux";
import BackBtn from "../../../components/BackBtn";
import { CustomButton } from "../../../components/CustomButton";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import { useEffect, useState, useMemo } from "react";
import { errorBlock } from "../../../controllers/errorBlock";
import axios from "../../../utils/axios";
import PaymentPlanTable from "../../../components/ProjectManager/PaymentPlan/PaymentPlanTable";
import AddPlanModal from "../../../components/ProjectManager/PaymentPlan/modals/AddPlanModal";
import AddDailyEnergyPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddDailyEnergyPlan";
import AddDailyPowerPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddDailyPowerPlan";
import AddCumulativeEnergyPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddCumulativeEnergyPlan";
import AddUnlimitedPlan from "../../../components/ProjectManager/PaymentPlan/modals/AddUnlimitedPlan";
import OtherSitePaymentPlan from "../../../components/ProjectManager/PaymentPlan/OtherSitePaymentPlan";
import AllDCUTable from "../../../components/ProjectManager/DCU/Table/AllDCUTable";
import { channelController } from "../../../controllers/channelController";
import { meterController } from "../../../controllers/MeterController";
import toast from "react-hot-toast";
import AllDCUInSite from "../../../components/ProjectManager/DCU/Table/AllDCUInSite";

const SiteDCU = () => {
  const [tabstate, settabstate] = useState(1);
  const [refresh, setrefresh] = useState(false);

  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);

  const [inputname, setinputname] = useState("");

  // get customer class
  useEffect(() => {
    (async () => {
      try {
        let res;
        if (tabstate == 1) {
          res = await axios.post(
            "/sitemanagement/dcu/getdcusnotassignedtosite",
            {}
          );
        } else {
          res = await axios.post("/sitemanagement/dcu/getdcusfromsite", {
            siteid: siteid,
          });
        }
        const result = meterController(res);

        if (result.type != "success") {
          return toast.error(result.message);
        }
        setdata(result.message.body);
        setloading(false);
      } catch (err) {
        errorBlock(err);
        setloading(false);
      }
    })();
    return () => {};
  }, [tabstate, refresh]);

  return (
    <Layout>
      {/* first vp */}
      <div className="bg-white p-2 shadow rounded">
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <div className="h-[30px]  mr-2 w-[20px] rounded bg-orange-200"></div>
            <PageTitle
              title={
                tabstate == 1
                  ? `Available DCUs`
                  : `DCU available in ${sitename}`
              }
              caption={""}
            />
          </div>
        </div>

        <hr className="mt-2" />

        {/* tabs */}
        <div className="mt-2">
          <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
            <div
              onClick={() => settabstate(1)}
              className={
                tabstate == 1
                  ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                  : `text-center cursor-pointer px-2 py-1 text-[14px]`
              }
            >
              All DCUs
            </div>
            <div
              onClick={() => settabstate(2)}
              className={
                tabstate == 2
                  ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                  : `text-center cursor-pointer text-[14px] px-2 py-1 `
              }
            >
              Your site DCU
            </div>
          </div>
        </div>

        <hr className="mt-4 mb-2" />

        <div className="flex justify-between items-end">
          <PageTitle
            title={
              tabstate == 1
                ? `All DCUs available to your company`
                : "All DCUs available to your site"
            }
            caption={""}
          />
        </div>

        <hr className="mb-2 mt-2" />
        {tabstate == 1 && (
          <div className="">
            <AllDCUTable
              data={data}
              tabstate={tabstate}
              loading={loading}
              refreshbtn={setrefresh}
            />
          </div>
        )}

        {tabstate == 2 && (
          <AllDCUInSite
            data={data}
            tabstate={tabstate}
            loading={loading}
            refreshbtn={setrefresh}
          />
        )}
      </div>
    </Layout>
  );
};

export default SiteDCU;
