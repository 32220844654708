import Backdrop from "../../Backdrop";
import CloseButton from "../../CloseButton";
import Rule from "../../Rule/Index";
import { useForm, SubmitHandler } from "react-hook-form";
import ErrorText from "../../Errors/ErrorText";
import { useState, useEffect } from "react";
import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Button } from "../../Button";
import { toast } from "react-hot-toast";
import { channelController } from "../../../controllers/channelController";
import axios from "../../../utils/axios";
import { Select } from "antd";
import { userController } from "../../../controllers/UserController";
import { getToken } from "../../../utils/token";
import { meterController } from "../../../controllers/MeterController";
import { useSelector } from "react-redux";

const AddAppUser = ({ open, setopen, tabdefault }) => {
  const [eye, seteye] = useState(false);
  const [tabstate, settabstate] = useState(tabdefault == true ? 2 : 1);
  const [password, setpassword] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const [selectedcst, setselectedcst] = useState("");
  const [CustomerType, setCustomerType] = useState([]);
  const [userloading, setuserloading] = useState(false);
  const siteDetails = useSelector((state) => state.projectSite);
  const [name, setname] = useState("");

  // get drop down
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/settings/getdropdown", {
          comboname: "customertype",
        });

        setCustomerType(res.data.list.split(","));
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const onSubmit = async (data) => {
    try {
      // console.log(data, "");

      if (!selectedcst) {
        return toast.error("Customer type is required.");
      }
      // console.log(data.email, "email", data.lastname, "lastname");
      setLoading(true);

      let res = await axios.post(
        "/auth/utility/customeraccount/createaccount",
        {
          firstname: data.firstname,
          lastname: data.lastname,
          username: data.email,
          defaultpassword: data.password,
          phone: data.phone,
          email: data.email,
          customertype: selectedcst,
          address: data.address,
        }
      );
      let result = meterController(res);

      if (result.type !== "success") {
        toast.error("Operation failed");
        setLoading(false);
        return;
      }

      toast.success(result.message.response);
      setopen(false);
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleUserName = async (data) => {
    try {
      // console.log(data);
      if (!name) return toast.error("Please enter a username");
      setuserloading(true);
      let res = await axios.post(
        "/auth/utility/customeraccount/isusernameused",
        {
          username: name,
        }
      );

      let result = userController(res);

      if (result.message == true) {
        toast.error("The username you entered has been used.");
        setuserloading(false);
        return;
      }

      if (result.message == false) {
        settabstate(2);
        setuserloading(false);
        return;
      }
    } catch (err) {
      toast.error(err.message);
      setuserloading(false);
    }
  };
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[450px] bg-white drop-shadow-md rounded-sm p-4">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-semibold text-[17px]">
                Register customer{" "}
              </div>
              <div className="text-gray-700 font-light">
                Fill the fields to register a customer in{" "}
                <span className="font-semibold">{siteDetails?.sitename}</span>
              </div>
            </div>
            <div className="flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div>
            {tabstate == 1 && (
              <div className="mt-4">
                <label className="text-primary font-normal text-sm">
                  Enter a username/email/phone/lastname/firstname for the
                  customer.
                </label>
                <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[40px]">
                  <div className="ml-2 w-full">
                    <input
                      required
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      type={"text"}
                      placeholder={"username of customer."}
                      className=" bg-gray-100 placeholder:font-thin w-full outline-none border-[0px]"
                    />
                  </div>
                </div>
                {tabstate == 1 && (
                  <div className=" w-100 mt-4 ">
                    <Button
                      text={"Continue"}
                      loading={userloading}
                      onClick={() => handleUserName()}
                    />
                  </div>
                )}
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              {tabstate == 2 && (
                <>
                  {/* email */}
                  <div className="mt-3 gap-4">
                    <input
                      placeholder="enter email..."
                      className="border-[1px] bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <span className="text-red-400 text-[12px]">
                        field is required
                      </span>
                    )}
                  </div>
                  {/* firstname */}
                  <div className="mt-3">
                    <input
                      placeholder="enter firstname..."
                      {...register("firstname", { required: true })}
                      className="border-[1px] bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                    />
                    {errors.firstname && (
                      <span className="text-red-400 text-[12px]">
                        field is required
                      </span>
                    )}
                  </div>
                  {/* lastname */}
                  <div className="mt-3">
                    <input
                      placeholder="enter lastname..."
                      className="border-[1px] bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                      {...register("lastname", { required: true })}
                    />
                    {errors.lastname && (
                      <span className="text-red-400 text-[12px]">
                        field is required
                      </span>
                    )}
                  </div>{" "}
                  {/* phone */}
                  <div className="mt-3">
                    <input
                      type="text"
                      placeholder="enter phone number..."
                      {...register("phone", { required: true })}
                      className="border-[1px] bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                    />
                    {errors.phone && (
                      <span className="text-red-400 text-[12px]">
                        field is required
                      </span>
                    )}
                  </div>
                  {/* password */}
                  <div className="mt-3 flex border-[1px] items-center bg-gray-100  border-gray-300 justify-center rounded p-2 w-full h-[40px]">
                    <input
                      type={eye ? "text" : "password"}
                      placeholder="enter password..."
                      className=" text-[15px] bg-transparent w-[90%] placeholder:text-[15px] placeholder:font-normal outline-none full border-none "
                      {...register("password", { required: true })}
                    />
                    <div
                      onClick={() => seteye((prev) => !prev)}
                      className="flex w-[10%] cursor-pointer items-center justify-center"
                    >
                      {eye ? <AiFillEye /> : <AiOutlineEye />}
                    </div>
                  </div>
                  {errors.password && (
                    <span className="text-red-400 text-[12px]">
                      field is required
                    </span>
                  )}
                  <div className="mt-">
                    <Rule />
                  </div>
                </>
              )}
              {tabstate == 3 && (
                <>
                  <div className="mt-3">
                    <textarea
                      style={{
                        height: "100%",
                        resize: "none",
                        fontSize: "14px",
                        background: "transparent",
                      }}
                      {...register("address", { required: true })}
                      className="w-full border-[1px] border-gray-500 focus-none  p-2 rounded input-box focus-none px-2 rounded-1  border-none outline-none"
                      placeholder={"Enter address."}
                    />
                    {errors.address && (
                      <span className="text-red-400 text-[12px]">
                        field is required
                      </span>
                    )}
                  </div>
                  <div className="mt-3">
                    <Select
                      placeholder={"Select a customer type"}
                      size="large"
                      style={{
                        width: "100%",
                        height: 45,
                        fontSize: 14,
                        border: "1px solid #aaa",
                        borderRadius: 5,
                      }}
                      value={selectedcst}
                      onChange={(e) => setselectedcst(e)}
                      bordered={false}
                      options={CustomerType.map((item) => {
                        return { value: item.trim(), label: item.trim() };
                      })}
                    />
                  </div>
                </>
              )}
              {tabstate != 1 && (
                <div className=" w-100 mt-4 grid grid-cols-2 gap-4">
                  <div
                    className="border-[1px] h-[40px] flex rounded items-center justify-center border-black"
                    onClick={() => settabstate(tabstate == 3 ? 2 : 1)}
                  >
                    <p className="m-0 p-0">Back</p>
                  </div>

                  {tabstate == 2 && (
                    <div
                      className="border-[1px] bg-black  h-[40px] flex rounded items-center justify-center border-black"
                      onClick={() => settabstate(3)}
                    >
                      <p className="m-0 p-0 text-white"> Next</p>
                    </div>
                  )}

                  {tabstate == 3 && (
                    <Button text={"Add Customer"} loading={loading} />
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddAppUser;
