import Chart from "react-apexcharts";
import React from "react";
import moment from "moment";

const ProjectManageTopupsLineChart = ({ data, height }) => {
  const date = data.length ? data.map((i) => String(i.transactionDate)) : [];
  const amount = data.length ? data.map((i) => Number(i.amount)) : [];

  return (
    <Chart
      options={{
        chart: {
          type: "datetime",
        },
        stroke: {
          curve: "straight",
          colors: "dodgerblue",
          width: 3,
        },
        markers: {
          size: 3,
          colors: "dodgerblue",
          strokeColors: "dodgerblue",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
        },
        // fill: {
        //   type: "solid",
        //   opacity: [],
        // },
        yaxis: {
          title: {
            text: "Amount",
          },
        },
        xaxis: {
          categories: date,
          labels: {
            formatter: (value, timestamp) => {
              // console.log(value, "value");
              // console.log(timestamp, "timestamp");
              return moment(value).format("YYYY-MM-DD HH:mm:ss");
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {},
        },
      }}
      series={[
        {
          name: "Top up amount",
          data: amount,
        },
      ]}
      height={500 || height}
      width="100%"
    />
  );
};

export default ProjectManageTopupsLineChart;
