import EmptyState from "../../../EmptyState";
import { HiUserGroup } from "react-icons/hi";
import moment from "moment";
import { toast } from "react-hot-toast";
import { channelController } from "../../../../controllers/channelController";
import { Button } from "../../../Button";
import axios from "../../../../utils/axios";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";

import { useState } from "react";
import { tableCustomStyles } from "../../../../helperFuncs";

const UserTable = ({ data, refreshBtn }) => {
  const [username, setUsername] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const handleActivate = async (item) => {
    try {
      if (!item?.username) {
        toast.error("Please select a username");
        return;
      }
      setLoading(true);
      let res = await axios.post("/auth/activateuseraccount", {
        username: item?.username,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }
      setMsg("Activating user account");
      toast.success("Account deactivated successfully.");
      setLoading(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleDeactivate = async (item) => {
    try {
      if (!item?.username) {
        toast.error("Please select a username");
        return;
      }
      setLoading(true);
      let res = await axios.post("/auth/deactivateuseraccount", {
        username: item?.username,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }
      setMsg("Activating user account");
      toast.success("Account deactivated successfully.");
      setLoading(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const columns = [
    {
      name: "Username",
      center: true,
      selector: (row) => row.username,
      grow: 1,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      center: true,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div>
            {item?.email ?? (
              <span className="text-[dodgerblue]">Incomplete registration</span>
            )}
          </div>
        );
      },
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      center: true,
      grow: 2,
    },

    {
      name: "Status",
      center: true,
      selector: (row) => row.activestatus,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div
            className={`${
              item?.activestatus == "active" ? "text-green-700" : "text-red-700"
            }`}
          >
            {item?.activestatus}
          </div>
        );
      },
    },
    {
      name: "Date registered",
      center: true,
      selector: (row) => row.dateregistered,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.dateregistered).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.deviceid,
      grow: 3,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            {item.activestatus == "active" ? (
              <div
                onClick={() => handleDeactivate(item)}
                className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
              >
                Deactivate
              </div>
            ) : (
              <div
                onClick={() => handleActivate(item)}
                className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
              >
                Activate
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      style={{
        height: "100%",
      }}
      className=" cursor-pointer"
      columns={columns}
      data={data}
      pagination
      highlightOnHover
      paginationPerPage={10}
      customStyles={tableCustomStyles}
    />
  );
};

export default UserTable;
