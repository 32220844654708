import { useEffect, useState } from "react";
import { CustomButton } from "../../../components/CustomButton";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import { useForm } from "react-hook-form";
import { errorBlock } from "../../../controllers/errorBlock";
import axios from "../../../utils/axios";
import ErrorText from "../../../components/Errors/ErrorText";
import useSelection from "antd/lib/table/hooks/useSelection";
import { useSelector } from "react-redux";
import { channelController } from "../../../controllers/channelController";
import toast from "react-hot-toast";
import { VscRefresh } from "react-icons/vsc";
import PlansOnMeter from "../../../components/SalesAgent/Table/PlansOnMeter";
import UnitPreview from "../../../components/SalesAgent/Modals/UnitPreview";
const SalesAgentDashboard = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [loading, setloading] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [error, seterror] = useState(false);
  const [modal, setmodal] = useState(false);
  const [unitdetails, setunitdetails] = useState({});
  const [payment, setpayment] = useState([]);
  const [tabState, settabState] = useState(0);
  const [customer, setcustomer] = useState(null);
  const { siteid, sitename } = useSelector((state) => state.projectSite);

  const onSubmit = async (data) => {
    try {
      seterror("");
      setloading(true);
      let res = await axios.post("/customer/getallmetercustomers", {
        column: "meter",
        columnparameter: data?.meternumber,
        zone: Number(siteid),
      });

      const result = channelController(res);

      if (result.type !== "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }

      if (result.message.body.length == 0) {
        seterror(
          `No customer with meter number "${data?.meternumber}" found, Try again.`
        );
        setloading(false);
        return;
      }
      setcustomer(result.message.body[0]);
      settabState(1);
      setloading(false);
    } catch (error) {
      errorBlock(error);
    }
  };

  const onPreview = async (data) => {
    try {
      setloading(true);
      let res = await axios.post("/vending/tieredpricing/previewpurchase", {
        meternumber: customer?.meter,
        amount: data?.amount,
      });
      const result = channelController(res);
      if (result.type !== "success") {
        toast.error(res.data.message);
        setloading(false);
        return;
      }

      // console.log(result.message, "response");
      setunitdetails(result.message);
      setloading(false);
      setmodal(true);
    } catch (error) {
      errorBlock(error);
      setloading(false);
    }
  };

  const handleMeters = async () => {
    try {
      setloading(true);

      let res = await axios.post(
        "/vending/paymentplan/getallpaymentplansformeter",
        {
          meternumber: customer?.meter,
        }
      );

      const result = channelController(res);

      if (result.type !== "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }

      //console.log(result.message);

      setpayment(result.message.body);

      settabState(2);
      setloading(false);
    } catch (error) {
      errorBlock(errorBlock);
    }
  };

  return (
    <Layout>
      {modal && (
        <UnitPreview
          setopen={setmodal}
          details={unitdetails}
          open={modal}
          customer={customer}
          refresh={setrefresh}
        />
      )}
      <div className="h-[90vh] bg-white shadow rounded p-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <div className="h-[30px]  mr-2 w-[20px] rounded bg-orange-200"></div>
            <PageTitle
              title={
                tabState == 2
                  ? "Plans available on meter"
                  : "Vend customer meter"
              }
              caption={
                tabState == 2
                  ? `List of plans available for ${customer?.meter}`
                  : ""
              }
            />
          </div>
          {customer && (
            <CustomButton
              onClick={() => {
                settabState(0);
                setcustomer(null);
                reset({
                  meternumber: "",
                });
              }}
              className={
                "p-2 flex border-[1px] border-black items-center justify-center rounded"
              }
            >
              <VscRefresh size={18} />
              <p className="m-0 text-black ml-1 text-[14px]">Vend new meter</p>
            </CustomButton>
          )}
        </div>
        <hr className="my-2" />
        <div className="w-full h-full flex items-start justify-center">
          {tabState == 0 && (
            <div className="w-[400px] mt-[50px]">
              <h2 className="text-[18px] text-center text-semibold">
                Vend meter
              </h2>
              <p className="text-center">Enter customer meter number</p>
              {error && <ErrorText text={error} />}
              <hr />
              <form className="" onSubmit={handleSubmit(onSubmit)}>
                <input
                  placeholder="Enter meter number"
                  type={"text"}
                  {...register("meternumber", {
                    required: true,
                  })}
                  className="bg-gray-100 mt-2 h-[40px] border-[1px] rounded-sm p-2 placeholder:font-light w-full outline-none "
                />

                {errors.meternumber && (
                  <ErrorText text={"Meter number is required."} />
                )}
                <CustomButton
                  loading={loading}
                  type="submit"
                  className={
                    "mt-4 border-[1px] bg-black text-center w-full p-2 border-gray-300 rounded-md flex items-center justify-center"
                  }
                >
                  <p className="m-0 text-white pr-2"> Submit</p>
                </CustomButton>
              </form>
            </div>
          )}

          {tabState == 1 && (
            <div className="w-[400px] mt-[50px]">
              <h2 className="text-[18px] text-center text-semibold">
                Customer details
              </h2>
              {/* <p>Confirm customer</p> */}
              <hr />

              <div className="flex items-center justify-between p-1 mt-3 w-full border-gray-300 border-[1px] rounded-md ">
                <div className="font-light text-gray-700">Fullname:</div>
                <div className="font-semibold text-gray-800 px-3">
                  {customer?.firstname + " " + customer?.lastname[0]}
                </div>
              </div>

              <div className="flex items-center justify-between p-1 mt-3 w-full border-gray-300 border-[1px] rounded-md ">
                <div className="font-light text-gray-700">Meter number:</div>
                <div className="font-semibold text-gray-800 px-3">
                  {customer?.meter}
                </div>
              </div>

              <hr className="my-2" />
              <div className="flex items-center justify-between">
                <CustomButton
                  onClick={() => {
                    settabState(0);
                    setcustomer(null);
                  }}
                  className=" border p-2 h-[36px] rounded-md flex items-center justify-center"
                >
                  <p className="m-0 text-black text-[13px]">Back</p>
                </CustomButton>
                <CustomButton
                  onClick={handleMeters}
                  loading={loading}
                  className="bg-black border p-2 h-[36px] rounded-md flex items-center justify-center"
                >
                  <p className="m-0 text-white text-[13px]">View plans</p>
                </CustomButton>
              </div>
            </div>
          )}

          {tabState == 2 && (
            <div className="w-full ">
              <div className="py-2 flex items-center justify-between">
                <div className="">
                  {/* Don't want to use payment plans?. */}
                  Use the field to view the estimated unit you will get for a
                  given amount
                  {/* <br /> Enter an amount to preview unit breakdown. */}
                </div>
                <form
                  onSubmit={handleSubmit(onPreview)}
                  className="flex items-center"
                >
                  {errors.amount && <ErrorText text={"Amount is required"} />}

                  <div className="flex">
                    <input
                      placeholder="Enter amount"
                      type={"number"}
                      {...register("amount", {
                        required: true,
                      })}
                      className="bg-gray-100 h-[40px] border-[1px] rounded-sm p-2 placeholder:font-light w-full outline-none "
                    />

                    <CustomButton
                      loading={loading}
                      className="bg-black border w-[200px] ml-2 p-2 h-[40px] rounded-md flex items-center justify-center"
                    >
                      <p className="m-0 text-white text-[13px]">Preview unit</p>
                    </CustomButton>
                  </div>
                </form>
              </div>
              <PlansOnMeter data={payment} customer={customer} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SalesAgentDashboard;
