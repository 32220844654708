import Backdrop from "../../../../Backdrop";
import CloseButton from "../../../../CloseButton";
import Rule from "../../../../Rule/Index";
import { useSelector } from "react-redux";

const AddPlanModal = ({
  open,
  setopen,
  setopenena,
  setopenenc,
  setopenenb,
  setopenend,
}) => {
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[500px] rounded-md bg-white drop-shadow-md  p-3">
          <div className="flex items-start justify-between">
            <div className="text-semibold">
              <div className=" font-semibold text-[17px]">
                Create Payment Plan
              </div>
              <div className="w-[90%] text-gray-700">
                Select a payment plan type you would like to create for
                customers in{" "}
                <span className="text-secondary font-semibold">{sitename}</span>{" "}
                <br />
              </div>
            </div>
            <CloseButton closeBtn={setopen} />
          </div>
          <Rule />
          <div className="text-semibold">
            <div
              className="even:bg-gray-50 hover:bg-gray-100 px-1 py-3 cursor-pointer my-2 "
              onClick={() => {
                setopen(false);
                setopenenb(true);
              }}
            >
              <div className="text-semibold">Daily Power limit</div>
            </div>
            <div
              className="even:bg-gray-50 hover:bg-gray-100 px-1 py-3 cursor-pointer my-2 "
              onClick={() => {
                setopen(false);
                setopenena(true);
              }}
            >
              <div className="text-semibold">Daily Energy limit</div>
            </div>
            <div
              className="even:bg-gray-50 hover:bg-gray-100 px-1 py-3 cursor-pointer my-2 "
              onClick={() => {
                setopen(false);
                setopenenc(true);
              }}
            >
              <div className="text-semibold">Cumulative Energy limit</div>
            </div>
            {/* <div
              className="even:bg-gray-50 hover:bg-gray-100 px-1 py-3 cursor-pointer my-2 "
              onClick={() => {
                setopen(false);
                setopenend(true);
              }}
            >
              <div className="text-semibold">Set Unlimited energy</div>
            </div> */}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddPlanModal;
