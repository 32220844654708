import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";
import DataTable from "react-data-table-component";

const AllSiteDiscountTable = ({ data, setselectedzone, loading }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [modal, setModal] = useState(false);

  const columns = [
    {
      name: "Created by",
      center: true,
      selector: (row) => row.creator,
      grow: 1,
    },
    {
      name: "Site name",
      center: true,
      selector: (row) => row.sitename,
      grow: 1,
    },
    {
      name: "Role",
      center: true,
      selector: (row) => row.role,
      grow: 1,
    },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.datecreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.datecreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.siteid,
      grow: 1,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative  rounded-md items-center justify-between">
            <Link
              to={`/dashboard/projectsite/${item.siteid}`}
              className="border-[1px] cursor-pointer ml-2 rounded border-[dodgerblue] text-[12px] font-light px-4 py-2"
            >
              Details
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      style={{
        height: "100%",
      }}
      columns={columns}
      data={data}
      pagination
      selectableRows
      progressPending={loading}
      onSelectedRowsChange={(row) => {
        setselectedzone({
          allSelected: row.allSelected,
          selectedCount: row.selectedCount,
          selectedRows: row.selectedRows,
        });
      }}
      paginationPerPage={10}
    />
  );
};

export default AllSiteDiscountTable;
