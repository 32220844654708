import axios from "../../../../utils/axios";
import SalesTopUpTable from "../../../../components/SalesAgent/Table/SalesTopUpTable";
import { countryList } from "../../../../helperFuncs/countryList";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { errorBlock } from "../../../../controllers/errorBlock";
import moment from "moment";

const ProjectManagerSalesTopUp = () => {
  const userObject = useSelector((state) => state.userDetails);
  const { country } = useSelector((state) => state.projectSite);
  const [salesStartDate, setsalesStartDate] = useState(
    moment(Date.now()).subtract("1", "week").format("YYYY-MM-DD")
  );

  const [salesEndDate, setsalesEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [topups, settopups] = useState([]);
  // get tops
  useEffect(() => {
    (async () => {
      try {
        if (!userObject) return;
        const res = await axios.post("/wallet/projectmanager/topupanalytics", {
          username: userObject.username,
          currencycode: countryList[country]["Currency Code"],
          startdate: salesStartDate,
          enddate: salesEndDate,
        });
        // Handle the response as needed
        settopups(res.data.topUps);
      } catch (error) {
        errorBlock(error);
      }
    })();
  }, []);
  return (
    <div className="h-[auto] p-4 border-[1px] mt-2 bg-white shadow-sm rounded">
      <div className=" items-center justify-between">
        <h2 className=" my-0 text-[15px] font-semibold">Top up history</h2>
        <p className="my-0 text-[13px]">
          Logs of your top up history on sales agent account
        </p>
      </div>
      <div className="h-[500px] bbg-white ">
        <SalesTopUpTable data={topups} />
      </div>
    </div>
  );
};

export default ProjectManagerSalesTopUp;
