import Backdrop from "../../../../Backdrop";
import CloseButton from "../../../../CloseButton";
import Rule from "../../../../Rule/Index";
import { useForm } from "react-hook-form";
import ErrorText from "../../../../Errors/ErrorText";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../../../utils/axios";
import { Select } from "antd";
import { CustomButton } from "../../../../CustomButton";
import { meterController } from "../../../../../controllers/MeterController";
import { useSelector } from "react-redux";
import { errorBlock } from "../../../../../controllers/errorBlock";

const AddDCUToSite = ({ open, setopen, detials, refreshbtn }) => {
  const [loading, setloading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { siteid } = useSelector((state) => state.projectSite);

  const handleAddCustomer = async (data) => {
    try {
      setloading(true);
      let res = await axios.post("/sitemanagement/dcu/adddcutosite", {
        siteid: siteid,
        dcuid: detials?.dcuid,
        dcualias: data?.dcualias,
      });
      let result = meterController(res);

      if (result.type !== "success") {
        setloading(false);
        toast[result.type](result.message);
        return;
      }
      refreshbtn((prev) => !prev);
      toast.success("DCU added to site succefully.");
      setopen(false);
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[400px] bg-white drop-shadow-md rounded-sm p-4">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-semibold text-[17px]">Add to site</div>
              <div className="text-gray-700 font-light">
                Fill the fields to DCU to you site
              </div>
            </div>
            <div className="flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <form onSubmit={handleSubmit(handleAddCustomer)}>
            <div className="mt-4">
              <div className="flex w-full bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[40px]">
                <input
                  required
                  {...register("dcualias", { required: true })}
                  type={"text"}
                  placeholder={"Enter Dcu alias"}
                  className="bg-gray-100  p-2 placeholder:font-thin w-full outline-none border-[0px]"
                />
              </div>
              {errors.dcualias && <ErrorText />}
            </div>

            <CustomButton
              loading={loading}
              className={
                "bg-black flex items-center justify-center h-[40px] w-full mt-4 rounded-md"
              }
            >
              <p className="text-white m-0">{"Add"}</p>
            </CustomButton>
          </form>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddDCUToSite;
