import React from "react";
import Chart from "react-apexcharts";

const SalesAgentRevenueChart = ({ currency, data }) => {
  const labels = Object.keys(data);
  const series = Object.keys(data).map((item) =>
    parseFloat(data[item][currency]?.totalRevenue || 0)
  );

  return (
    <Chart
      options={{
        labels,
        legend: {
          show: true,
          position: "bottom",
        },
        tooltip: {
          y: {
            formatter: (value) => `${currency} ${value.toLocaleString()}`,
          },
          title: {
            formatter: (seriesName) => `${seriesName} Sales`,
          },
        },
      }}
      series={series}
      type="donut"
      width="500"
    />
  );
};

export default SalesAgentRevenueChart;
