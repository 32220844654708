import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { Button } from "../../../Button";
import { themeColor as color } from "../../../../constant/color";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";

const CustomersTable = ({ data }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  let grid = useRef();
  let num = 1;

  const [selectedId, setSelectedId] = useState(null);
  const [modal, setModal] = useState(false);

  const columns = [
    {
      name: "customerid",
      center: true,
      selector: (row) => row.customerid,
      grow: 1,
    },
    {
      name: "agent",
      center: true,
      selector: (row) => row.agent,
      grow: 1,
    },

    {
      name: "customertype",
      center: true,
      selector: (row) => row.customertype,
      grow: 1,
    },
    {
      name: "polenumber",
      center: true,
      selector: (row) => row.polenumber,
      grow: 1,
    },
    {
      name: "section",
      center: true,
      selector: (row) => row.customertype,
      grow: 1,
    },
    {
      name: "gps",
      center: true,
      selector: (row) => row.gps,
      grow: 1,
    },
    {
      name: "Meter",
      center: true,
      selector: (row) => row.meter,
      grow: 1,
    },
    {
      name: "zone",
      center: true,
      selector: (row) => row.zone,
      grow: 1,
    },
    {
      name: "Full name",
      // selector: (row) => row.,
      center: true,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div>{item.firstname + item.lastname}</div>
          </div>
        );
      },
    },

    {
      name: "Date registered",
      center: true,
      selector: (row) => row.datecreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.datecreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.deviceid,
    //   grow: 3,
    //   cell: (item) => {
    //     return (
    //       <div className="flex relative rounded-md items-center justify-between">
    //         <div
    //           onClick={() => {}}
    //           className="border-[1px] cursor-pointer rounded border-gray-400 text-[12px] font-light px-4 py-2"
    //         >
    //           Details
    //         </div>
    //         {item.activestatus == "active" ? (
    //           <div
    //             // onClick={() => handleDeactivate(item)}
    //             className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
    //           >
    //             Deactivate
    //           </div>
    //         ) : (
    //           <div
    //             // onClick={() => handleActivate(item)}
    //             className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
    //           >
    //             Activate
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <DataTable
      style={{
        height: "100%",
      }}
      columns={columns}
      data={data}
      pagination
      paginationPerPage={10}
    />
  );
};

export default CustomersTable;
