import Chart from "react-apexcharts";

const PowerQualityChart = ({}) => {
  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [],
    },
  };
  const series = [
    {
      name: "Energy",
      data: [],
    },
    {
      name: "Voltage",
      data: [],
    },
    {
      name: "current",
      data: [],
    },
  ];
  return (
    <Chart
      height={500}
      options={options}
      series={series}
      type="bar"
      width="100%"
    />
  );
};

export default PowerQualityChart;

//  <Line
//    data={{
//      labels: [], // x axis
//      datasets: [
//        {
//          type: "bar",
//          label: "Voltage",
//          fill: false,
//          backgroundColor: "#0183d0e2",
//          data: [],
//          borderColor: "#0183d0",
//          radius: 1,
//          borderWidth: 0,
//          pointBorderWidth: 2,
//          tension: 0.4,
//        },
//        {
//          type: "bar",
//          label: "current",
//          fill: false,
//          backgroundColor: "tomato",
//          data: [],
//          borderColor: "tomato",
//          radius: 1,
//          borderWidth: 0,
//          pointBorderWidth: 2,
//          tension: 0.4,
//        },
//        {
//          type: "bar",
//          label: "frequency",
//          fill: false,
//          backgroundColor: "orange",
//          data: [],
//          borderColor: "",
//          radius: 1,
//          borderWidth: 0,
//          pointBorderWidth: 2,
//          tension: 0.4,
//        },
//      ],
//    }}
//    options={{
//      maintainAspectRatio: false,
//      responsive: true,
//      plugins: {
//        legend: false,
//        title: {
//          display: false,
//        },
//        tooltip: {
//          // callbacks: {
//          //   label: function (tooltipItem, data) {
//          //     return numFormtter(parseInt(tooltipItem.parsed.y));
//          //   },
//          // },
//        },
//      },
//      scales: {
//        x: {
//          display: true,
//          grid: {
//            display: false,
//          },
//        },
//        y: {
//          display: true,
//          grid: {
//            display: true,
//            borderDash: [10],
//            borderDashOffset: 20,
//            borderWidth: 0,
//            color: "#eee",
//          },
//        },
//      },
//    }}
//  />;
