import React, { useState, useEffect, useCallback, useMemo } from "react";
import Layout from "../../../../components/Layout";
import { useAsync } from "../../../../utils/Hooks/useAsync";
import { client } from "../../../../utils/api";
import { Link, useParams } from "react-router-dom";
import ProfileImg from "../../../../assets/svg/projectimg.svg";
import moment from "moment";
import SiteDetailsCard from "../../../../components/ProjectManager/ProjectSite/SiteDetailsCard";
import SiteDetailsCardRight from "../../../../components/ProjectManager/ProjectSite/SiteDetailsCardRight";
import BackBtn from "../../../../components/BackBtn";
import { FaUserShield } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
/** Styles */
import Rule from "../../../../components/Rule/Index";
import { useGetSiteDetailsMutation } from "../../../../services/projectsite.services";
import { countryList } from "../../../../helperFuncs/countryList";
import { errorBlock } from "../../../../controllers/errorBlock";
import { useGetAllCustomerMetersInSiteMutation } from "../../../../services/meter.services";
import AssignedMetersTable from "../../../../components/CustomerManager/Tables/AssignedMetersTable";

const CustomerManagerDetails = () => {
  const [cardtype, setcardtype] = useState({});
  const [currency, setcurrency] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [tab, settab] = useState("customer");
  const { data: userDetails, run: getUser } = useAsync({
    data: [],
    status: "pending",
  });

  const { data: meters, run: getallmetersinzone } = useAsync({
    data: {},
    status: "pending",
  });

  const { siteid, username } = useParams();

  // get user
  useEffect(() => {
    getUser(
      client(`/auth/useraccount/getuser`, {
        data: { username },
        method: "POST",
      })
    );
  }, [getUser, username]);

  // get sales history per zone based on vending
  const [startdate, setStartDate] = useState(
    moment(Date.now()).startOf("year").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  const [
    getSiteDetails,
    { isLoading: siteloading, error: siteError, data: siteData },
  ] = useGetSiteDetailsMutation();

  useEffect(() => {
    getSiteDetails({ siteid });
  }, []);

  const [
    getAllCustomerMetersInSite,
    { isLoading: metersloading, error: metersError, data: metersData },
  ] = useGetAllCustomerMetersInSiteMutation();

  // get Site details
  useEffect(() => {
    getAllCustomerMetersInSite({
      siteid: Number(siteid),
      startdatecreated: startdate,
      enddatecreated: enddate,
      customermanager: username,
    }).unwrap();
  }, []);

  useEffect(() => {
    if (metersError) {
      errorBlock(metersError);
    }
  }, [metersError]);

  useEffect(() => {
    if (siteData?.country) {
      setcurrency(countryList[siteData?.country]["Currency Code"]);
    }
  }, [siteData]);

  useEffect(() => {
    if (siteError) {
      errorBlock(siteError);
    }
  }, [siteError]);

  return (
    <Layout>
      <div className="mb-40">
        {<BackBtn text={"Go back"} />}
        <div className="">
          <div className=" p-2 rounded shadow mt-3 bg-white items-center">
            <div className="flex items-center justify-between">
              <div className="">
                <div className="flex">
                  <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
                  <div className="flex flex-col items-start">
                    <div className="ml-2">Details of customer manager: </div>
                    <div className="ml-2">
                      Username:{" "}
                      <span className=" font-semibold">
                        {userDetails?.username
                          ? `${userDetails?.username[0].toUpperCase()}${userDetails?.username.substring(
                              1
                            )} `
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center w-[170px] justify-end">
                Site managed:
                {siteData?.sitename && (
                  <Link
                    to={`/dashboard/projectsite/${siteid}`}
                    className=" font-semibold underline pl-2"
                  >
                    {siteData?.sitename}
                  </Link>
                )}
              </div>
            </div>
            {/* user details */}
            <div className=" mt-4 grid grid-cols-4 gap-4 w-full py-4 border rounded">
              {/* full name */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <FaUserShield size={24} />
                </div>
                <div className="text-gray-400">Full Name</div>
                <div className="font-semibold">
                  {!userDetails?.firstname
                    ? "Registration not complete"
                    : userDetails?.firstname + " " + userDetails?.lastname}
                </div>
              </div>
              {/* Email */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <MdMarkEmailRead size={24} />
                </div>
                <div className="text-gray-400">Email</div>
                <div className="font-semibold">
                  {!userDetails
                    ? "Registration not complete"
                    : userDetails?.email}
                </div>
              </div>
              {/* Phone */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <FaPhone size={24} />
                </div>
                <div className="text-gray-400">Phone</div>
                <div className="font-semibold">
                  {!userDetails
                    ? "Registration not complete"
                    : userDetails?.phone}
                </div>
              </div>
              {/* Date registered */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <MdDateRange size={24} />
                </div>
                <div className="text-gray-400">Date registered</div>
                <div className="font-semibold">
                  {moment(userDetails?.dateregistered).format("LLL")}
                </div>
              </div>
            </div>

            <div className="w-full hidden project-datails__title">
              Project manager details for{" "}
              <span className="zone-name">{siteid}</span>
            </div>
          </div>
          <div className="hidden flex-row p-3 bg-white">
            <div className="hidden md:block lg:block xl:block w-2/12">
              <div className="project-img flex items-center justify-center">
                <img
                  src={ProfileImg}
                  alt="img"
                  style={{ width: "70px", height: "70px" }}
                />
              </div>
            </div>
            <div className="hidden flex-grow-0 flex-shrink-0 w-1/3">
              <div className="flex-row items-center justify-between">
                <div className="sm:col-span-12 sm:col-start-1 sm:col-end-7 lg:col-span-7 xl:col-span-7 xxl:col-span-7">
                  <div className="">
                    <div className="project-entry flex items-center justify-between  ">
                      <div className="project-title text-sm">Full Name</div>
                      <div className="project-value">
                        {!userDetails?.firstname
                          ? "Registration not complete"
                          : userDetails?.firstname +
                            " " +
                            userDetails?.lastname}
                      </div>
                    </div>
                    <div className="project-entry mt-2 flex items-center justify-between">
                      <div className="project-title">Phone Number</div>
                      <div className="project-value">{userDetails?.phone}</div>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-12 sm:col-start-1 sm:col-end-7 lg:col-span-7 xl:col-span-7 xxl:col-span-7">
                  <div className="">
                    <div className="project-entry mt-2 flex items-center justify-between">
                      <div className="project-title ">Email</div>
                      <div className="project-value">
                        {!userDetails?.email
                          ? "Registration not complete."
                          : userDetails?.email}
                      </div>
                    </div>
                    <div className="project-entry flex mt-2 items-center justify-between">
                      <div className="project-title">Date Registered</div>
                      <div className="project-value">
                        {moment(userDetails?.dateregistered).format("LLL")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Customer onboarded in the site */}
          <div className="p-2 bg-white rounded shadow mt-2">
            <div className="">
              <div className="flex items-center justify-between">
                <div className="font-semibold">Customer records</div>
                <div
                  className=" cursor-pointer"
                  onClick={() => {
                    settab("customer");
                  }}
                >
                  <IoIosArrowDown />
                </div>
              </div>
              <Rule />
              {tab == "customer" && (
                <div className="grid grid-cols-2 gap-2 mt-2">
                  <div className="">
                    <SiteDetailsCard
                      currency={currency}
                      userDetails={userDetails ?? {}}
                      desc={`Number of customers onboarded since ${
                        userDetails ? userDetails?.username : ""
                      }
                    was made a project manager.`}
                      imgType={"customer"}
                      value={"0"}
                    />
                  </div>
                  <div className="bg-white rounded-1 shadow-sm">
                    <SiteDetailsCardRight
                      currency={currency}
                      title={"Number of customers onboarded from site today."}
                      caption={"Total customer"}
                      value={"0"}
                      imgType={"customer"}
                      bg={"#FFF8EF"}
                      link={`/dashboard/records/users/${siteid}/${username}`}
                      border={`border-2 border-yellow-500`}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="mt-4">
              <div className="font-semibold mb-3">
                History of meters assigned by {username}
              </div>
              <AssignedMetersTable
                refresh={setrefresh}
                data={metersData?.body ?? []}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default CustomerManagerDetails;
