import { useState } from "react";

import DataTable from "react-data-table-component";

import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { channelController } from "../../../../controllers/channelController";
import { errorBlock } from "../../../../controllers/errorBlock";
import axios from "../../../../utils/axios";
import AddDCUToSite from "../modals/AddDCUToSite";
import DCUInfoModal from "../modals/DCUInfoModal";
import { tableCustomStyles } from "../../../../helperFuncs";
const AllDCUTable = ({ data, loading, refreshbtn, tabstate }) => {
  const [details, setdetails] = useState(null);
  const [showinfo, setshowinfo] = useState(false);
  const [selected, setselected] = useState(null);
  const [open, setopen] = useState(false);
  const { siteid } = useSelector((state) => state.projectSite);

  const columns = [
    {
      name: "Batch ID",
      center: true,
      selector: (row) => row.batchid,
      grow: 3,
    },

    {
      name: "DCU Id",
      center: true,
      selector: (row) => row.dcuid,
      grow: 3,
    },
    {
      name: "Model",
      center: true,
      selector: (row) => row.model,
      grow: 1,
    },
    {
      name: "Capacity",
      center: true,
      selector: (row) => row.devicecapacity,
      grow: 1,
    },
    {
      name: "Device serial",
      center: true,
      selector: (row) => row.serialid,
      grow: 3,
    },
    {
      name: "Action",
      selector: (row) => row.id, // Assuming you have an id field for each row
      grow: 3,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <div
              onClick={() => {
                setselected(item);
                setopen(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-sky-600 text-[12px] text-sky-600 font-light px-4 py-2"
            >
              <p className="m-0">Add to site</p>
            </div>
            <div
              onClick={() => {
                setdetails(item);
                setshowinfo(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              View Info
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {open && (
        <AddDCUToSite
          detials={selected}
          open={open}
          setopen={setopen}
          refreshbtn={refreshbtn}
        />
      )}

      {details && (
        <DCUInfoModal open={showinfo} setopen={setshowinfo} dcuinfo={details} />
      )}

      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </>
  );
};

export default AllDCUTable;
