import { useParams, useSearchParams } from "react-router-dom";
import BackBtn from "../../../../components/BackBtn";
import Layout from "../../../../components/Layout";
import {
  useGetAllSiteMeterMapMutation,
  useGetMetersInDCUMapMutation,
} from "../../../../services/map.services";
import { useEffect, useState } from "react";
import { errorBlock } from "../../../../controllers/errorBlock";
import MetersInDCULocation from "../../../../components/ProjectManager/ProjectSite/Map/MetersInDCULocation";
import { countryList } from "../../../../helperFuncs/countryList";
import { useGetSiteDetailsMutation } from "../../../../services/projectsite.services";

const ProjectSiteDCULocation = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  let { siteid, dcuid } = useParams();
  const [currency, setcurrency] = useState("");

  const [
    getSiteDetails,
    { isLoading: siteloading, error: siteError, data: siteData },
  ] = useGetSiteDetailsMutation();

  // get Site details
  useEffect(() => {
    getSiteDetails({
      siteid: siteid,
    }).unwrap();
  }, []);

  useEffect(() => {
    if (siteData?.country) {
      setcurrency(countryList[siteData?.country]["Currency Code"]);
    }
  }, [siteData]);

  useEffect(() => {
    if (siteError) {
      errorBlock(siteError);
    }
  }, [siteError]);

  const [
    getMetersInDCUMap,
    { isLoading: sitemapLoading, error: sitemapError, data: sitemapData },
  ] = useGetMetersInDCUMapMutation();

  useEffect(() => {
    getMetersInDCUMap({ siteid, dcuid: dcuid }).unwrap();
  }, []);

  useEffect(() => {
    if (sitemapError) {
      errorBlock(sitemapError);
    }
  }, [sitemapError]);

  return (
    <Layout>
      <div className="w-full h-auto p-4 rounded shadow-md bg-white">
        <div className="">
          <BackBtn />
        </div>
        <hr className="my-3" />
        <div className="flex items-center pb-2 justify-between">
          <div className="font-semibold">
            Location of meters in DCU in {siteData?.sitename ?? ""}
          </div>
          <div className=" cursor-pointer px-2">
            Count of meters:{" "}
            <span className="font-semibold">
              {sitemapData?.total_meters ?? 0}
            </span>
          </div>
        </div>
        <div className="h-[600px] rounded border-[1px]">
          <MetersInDCULocation data={sitemapData?.devices ?? []} zoom={4} />
        </div>
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default ProjectSiteDCULocation;
