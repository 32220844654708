import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Layout from "../../components/Layout";
// import MeterTable from "../../components/Meters/Table/MeterTable";
import TableTop from "../../components/TableTop";
import TopNav from "../../components/TopNav";
import axios from "../../utils/axios";
// import { meterController } from "../../controllers/MeterController";
// import MeterInfoModal from "../../components/Meters/Modals/MeterInfoModal";
import { MdElectricMeter } from "react-icons/md";
import BackBtn from "../../components/BackBtn";
// import MeterTopCard from "../../components/Meters/MeterTopCard";
import DateRangePicker from "../../components/DateRangePicker";
// import MeterCountModal from "../../components/Meters/MeterCountModal";
// import AllMeterTable from "../../components/Meters/Table/AllMeterTable";
import AddCustomer from "../../components/Customer/AddCustomer";
// import AssignMeter from "../../components/Meters/AssignMeter";
import { getToken } from "../../utils/token";
import AssignMeter from "../../components/Meter/modals/AssignMeter";
import AllMeterTable from "../../components/Meter/Table/AllMeterTable";
import MeterCountModal from "../../components/Meter/modals/MeterCountModal";
import { meterController } from "../../controllers/MeterController";
import MeterInfoModal from "../../components/Meter/modals/MeterInfoModal";
import { useSelector } from "react-redux";
import AllBatchMeterTable from "../../components/Meter/Table/AllBatchMeterTable";
import { generalController } from "../../controllers/generalController";
import { errorBlock } from "../../controllers/errorBlock";
const Meters = () => {
  // register  == getallmeters
  // register  == assigned by utilit
  // decommission  == removed
  // inservice  == actively

  const user = useSelector((state) => state.userDetails);
  const [count, setCount] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "year").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [decommissionedModal, setDecommissionModal] = useState(false);
  const [commissionedModal, setCommissionModal] = useState(false);
  const [registeredModal, setRegisteredModal] = useState(false);
  const [data, setData] = useState([]);
  const [batch, setbatch] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const controller = new AbortController();
  const [addMeterModal, setAddMeterModal] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [tabstate, settabstate] = useState(1);
  const [meterstats, setmeterstats] = useState({
    registered: 0,
    assigned: 0,
    unassigned: 0,
    deployed: 0,
  });
  // const [loading, setloading] = useState();
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meter/getallmeters", {
          limit: 30,
          offset: 0,
          startdate: startDate,
          enddate: endDate,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setData(result.message.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh]);

  // get all meter by batch
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meter/getallmeterbatches", {});
        const result = generalController(res);

        // console.log(result.message.body);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }

        setbatch(result.message.body);
      } catch (error) {
        errorBlock(error);
      }
    })();
  }, []);
  // meter stats
  useEffect(() => {
    Promise.all([
      axios.post("/meter/countalldecommisionedmeters", {
        startdate: startDate,
        enddate: endDate,
      }),
      axios.post("/meter/countallmetersinservice", {
        startdate: startDate,
        enddate: endDate,
      }),
      axios.post("/meter/countallregisteredmeters", {
        startdate: startDate,
        enddate: endDate,
      }),
      axios.post("/meter/countallmetersnotassignedtocustomers", {}),
      axios.post("/meter/countallmetersassignedtocustomers", {}),
    ])
      .then((res) => {
        let response = res.map((item) => meterController(item));
        // decommissionedmeter
        setmeterstats((prev) => {
          return {
            registered: response[4].message?.count,
            assigned: response[2].message?.count,
            unassigned: response[3].message?.count,
            deployed: response[1].message?.count,
          };
        });
        // meters in service
        // registered meters in service
        //  meters not assigned in service
        //  meters assigned in service
      })
      .catch((err) => {
        errorBlock(err);
      });

    return () => {
      controller.abort();
    };
  }, [refresh]);

  return (
    <Layout>
      {/* {decommissionedModal && (
        <MeterCountModal
          closeModal={setDecommissionModal}
          title={"Decommissioned Meter Count"}
          caption={"decommissioned"}
          type={"dm"}
        />
      )}
      {commissionedModal && (
        <MeterCountModal
          closeModal={setCommissionModal}
          title={"Commissioned Meter Count"}
          caption={"commissioned"}
          type={"cm"}
        />
      )}
      {registeredModal && (
        <MeterCountModal
          closeModal={setRegisteredModal}
          title={"Registered Meter Count"}
          caption={"registered"}
          type={"rm"}
        />
      )} */}
      {addMeterModal && <AssignMeter closeModal={setAddMeterModal} />}
      <div
        className=" mt-2 bg-white h-auto rounded shadow-md"
        style={{
          marginBottom: 200,
        }}
      >
        <div className="w-full ">
          <div className="p-1 flex items-center justify-between mb-3">
            <div className="flex items-center ">
              <div className=" w-[15px] h-[25px] bg-[#45e4df7b] rounded-sm"></div>
              <div className="font-semibold ml-2">
                All meters available in organisation{" "}
                <span className="">({user.domain})</span>
              </div>
            </div>
            <div className="">
              <DateRangePicker
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                refreshBtn={setRefresh}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 p-2 md:grid-cols-4 lg:grid-cols-4 my-1 gap-4">
            <div
              className={`h-[150px] p-2 border-[1px] rounded border-gray-200 shadow-sm`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                <MdElectricMeter
                  size={35}
                  color=" text-sky-500"
                  className=" text-green-600"
                />
              </div>
              <div className="pt-2 font-bold">Registered Meters</div>
              <div className="pt-2 font-bold text-3xl">
                {meterstats.registered ?? 0}
              </div>
            </div>
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                <MdElectricMeter size={35} color="" className=" text-sky-500" />
              </div>
              <div className="pt-2 font-bold">Deployed Meters</div>
              <div className="pt-2 font-bold text-3xl">
                {meterstats.deployed ?? 0}
              </div>
            </div>
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                <MdElectricMeter
                  size={35}
                  color=""
                  className=" text-gray-600"
                />
              </div>
              <div className="pt-2 font-bold">Assigned Meters</div>
              <div className="pt-1 font-bold text-3xl">
                {meterstats.assigned ?? 0}
              </div>
            </div>
            <div
              className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100 flex items-center justify-center">
                <MdElectricMeter
                  size={35}
                  color=""
                  className=" text-orange-500"
                />
              </div>
              <div className="pt-1 font-bold">Unassigned Meters</div>
              <div className="pt-2 font-bold text-3xl">
                {meterstats.unassigned ?? 0}
              </div>
            </div>
          </div>

          <div className="row mt-1 rounded-md">
            {/* <TableTop
              title={"All meters in your domain"}
              showAddMeter={true}
              setAddMeterModal={setAddMeterModal}
            /> */}

            <div className=" px-3 mb-4">
              <div className="flex p-1 w-[fit-content] bg-gray-200 rounded-md items-center">
                <div
                  onClick={() => settabstate(1)}
                  className={
                    tabstate == 1
                      ? `bg-white rounded-md text-[14px] px-2 py-1 text-center cursor-pointer`
                      : `text-center cursor-pointer px-2 py-1 text-[14px]`
                  }
                >
                  View meters by Batch Id
                </div>
                <div
                  onClick={() => settabstate(2)}
                  className={
                    tabstate == 2
                      ? `bg-white rounded-md px-2 py-1 text-[14px] text-center cursor-pointer`
                      : `text-center cursor-pointer text-[14px] px-2 py-1 `
                  }
                >
                  View all meters
                </div>
              </div>
            </div>

            <div className="h-auto overflow-y-hidden overflow-x-auto">
              {tabstate == 1 ? (
                <AllBatchMeterTable data={batch} />
              ) : (
                <AllMeterTable data={data} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[300px] w-full"></div>
    </Layout>
  );
};

export default Meters;
