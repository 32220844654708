import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import Backdrop from "../../../Backdrop";
import { DatePicker } from "antd";
import moment from "moment";

const FilterRevenue = ({
  open,
  setopen,
  setEndDate,
  setStartDate,
  refreshBtn,
}) => {
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[450px] rounded bg-white drop-shadow-md p-3 ">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-semibold text-[17px]">Select date range</div>
            </div>
            <CloseButton closeBtn={setopen} />
          </div>
          <Rule />
          <div>
            <DatePicker.RangePicker
              showTime={{ format: "HH:mm:ss" }}
              format="YYYY-MM-DD HH:mm:ss"
              style={{
                width: "100%",
              }}
              placement="bottomLeft"
              size="large"
              onChange={(e) => {
                setStartDate(moment(e[0]._d).format("YYYY-MM-DD HH:mm:ss"));
                setEndDate(moment(e[1]._d).format("YYYY-MM-DD HH:mm:ss"));
                refreshBtn((prev) => !prev);
                setopen(false);
              }}
            />
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default FilterRevenue;
