const PageTitle = ({ title, caption }) => {
  return (
    <div className="flex flex-col items-start">
      <div className="text-primary font-semibold text-[16px]">{title}</div>
      <div className="">{caption}</div>
    </div>
  );
};

export default PageTitle;
