import { useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { errorBlock } from "../../../../controllers/errorBlock";
import axios from "../../../../utils/axios";
import AddDCUToSite from "../modals/AddDCUToSite";
import DCUInfoModal from "../modals/DCUInfoModal";
import { meterController } from "../../../../controllers/MeterController";
import { CustomButton } from "../../../CustomButton";
const AllMetersOnDCU = ({ data, loading, refreshbtn, tabstate }) => {
  const [details, setdetails] = useState(null);
  const [showinfo, setshowinfo] = useState(false);
  const [selected, setselected] = useState(null);
  const [open, setopen] = useState(false);
  const [loadingmeter, setloadingmeter] = useState(false);
  const { siteid } = useSelector((state) => state.projectSite);
  const handleRemoveMeter = async (data) => {
    try {
      setloadingmeter(true);
      let res = await axios.post("/sitemanagement/dcu/removemeterfromdcu", {
        siteid: siteid,
        meterid: data?.meterid,
      });

      let result = meterController(res);

      if (result.type !== "success") {
        setloadingmeter(false);
        toast[result.type](result.message);
        return;
      }
      toast.success("DCU added to site succefully.");
      setopen(false);
    } catch (err) {
      errorBlock(err);
      setloadingmeter(false);
    }
  };

  const columns = [
    {
      name: "Batch ID",
      center: true,
      selector: (row) => row.batchid,
      grow: 3,
    },

    {
      name: "DCU Id",
      center: true,
      selector: (row) => row.dcuid,
      grow: 3,
    },
    {
      name: "Model",
      center: true,
      selector: (row) => row.model,
      grow: 1,
    },
    {
      name: "Capacity",
      center: true,
      selector: (row) => row.devicecapacity,
      grow: 1,
    },
    {
      name: "Device serial",
      center: true,
      selector: (row) => row.serialid,
      grow: 3,
    },
    {
      name: "Action",
      selector: (row) => row.id, // Assuming you have an id field for each row
      grow: 3,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <CustomButton
              loading={
                selected?.dcu == item?.dcu && loadingmeter ? true : false
              }
              className={
                "border-[1px] cursor-pointer ml-2 bg-red-400 rounded border-red-400 text-white text-[12px] font-light px-4 py-2"
              }
              onClick={() => {
                setselected(item);
                handleRemoveMeter(item);
              }}
            >
              <p className="m-0">Remove meter</p>
            </CustomButton>
            <div
              onClick={() => {
                setdetails(item);
                setshowinfo(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              View more
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {open && (
        <AddDCUToSite detials={selected} open={open} setopen={setopen} />
      )}

      {details && (
        <DCUInfoModal open={showinfo} setopen={setshowinfo} dcuinfo={details} />
      )}

      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        paginationPerPage={10}
        noDataComponent={<div className="">No meter has been added to DCU</div>}
      />
    </>
  );
};

export default AllMetersOnDCU;
