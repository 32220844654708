import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "../../../utils/axios";
import { toast } from "react-hot-toast";
import { channelController } from "../../../controllers/channelController";
import { Input, Select } from "antd";
import AddRoles from "../../../components/Roles/AddRole";
import { errorBlock } from "../../../controllers/errorBlock";
import { Button } from "../../../components/Button";
import AllRolesTable from "../../../components/Roles/Tables/Tables/AllRolesTable";
import { themeColor } from "../../../constant/color";
import { CustomButton } from "../../../components/CustomButton";
const ZoneRoleList = () => {
  const { Option } = Select;
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [inputname, setinputname] = useState("");
  const [query, setquery] = useState(searchParams.get("role"));

  const [selectedItem, setSelectedItem] = useState(query ?? "All");
  const location = useLocation();

  let dropDown = [
    "All",
    "Project Manager",
    "Customer Representative",
    "Customer Manager",
    "Sales Agent",
    "System Manager",
  ];

  useEffect(() => {
    (async () => {
      try {
        let res;

        if (selectedItem !== "All") {
          res = await axios.post("/roles/getallusersbyrole", {
            role: selectedItem,
          });
        }

        if (selectedItem == "All") {
          res = await axios.post("/roles/getallroles", {});
        }

        let result = channelController(res);

        if (result.type !== "success") {
          toast.error(result.message);
          return;
        }

        if (selectedItem == "All") {
          setData(result.message.roles);
        } else {
          setData(result.message.body);
        }

        setloading(false);
      } catch (err) {
        errorBlock(err);
        setloading(false);
      }
    })();
  }, [selectedItem, refresh]);

  const filteredData = data.filter(
    (i) =>
      i.creator.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i.role.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i.sitename.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i.username.toLowerCase().trim().includes(inputname.toLowerCase()) ||
      i.country.toLowerCase().trim().includes(inputname.toLowerCase())
  );
  return (
    <Layout>
      {modal && <AddRoles closeModal={setModal} refreshBtn={setRefresh} />}
      <div className="flex items-center justify-between bg-white rounded-md shadow-md p-2">
        <div className="flex items-center">
          <div className="w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
          <div className="font-semibold ml-2">
            {selectedItem
              ? selectedItem == "All"
                ? "List of user and roles in your organisation"
                : `List of ${selectedItem}s role in your organisation`
              : query
              ? `List of ${query}s role in your organisation`
              : ""}
          </div>
        </div>
        <div className="flex items-center justify-end">
          <div className="mr-4">
            <Input
              size="large"
              onChange={(e) => setinputname(e.target.value)}
              className="placeholder:font-light "
              placeholder="Search..."
            />
          </div>

          <div className="mr-4 ">
            <Select
              style={{
                width: 200,
              }}
              showSearch
              showArrow
              placeholder="Select role"
              bordered
              size="large"
              value={selectedItem}
              onChange={(e) => {
                setSelectedItem(e);
                setRefresh((prev) => !prev);
                setquery("");
              }}
            >
              {dropDown.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex items-center w-[170px] h-[47px] justify-center">
            <CustomButton
              className={
                "flex w-full  items-center m-0 justify-center bg-black text-white  p-2 rounded "
              }
              onClick={() => setModal(true)}
            >
              <p className="m-0">Assign Role</p>
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="bg-white mt-2 rounded-md shadow-md p-2 h-[90vh]">
        <AllRolesTable data={filteredData} loading={loading} />
      </div>
    </Layout>
  );
};

export default ZoneRoleList;
