import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Logo from "./location.svg";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { FaMapLocationDot } from "react-icons/fa6";

const CustomMarker = ({ position, children }) => {
  const map = useMap();

  const customIcon = L.icon({
    iconUrl: Logo,
    iconSize: [50, 50],
    iconAnchor: [25, 50],
  });

  return (
    <Marker position={position} icon={customIcon}>
      {children}
    </Marker>
  );
};

export default CustomMarker;
