import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { setProjectSiteName } from "../../../../redux/slice/projectsiteSlice";
import toast from "react-hot-toast";
import EmptyState from "../../../EmptyState";
import { Select } from "antd";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { countryList } from "../../../../helperFuncs/countryList";
import { CustomButton } from "../../../CustomButton";
import ViewPaymentPlan from "../../../ProjectManager/PaymentPlan/modals/ViewPaymentPlan";
import { errorBlock } from "../../../../controllers/errorBlock";
import axios from "../../../../utils/axios";
import { channelController } from "../../../../controllers/channelController";
import { themeColor } from "../../../../constant/color";
import { tableCustomStyles } from "../../../../helperFuncs";

const PlansOnMeter = ({ data, setdetails, setmodal, customer }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [selectplan, setselectplan] = useState({});
  const [plandetails, setplandetails] = useState({});
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const { country } = useSelector((state) => state.projectSite);
  const { username } = useSelector((state) => state.userDetails);

  const buyPlan = async (data) => {
    try {
      setloading(true);
      let res = await axios.post("/vending/payment/acquiretransactionid", {
        planalias: data?.planalias,
        channelid: customer?.meter,
        paymentgateway: "sales agent",
        amount: data.amount,
        creator: username,
        currency: countryList[country]["Currency Code"],
      });

      const result = channelController(res);

      if (result.type !== "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }

      if (!("transactionid" in result.message)) {
        toast.error(result.message?.response);
        setloading(false);
        return;
      }

      let tranxres = await axios.post("/vending/payment/committransaction", {
        transactionid: result.message?.transactionid,
      });

      const tranxresult = channelController(tranxres);

      if (tranxresult.type !== "success") {
        toast.error(tranxresult.message);
        setloading(false);
        return;
      }

      if (tranxresult.message.status == "Request Sent") {
        toast.success(tranxresult.message.status);
      } else {
        toast.error(tranxresult.message.status);
      }

      setloading(false);
    } catch (error) {
      errorBlock(error);
      setloading(false);
    }
  };

  const columns = [
    {
      name: "Plan Name",
      selector: (row) => row.planalias,
      center: true,
      grow: 3,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      center: true,
      grow: 3,
    },

    {
      name: `Amount (${countryList[country]["Currency Code"]})`,
      selector: (row) => row.amount,
      center: true,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="">{` ${item.amount}` ?? "-Nil-"}</div>
          </div>
        );
      },
    },
    {
      name: "Date Created",
      selector: (row) => row.datecreated,
      center: true,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="">{moment(item.datecreated).format("lll")}</div>
          </div>
        );
      },
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => row.meter_class,
      center: true,
      grow: 3,

      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <CustomButton
              color={themeColor.secondary}
              loading={selectplan == item.planalias ? loading : false}
              disabled={loading}
              onClick={() => {
                setselectplan(item.planalias);
                buyPlan(item);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-secondary text-[12px] text-secondary font-light px-4 py-2"
            >
              {selectplan == item.planalias && loading ? "" : "Buy plan"}
            </CustomButton>
            <div
              onClick={() => {
                setplandetails(item);
                setopen(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              <FaEye size={15} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="">
      {open && (
        <ViewPaymentPlan open={open} setopen={setopen} details={plandetails} />
      )}
      <DataTable
        columns={columns}
        data={data}
        pagination
        customStyles={tableCustomStyles}
        paginationPerPage={10}
      />
    </div>
  );
};

export default PlansOnMeter;
