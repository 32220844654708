import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState, useEffect, useMemo } from "react";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { toast } from "react-hot-toast";
import axios from "../../../../utils/axios";
import { Select } from "antd";
import { userController } from "../../../../controllers/UserController";
import { meterController } from "../../../../controllers/MeterController";
import { useSelector } from "react-redux";
import { CustomButton } from "../../../CustomButton";
import { useEditCustomerMeterMutation } from "../../../../services/customer.services";
import { errorBlock } from "../../../../controllers/errorBlock";

const EditCustomerMeter = ({ open, details, setopen, refresh }) => {
  const [eye, seteye] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/settings/getdropdown", {
          comboname: "customertype",
        });

        setCustomerType(res.data.list.split(",").map((i) => i.trim()));

        refresh((prev) => !prev);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const [selectedcst, setselectedcst] = useState("");
  const [CustomerType, setCustomerType] = useState([]);
  const [userloading, setuserloading] = useState(false);
  const siteDetails = useSelector((state) => state.projectSite);
  const [name, setname] = useState("");

  const [editCustomerMeter, { isLoading, error, data }] =
    useEditCustomerMeterMutation();

  const onSubmit = async (data) => {
    try {
      editCustomerMeter({
        customerid: details?.customerid,
        meter: details?.meter,
        customertype: selectedcst == "" ? details?.customertype : selectedcst,
        section: data?.section,
        gps: !(data?.lat && data?.lng) ? null : data?.lat + "," + data?.lng,
        polenumber: data?.polenumber,
        projectzone: Number(siteDetails?.siteid),
        address: data?.address,
      })
        // meter,
        //   customertype,
        //   section,
        //   gps,
        //   polenumber,
        //   projectzone,
        //   address
        .then((res) => {
          if (res.error) {
            errorBlock(res.error);
            return;
          }

          if (res.data?.response != "success") {
            toast.error(res.data?.response);
            return;
          }
          toast.success("Updated successfully");
          setopen(false);
        })
        .catch((err) => {
          errorBlock(err);
        });
    } catch (err) {
      errorBlock(err);
      // toast.error(err);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[450px] bg-white drop-shadow-md rounded-sm p-4">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-semibold text-[17px]">
                Update customer info
              </div>
              <div className="text-gray-700 ">
                Fill the fields to update{" "}
                <span className="font-semibold">
                  {details?.customerid[0].toUpperCase() +
                    details?.customerid.substring(1)}
                  's{" "}
                </span>
                information{" "}
              </div>
            </div>
            <div className="flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* email */}
              <div className="mt-2 gap-4">
                <input
                  defaultValue={details?.polenumber}
                  placeholder="enter pole number..."
                  className="border-[1px] bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                  {...register("polenumber", {
                    required: false,
                  })}
                />
                {errors.polenumber && (
                  <span className="text-red-400 text-[12px]">
                    field is required
                  </span>
                )}
              </div>
              {/* firstname */}
              <div className="mt-1">
                <input
                  placeholder="enter section..."
                  {...register("section", {
                    required: false,
                  })}
                  className="border-[1px] bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                />
                {errors.section && (
                  <span className="text-red-400 text-[12px]">
                    field is required
                  </span>
                )}
              </div>
              {/* lat */}
              <div className="mt-1">
                <input
                  placeholder="enter latitude..."
                  defaultValue={details?.gps ? details?.gps.split(",")[0] : ""}
                  className="border-[1px] bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                  {...register("lat", {
                    required: false,
                  })}
                />
                {errors.lat && (
                  <span className="text-red-400 text-[12px]">
                    field is required
                  </span>
                )}
              </div>{" "}
              {/* lng */}
              <div className="mt-1">
                <input
                  placeholder="enter longitude ..."
                  defaultValue={details?.gps ? details?.gps.split(",")[1] : ""}
                  className="border-[1px] bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[40px]"
                  {...register("lng", { required: false })}
                />
                {errors.lng && (
                  <span className="text-red-400 text-[12px]">
                    field is required
                  </span>
                )}
              </div>{" "}
              {/* lng */}
              <div className="mt-1">
                <textarea
                  placeholder="enter address  ..."
                  defaultValue={details?.address}
                  className="border-[1px] bg-gray-100 text-[15px] placeholder:text-[15px] mb-2 placeholder:font-normal outline-none border-gray-300 rounded p-2 w-full h-[80px]"
                  {...register("address", { required: false })}
                />
                {errors.address && (
                  <span className="text-red-400 text-[12px]">
                    field is required
                  </span>
                )}
              </div>{" "}
              {/* customer type */}
              <div className="mt-1">
                <Select
                  placeholder={"Select a customer type"}
                  size="large"
                  defaultValue={details?.customertype}
                  value={details?.customertype}
                  style={{
                    width: "100%",
                    height: 43,
                    fontSize: 14,
                    border: "1px solid rgb(209 213 219)",
                    borderRadius: 5,
                  }}
                  onChange={(e) => setselectedcst(e)}
                  bordered={false}
                  options={CustomerType.map((item) => {
                    return { value: item, label: item };
                  })}
                />
              </div>
              <div className="mt-">
                <Rule />
              </div>
              <CustomButton
                loading={isLoading}
                className={
                  "border-[1px] p-2 w-full bg-black text-white rounded flex items-center justify-center"
                }
              >
                <p className="m-0">Update</p>
              </CustomButton>
            </form>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default EditCustomerMeter;
