import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import DataTable from "react-data-table-component";
import { MdOutlineDeleteForever } from "react-icons/md";
import axios from "../../../../utils/axios";
import { TbDotsVertical } from "react-icons/tb";
import toast from "react-hot-toast";
import { tableCustomStyles } from "../../../../helperFuncs";
import { errorBlock } from "../../../../controllers/errorBlock";
import { Dropdown, message, Space } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import Rule from "../../../Rule/Index";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const SiteCustomerClassTable = ({ data, refresh }) => {
  const [selectedsite, setselectedsite] = useState(Object.keys(data)[0] ?? "");
  const [loading, setloading] = useState(false);

  const handleDelete = async (item) => {
    try {
      setloading(true);
      let res = await axios.post("/meterclass/deletesavedmeterclasses", {
        meterclasscode: item,
      });
      toast.success("Meterclass deleted successfully.");
      setloading(false);
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: <div className="text-[12px]">Add to site</div>,
    },
    {
      key: "2",
      label: <div className="text-[12px]">View class details</div>,
    },
  ];

  const columns = [
    {
      name: "Customer class",
      center: true,
      selector: (row) => row.customerClass,
      grow: 1,
    },
    {
      name: "Time of use",
      center: true,
      selector: (row) => row.timeOfUse,
      grow: 2,
    },
    {
      name: "Class type",
      center: true,
      selector: (row) => row.customerType,
      grow: 2,
    },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.dateCreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.dateCreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.id,
      grow: 2,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomCenter"
              arrow
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <TbDotsVertical color="black" />
                </Space>
              </a>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="">
        {Object.keys(data).map((i) => (
          <>
            <Rule />
            <div className="flex items-center justify-between">
              <div className="text-[14px]">
                Sitename:{" "}
                <span className="font-semibold text-secondary">{i}</span>
              </div>
              <div
                className=" cursor-pointer"
                onClick={() => setselectedsite(i)}
              >
                {selectedsite == i ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </div>
            </div>
            {selectedsite == i && (
              <div className="mt-2">
                <DataTable
                  style={{
                    height: "100%",
                  }}
                  columns={columns}
                  data={data[i]}
                  pagination
                  paginationPerPage={10}
                  customStyles={tableCustomStyles}
                />
              </div>
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default SiteCustomerClassTable;
