import { IoNotificationsOutline } from "react-icons/io5";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { deleteToken, getToken, setToken } from "../../utils/token";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import {
  useLocation,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button } from "../Button";
import AddUser from "../Modals/AddUser";
import AddCustomer from "../Modals/AddCustomer";
import { HiOutlineUserPlus } from "react-icons/hi2";
import axios from "../../utils/axios";
import { errorBlock } from "../../controllers/errorBlock";
import SideNav from "../SideNav";
import Backdrop from "../Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { postLogout } from "../../redux/actions/auth.action";
import { BsPersonPlus } from "react-icons/bs";
import TopNavTitle from "./TopNavTitle";
import { pageTitle } from "./data";
import { Select } from "antd";
import { BiArrowBack } from "react-icons/bi";
import { channelController } from "../../controllers/channelController";
import { updateRole } from "../../redux/slice/userDetailSlice";
import AddAppUser from "../Modals/AddAppUser";
import {
  setProjectSiteId,
  setProjectSiteName,
} from "../../redux/slice/projectsiteSlice";
import { mqttEvents } from "../../context/MqttContext";
import MobileSideNav from "../MobileSideNav";
import { setNavtitle } from "../../redux/slice/layoutSlice";

const TopNav = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [showdd, setshowdd] = useState(false);
  const navigate = useNavigate();
  const [usermodal, setusermodal] = useState(false);
  const [appusermodal, setappusermodal] = useState(false);
  const [customermodal, setcustomermodal] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [noti, setnoti] = useState("");
  const [opendrawal, setopendrawal] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setloading] = useState(true);
  const [tabdefault, setdefaulttab] = useState(false);

  const [data, setData] = useState([]);

  const { role, username } = useSelector((state) => state.userDetails);
  const { name } = useSelector((state) => state.domain);
  const { sitename } = useSelector((state) => state.projectSite);

  useEffect(() => {
    mqttEvents.on("open", () => {
      setappusermodal(true);
      setdefaulttab(true);
    });

    return () => {};
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/roles/getuserroles", {
          targetusername: username,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          return;
        }

        setData(result?.message?.body);
        setloading(false);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.response);
        }
        toast.error(err.message);
        setloading(false);
      }
    })();
  }, []);

  // request
  const [request, setrequest] = useState(0);

  const title = () => {
    if (searchParams.get("name")) {
      return {
        title: searchParams.get("name"),
      };
    }

    switch (window.location.pathname) {
      case "/dashboard":
        return {
          title: "Dashboard",
        };
      case "/allmeters":
        return {
          title: "Meters",
        };
      case "/allchannels":
        return { title: "Meters" };
      case "/meterclass":
        return { title: "Meterclass" };
      case "/wallet":
        return { title: "Wallets" };
      case "/settings":
        return { title: "Profile" };
      default:
        return {
          title: "",
        };
    }
  };

  const hanldeSwitch = async (item) => {
    try {
      let role = item.split("-")[0];
      let name = item.split("-")[1];
      let id = item.split("-")[2];

      dispatch(setProjectSiteId(id));

      dispatch(setProjectSiteName(name));

      switch (role) {
        case "System Manager":
          dispatch(updateRole("System Manager"));
          dispatch(setNavtitle("System Manager Dashboard"));
          navigate(`/dashboard/systemadmin`);
          return;
        case "Project Manager":
          dispatch(updateRole("Project Manager"));
          dispatch(setNavtitle("Project Manager Dashboard"));
          navigate(`/dashboard/projectmanager`);
          return;
        case "Customer Representative":
          dispatch(updateRole("Customer Representative"));
          dispatch(setNavtitle("Customer Rep Dashboard"));
          navigate(`/dashboard/customerrepresentative`);
          return;
        case "Customer Manager":
          dispatch(updateRole("Customer Manager"));
          dispatch(setNavtitle("Customer Manager Dasbboard"));
          navigate(`/dashboard/customermanager`);
          return;
        case "Sales Agent":
          dispatch(updateRole("Sales Agent"));
          dispatch(setNavtitle("Sales Agent Dasbboard"));
          navigate(`/dashboard/salesagent`);
          return;
        case "Customer":
          dispatch(updateRole("Customer"));
          await setToken("spiral_role", "Customer");
          return;
        default:
          break;
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      {opendrawal && (
        <Backdrop open={opendrawal}>
          <MobileSideNav
            opendrawal={opendrawal}
            setopendrawal={setopendrawal}
          />
        </Backdrop>
      )}

      {usermodal && <AddUser open={usermodal} setopen={setusermodal} />}

      {customermodal && (
        <AddCustomer open={customermodal} setopen={setcustomermodal} />
      )}
      {appusermodal && (
        <AddAppUser
          tabdefault={tabdefault}
          open={appusermodal}
          setopen={setappusermodal}
        />
      )}
      <div className="flex px-4 items-center justify-between h-[65px] drop-shadow-md bg-white sticky top-0 left-0 ">
        <div className="">
          <div className="font-semibold font-kanit text-[17px]">
            {/* {title().title} */}
            {pageTitle(window.location.pathname)}
          </div>
          <div className="font-light text-gray-600 hidden">Meter Overview</div>
        </div>
        <div className="flex items-start">
          {/* Add customer */}
          {/* {role == "Project Manager" && ( */}
          {/* <div className="w-[110px] mx-4">
            <Button
              text={"Onboard Customer"}
              height={"35px"}
           
            />
          </div> */}
          {/* )} */}
          {/* add user */}
          {/* {role == "Customer Manager" && (
            <div className="w-[110px] mx-4">
              <Button
                text={"Add User"}
                height={"35px"}
                onClick={() => setusermodal(true)}
              />
            </div>
          )} */}

          {/* profile cover */}
          <Link
            to="/request"
            className="items-center hidden justify-center pt-1 relative mx-3 px-2"
          >
            <div className="w-[15px] h-[15px] left-[22px] top-[-3px] bg-red-500 flex items-center justify-center rounded-full absolute">
              <div className="text-white text-[12px] ">{request}</div>
            </div>
            <HiOutlineUserPlus size={20} className="text-gray-600" />
          </Link>

          {/* onbaord customer */}
          {role == "Project Manager" && (
            <div
              onClick={() => setcustomermodal(true)}
              className="items-center px-2 py-1 mr-2 rounded-md cursor-pointer border-[1px] border-secondary flex justify-center pt-1"
            >
              <BsPersonPlus className="text-secondary " size={18} />
              <div className="text-secondary ml-2">Onboard customer</div>
            </div>
          )}

          {/* Add user */}
          {role == "System Manager" && (
            <div
              onClick={() => setusermodal(true)}
              className="items-center px-2 py-1 rounded cursor-pointer border-[1px] border-secondary flex justify-center pt-1"
            >
              <BsPersonPlus className="text-secondary " size={18} />
              <div className="text-secondary ml-2">Add User</div>
            </div>
          )}

          {/* Add user in site for customer manager */}
          {role == "Customer Manager" && (
            <div
              onClick={() => setappusermodal(true)}
              className="items-center px-2 py-1 rounded cursor-pointer border-[1px] border-secondary flex justify-center pt-1"
            >
              <BsPersonPlus className="text-secondary " size={18} />
              <div className="text-secondary ml-2">Add Customer</div>
            </div>
          )}

          {/* Role drop down */}
          <Select
            showSearch
            options={
              data.length == 0
                ? []
                : data.map((i) => {
                    return {
                      value: `${i.role}-${i.sitename}-${i.siteid}`,
                      label: `${i.role} (${i.sitename ?? name})`,
                    };
                  })
            }
            suffixIcon={<IoMdArrowDropdown size={20} color="black" />}
            placeholder={"Switch View"}
            style={{
              width: 230,
              height: 32,
              borderRadius: "0.25rem !important",
              marginLeft: 10,
            }}
            onChange={(e) => hanldeSwitch(e)}
            defaultValue={`${role} (${
              role == "System Manager" ? name : sitename
            })`}
          />
          <div
            onClick={() => setshowdd(!showdd)}
            className="hidden cursor-pointer relative ml-3 items-center justify-between rounded px-2 py-1 w-[100px] border-[1px] border-gray-300"
          >
            <div className="">Profile</div>
            <div className="flex items-center justify-center">
              <IoMdArrowDropdown size={18} />
            </div>
          </div>

          {/* Account drop down */}
          <Select
            options={[
              {
                value: "profile",
                label: "Profile",
              },
              {
                value: "logout",
                label: "Logout",
              },
            ]}
            onChange={(e) => {
              if (e == "profile") {
                navigate("/settings/profile");
              } else if (e == "logout") {
                dispatch(postLogout());
              }
            }}
            suffixIcon={<IoMdArrowDropdown size={20} color="black" />}
            placeholder={"Account"}
            defaultValue={"Account"}
            style={{
              width: 150,
              height: 32,
              borderRadius: "0.25rem !important",
              // border: "1px solid red",
              marginLeft: 10,
            }}
          />

          {showdd && (
            <div className="absolute hidden top-[60px] right-[10px] animate__fadeInUp animate__animated p-2 w-[150px] bg-white rounded-sm drop-shadow-sm border-[0.5px]">
              <Link
                to="/settings"
                className="text-gray-700 py-2 px-1 my-2 hover:text-secondary hover:underline"
              >
                View Profile
              </Link>
              <div
                onClick={async () => {
                  try {
                    dispatch(postLogout());
                  } catch (err) {
                    toast.error(err.message);
                  }
                }}
                className="text-gray-700 py-2 px-1 my-2 hover:text-secondary hover:underline"
              >
                Logout
              </div>
            </div>
          )}

          {/* {pathname !== "/userroles" && opendrawal && ( */}

          <div
            className="flex md:flex lg:hidden items-center justify-center pt-1 px-2 ml-2 cursor-pointer"
            onClick={() => setopendrawal(true)}
          >
            <HiOutlineMenuAlt3 size={22} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNav;
