import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { getToken } from "../../../utils/token";

const TopNavTitle = () => {
  const { pathname } = window.location;
  switch (pathname) {
    case "/dashboard":
      return {
        title: "Dashboard",
        caption: "",
      };
    case "/allchannels":
      return { title: "Meters", caption: "" };
    case "/paymentplan":
      return { title: "Site payment plan", caption: "" };
    case "/meterclass":
      return { title: "Customer class", caption: "" };
    case "/wallet":
      return { title: "Wallet", caption: "" };
    case "/settings":
      return { title: "Profile", caption: "" };
    default:
      return {
        title: "",
        caption: "",
      };
  }
};

export default TopNavTitle;
