import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const meterAPI = createApi({
  reducerPath: "meterPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      //   const token = credentials.token;
      //   if (token) {
      //     headers.set("Version", `0.1`);
      //     return headers;
      //   }
    },
  }),
  endpoints: (build) => ({
    getMeterDetails: build.mutation({
      query: (data) => ({
        url: "meter/getmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data,
        },
      }),
    }),
    getMetersInSite: build.mutation({
      query: (data) => ({
        url: "/meter/getallmetersinzone",
        method: "POST",
        body: {
          ...credentials,
          zone: Number(data),
        },
      }),
    }),
    // assign meter
    assignMeter: build.mutation({
      query: (data) => ({
        url: "meter/assignmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          customerid: data?.customerid,
          address: data?.address,
          zone: Number(data?.siteid),
          customerclass: data?.customerclass,
        },
      }),
    }),
    unassignMeter: build.mutation({
      query: (data) => ({
        url: "meter/unassignmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          customerid: data?.customerid,
        },
      }),
    }),
    // assign Rf meter
    assignRFMeter: build.mutation({
      query: (data) => ({
        url: "meter/assignrfmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          dcuid: data?.dcuid,
          customerid: data?.customerid,
          address: data?.address,
          zone: Number(data?.siteid),
          customerclass: data?.customerclass,
        },
      }),
    }),
    // assign Rf meter
    getAllCustomerMetersInSite: build.mutation({
      query: (data) => ({
        url: "customer/getallmetercustomeraccountsbycustomermanagerandzone",
        method: "POST",
        body: {
          ...credentials,
          zone: Number(data?.siteid),
          startdatecreated: data?.startdatecreated,
          enddatecreated: data?.enddatecreated,
          customermanager: data?.customermanager,
        },
      }),
    }),
    assignRFMeter: build.mutation({
      query: (data) => ({
        url: "meter/assignrfmeter",
        method: "POST",
        body: {
          ...credentials,
          meternumber: data?.meternumber,
          dcuid: data?.dcuid,
          customerid: data?.customerid,
          address: data?.address,
          zone: Number(data?.siteid),
          customerclass: data?.customerclass,
        },
      }),
    }),
  }),
});

export const {
  useGetMeterDetailsMutation,
  useAssignMeterMutation,
  useAssignRFMeterMutation,
  useGetMetersInSiteMutation,
  useUnassignMeterMutation,
  useGetAllCustomerMetersInSiteMutation,
} = meterAPI;
