import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const rolesAPI = createApi({
  reducerPath: "rolesPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {},
  }),
  endpoints: (build) => ({
    // unassign roles
    getUserRoles: build.mutation({
      query: (data) => ({
        url: "roles/getuserroles",
        method: "POST",
        body: {
          ...credentials,
          targetusername: data?.username,
        },
      }),
    }),
    //
    getAUserRole: build.mutation({
      query: (data) => ({
        url: "roles/getallrolescreatedbyuserbyrole",
        method: "POST",
        body: {
          ...credentials,
          targetusername: data?.username,
        },
      }),
    }),
  }),
});

export const { useGetUserRolesMutation } = rolesAPI;
