import moment from "moment";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaUser, FaUsersCog } from "react-icons/fa";
import { themeColor as color } from "../../../../../constant/color";
import { setToken } from "../../../../../utils/token";
import { Button } from "../../../../Button";
import RoleInfoModal from "../../../RoleInfoModal";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";
import { tableCustomStyles } from "../../../../../helperFuncs";

const AllRolesTable = ({ data, loading }) => {
  const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [roleId, setRoleId] = useState("");
  const [username, setUsername] = useState("");
  let grid = useRef();
  let num = 1;
  let final = data.map((i) => {
    return {
      num: num++,
      ...i,
    };
  });

  const columns = [
    {
      name: "Creator",
      selector: (row) => row.creator,
      center: true,
      sortable: true,
      grow: 2,
      // cell: (item) => {
      //   return (
      //     <div className="flex items-center">
      //       <div>{item.firstname + item.lastname}</div>
      //     </div>
      //   );
      // },
    },
    {
      name: "Role",
      selector: (row) => row.role,
      center: true,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex text-center items-center font-semibold">
            {item.role ?? "-Nil-"}
          </div>
        );
      },
    },
    {
      name: "Project zone",
      selector: (row) => row.sitename,
      center: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">{item.sitename ?? "-Nil-"}</div>
        );
      },
    },
    {
      name: "Country",
      selector: (row) => row.country,
      center: true,
      grow: 2,
      cell: (item) => {
        return <div className="flex items-center">{item.country}</div>;
      },
    },
    {
      name: "Username",
      selector: (row) => row.username,
      center: true,
      grow: 1,
    },

    {
      name: "Date created",
      center: true,
      selector: (row) => row.datecreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="text-[12px]">
            {moment(item.datecreated).format("lll")}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.deviceid,
      grow: 2,
      center: true,
      cell: (item) => {
        return (
          <Link
            to={`${
              item?.role == "Sales Agent"
                ? `/dashboard/salesagent/${item?.siteid}/${item?.username}`
                : item?.role == "Project Manager"
                ? `/dashboard/projectsiteinfo/${item?.siteid}/${item?.username}`
                : item?.role == "Customer Manager"
                ? `/dashboard/customermanager/${item?.siteid}/${item?.username}`
                : "/"
            }`}
          >
            <div
              onClick={() => {}}
              className="border-[1px] text-blue-500 border-blue-500 cursor-pointer rounded text-[12px] font-light px-4 py-2"
            >
              Details
            </div>
          </Link>
        );
      },
    },
  ];

  return (
    <div>
      {modal && (
        <RoleInfoModal
          closeModal={setModal}
          roleId={roleId}
          username={username}
        />
      )}
      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default AllRolesTable;
