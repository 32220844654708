import DataTable from "react-data-table-component";
import { countryList } from "../../../../helperFuncs/countryList";
import { tableCustomStyles } from "../../../../helperFuncs";
import { Dropdown, message, Space } from "antd";
import { TbDotsVertical } from "react-icons/tb";
import { useState } from "react";
import SiteSalesInfo from "../Modals/SiteSalesInfo";

const ProjectSiteSalesTable = ({ data }) => {
  const [selected, setselected] = useState("");
  const [modal, setmodal] = useState(false);
  const columns = [
    {
      name: "Meter number",
      center: true,
      sortable: true,
      selector: (row) => row.meter,
      grow: 1,
    },
    {
      name: "Iniated by",
      center: true,
      sortable: true,
      selector: (row) => row.initiatedBy,
      grow: 1,
    },
    {
      //
      name: `Amount`,
      center: true,
      sortable: true,
      selector: (row) => row.amount,
      grow: 1,
      cell: (row) => (
        <div className="">
          {row?.amount.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      name: `Net Revenue`,
      center: true,
      sortable: true,
      selector: (row) => row.netRevenue,
      grow: 1,
      cell: (row) => (
        <div className="">
          {row?.netRevenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      name: "Plan purchase",
      center: true,
      sortable: true,
      selector: (row) => row.totalKWh,
      grow: 1,
      cell: (row) => (
        <div className="text-center">
          {row?.planAlias == "kWh##Untimed"
            ? "Untimed purchase"
            : row?.planAlias}
        </div>
      ),
    },
    {
      name: `Date initiated`,
      center: true,
      sortable: true,
      selector: (row) => row.orderDate,
      grow: 1,
    },
    {
      name: "Action",
      center: true,
      sortable: true,
      selector: (row) => row.orderDate,
      grow: 1,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <div
                        className="text-[12px]"
                        onClick={() => {
                          setselected(item); // Store the selected item
                          setmodal(true); // Open the modal
                        }}
                      >
                        View details
                      </div>
                    ),
                  },
                ],
              }}
              placement="bottomCenter"
              arrow
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <TbDotsVertical color="black" />
                </Space>
              </a>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {modal && (
        <SiteSalesInfo setopen={setmodal} open={modal} salesinfo={selected} />
      )}
      <DataTable
        columns={columns}
        data={data}
        pagination={true}
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </>
  );
};

export default ProjectSiteSalesTable;
