import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const revenueAPI = createApi({
  reducerPath: "revenuePath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      //   const token = credentials.token;
      //   if (token) {
      //     headers.set("Version", `0.1`);
      //     return headers;
      //   }
    },
  }),
  endpoints: (build) => ({
    // get agent revenue
    getAgentRevenue: build.mutation({
      query: (data) => ({
        url: "/salesanalytics/getdailysalesagentanalytics",
        method: "POST",
        body: {
          ...credentials,
          username: data?.username,
          siteid: Number(data?.siteid),
          startdate: data?.startdate,
          enddate: data?.enddate,
          limit: data?.limit,
          offset: data?.offset,
        },
      }),
    }),
    getProjectSiteRevenue: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getdetailedtransactionanalytics",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
          initiatedby: data?.initiatedby ?? "",
          paymentgateway: data?.paymentgateway ?? "",
          siteid: Number(data?.siteid),
          // projectmanagerusername: data?.projectmanagerusername ?? "",
        },
      }),
    }),
    getProjectManagerRevenue: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getprojectmanageranalytics",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
          projectmanagerusername: data?.username,
        },
      }),
    }),
    getSalesAgentCummulativeAnalytics: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getcumulativesalesagentanalytics",
        method: "POST",
        body: {
          ...credentials,
          username: data?.username,
          siteid: data?.siteid,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
    getSysadminSalesAnalytics: build.mutation({
      query: (data) => ({
        url: "salesanalytics/getsystemmanageranalytics",
        method: "POST",
        body: {
          ...credentials,
          startdate: data?.startdate,
          enddate: data?.enddate,
        },
      }),
    }),
  }),
});

export const {
  useGetAgentRevenueMutation,
  useGetSalesAgentCummulativeAnalyticsMutation,
  useGetProjectManagerRevenueMutation,
  useGetSysadminSalesAnalyticsMutation,
  useGetProjectSiteRevenueMutation,
} = revenueAPI;
