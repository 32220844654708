import Backdrop from "../../Backdrop";
import CloseButton from "../../CloseButton";
import Rule from "../../Rule/Index";
import { useForm } from "react-hook-form";
import ErrorText from "../../Errors/ErrorText";
import { useState } from "react";
import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Button } from "../../Button";
import { toast } from "react-hot-toast";
import { channelController } from "../../../controllers/channelController";
import axios from "../../../utils/axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const AddUser = ({ open, setopen }) => {
  const [eye, seteye] = useState(false);
  const navigate = useNavigate();
  const { name } = useSelector((state) => state.domain);
  const [password, setpassword] = useState("");
  const [loading, setloading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      if (!password) return toast.error("Please enter a password.");
      setloading(true);
      let res = await axios.post("/auth/createaccount", {
        username: data.username.trim().toLowerCase(),
        defaultpassword: password.trim().toLowerCase(),
        phone: data.phonenumber.trim(),
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }
      toast.success("User created successfully");
      setloading(false);
      setopen(false);
      navigate("/users");
    } catch (err) {
      toast.error(err.message);
      setloading(false);
    }
  };
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[450px] rounded bg-white drop-shadow-md p-3 ">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-semibold text-[17px]">
                Add a user to your company ({name})
              </div>
              <p>
                Fill the form fields below to add and assign a role to user in
                your company
              </p>
            </div>
            <CloseButton closeBtn={setopen} />
          </div>
          <Rule />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4">
              <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded border-gray-300 mt-2 h-[45px]">
                <input
                  required
                  {...register("username", { required: true })}
                  type={"text"}
                  placeholder={"Enter a username for this user."}
                  className="bg-gray-100 px-2 placeholder:text-sm placeholder:font-normal w-full outline-none border-[0px]"
                />
              </div>
              {errors.username && (
                <ErrorText text={"Please enter a username"} />
              )}
            </div>
            <div className="mt-4">
              <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded mt-2 h-[45px]">
                <input
                  required
                  {...register("phonenumber", { required: true })}
                  type={"number"}
                  placeholder={"Enter the phone number of the user"}
                  className="bg-gray-100 px-2 placeholder:text-sm placeholder:font-normal w-full outline-none border-[0px]"
                />
              </div>
              {errors.exampleRequired && (
                <ErrorText text={"Phone is required."} />
              )}
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-between w-100 border-[1px] rounded bg-gray-100 mt-2 h-[45px]">
                <div className="flex w-full">
                  <input
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    type={eye ? "text" : "password"}
                    placeholder={"Enter your password"}
                    className="px-2 placeholder:text-sm bg-gray-100 placeholder:font-normal outline-none border-[0px] w-full"
                  />
                </div>

                <div
                  className="px-3 cursor-pointer"
                  onClick={() => seteye(!eye)}
                >
                  {eye ? (
                    <AiOutlineEye
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className=" w-100 mt-7">
              <Button text={"Add "} loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddUser;
