import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: "",
  username: "",
  domain: "Ikeja",
  roleCount: 0,
  clientid: "",
};

export const userDetails = createSlice({
  name: "userDetials",
  initialState,
  reducers: {
    updateRole: (state, action) => {
      state.role = action.payload;
    },
    updateUsername: (state, action) => {
      state.username = action.payload;
    },
    updateDomain: (state, action) => {
      state.domain = action.payload;
    },
    updateRoleCount: (state, action) => {
      state.roleCount = action.payload;
    },
    updateClientId: (state, action) => {
      state.clientid = action.payload;
    },
  },
});

export const {
  updateRole,
  updateUsername,
  updateDomain,
  updateRoleCount,
  updateClientId,
} = userDetails.actions;

export const userDetailsReducer = userDetails.reducer;
