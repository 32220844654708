// import CardTitle from "../../../CardTitle";
import "./styles.css";
import { IoClose } from "react-icons/io5";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "../../../../utils/axios";
import toast from "react-hot-toast";
import Backdrop from "../../../Backdrop";
import { countryList } from "../../../../helperFuncs/countryList";
import { useSelector } from "react-redux";
import { CustomButton } from "../../../CustomButton";
import { errorBlock } from "../../../../controllers/errorBlock";
import { channelController } from "../../../../controllers/channelController";

const UnitPreview = ({ setopen, open, details, customer, refresh }) => {
  const [meterDetails, setMeterDetails] = useState({});
  const [loading, setloading] = useState(false);
  const { country, sitename } = useSelector((state) => state.projectSite);
  const { username } = useSelector((state) => state.userDetails);

  const handleBuyPlan = async () => {
    try {
      setloading(true);

      let res = await axios.post("/vending/payment/acquiretransactionid", {
        creator: username,
        planalias: "kWh##Untimed",
        channelid: customer?.meter,
        paymentgateway: "sales agent",
        amount: details?.totalCost,
        currency: countryList[country]["Currency Code"],
      });

      const result = channelController(res);
      // console.log(result);
      if (result.type !== "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }

      if (!("transactionid" in result.message)) {
        toast.error(result.message.response);
        setloading(false);
        return;
      }

      let tranxres = await axios.post("/vending/payment/committransaction", {
        transactionid: result.message?.transactionid,
      });

      const tranxresult = channelController(tranxres);

      if (tranxresult.type !== "success") {
        toast.error(tranxresult.message);
        setloading(false);
        return;
      }

      if (tranxresult.message.status == "Request Sent") {
        toast.success(tranxresult.message.status);
      } else {
        toast.error(tranxresult.message.status);
      }

      setloading(false);
      setopen(false);
      // refresh((prev) => !prev);
    } catch (error) {
      errorBlock(error);
      setloading(false);
    }
  };
  return (
    <Backdrop open={open} setopen={setopen}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[450px] bg-white drop-shadow-md rounded p-4">
          <div className=" pb-1 flex items-center justify-between">
            <div className="font-semibold">Units preview</div>
            <div className=" cursor-pointer" onClick={() => setopen(false)}>
              Close
            </div>
          </div>
          <hr />
          <div className="">
            <div className="mt-2">
              <p className="m-0 font-semibold text-center">Estimated units</p>
              <p className="mt-1 text-center">
                For{" "}
                <span className="font-semibold text-secondary">
                  {details?.totalCost}
                  <span>{countryList[country]["Currency Code"]}</span>
                </span>{" "}
                you will get{" "}
                <span className="font-semibold ">{details?.totalKWh}kWh</span>
              </p>
            </div>
            {/* <hr className="my-2" /> */}
            <div className="hidden">
              <p className="m-0 font-semibold">Units breakdown</p>
              <div className=""></div>
            </div>
            <div className="">
              <p className="mt-1 mb-0 text-center">
                Would you like to purchase this plan for{" "}
                <span className="font-semibold">
                  {customer?.firstname[0].toUpperCase() +
                    customer?.firstname.substring(1) +
                    " " +
                    customer?.lastname[0].toUpperCase()}
                  .
                </span>{" "}
              </p>
            </div>
            <div className="grid grid-cols-2 mt-4 items-center w-[80%] mx-auto justify-around">
              <CustomButton
                onClick={(e) => {
                  e.preventDefault();
                  setopen(false);
                }}
                className=" border p-2 h-[36px] rounded-md flex items-center justify-center"
              >
                <p className="m-0 text-black text-[13px]">No</p>
              </CustomButton>
              <CustomButton
                onClick={handleBuyPlan}
                loading={loading}
                className="bg-black border ml-3 p-2 h-[36px] rounded-md flex items-center justify-center"
              >
                <p className="m-0 text-white text-[13px]">Buy plan</p>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default UnitPreview;
