export const countryList = {
  Algeria: {
    "Currency Code": "DZD",
    "Country Code": "DZA",
    "Phone Code": "+213",
    "Flag Emoji": ":flag-dz:",
    "Time Zones": ["Africa/Algiers"],
  },
  Angola: {
    "Currency Code": "AOA",
    "Country Code": "AGO",
    "Phone Code": "+244",
    "Flag Emoji": ":flag-ao:",
    "Time Zones": ["Africa/Luanda"],
  },
  Benin: {
    "Currency Code": "XOF",
    "Country Code": "BEN",
    "Phone Code": "+229",
    "Flag Emoji": ":flag-bj:",
    "Time Zones": ["Africa/Porto-Novo"],
  },
  Botswana: {
    "Currency Code": "BWP",
    "Country Code": "BWA",
    "Phone Code": "+267",
    "Flag Emoji": ":flag-bw:",
    "Time Zones": ["Africa/Gaborone"],
  },
  "Burkina Faso": {
    "Currency Code": "XOF",
    "Country Code": "BFA",
    "Phone Code": "+226",
    "Flag Emoji": ":flag-bf:",
    "Time Zones": ["Africa/Ouagadougou"],
  },
  Burundi: {
    "Currency Code": "BIF",
    "Country Code": "BDI",
    "Phone Code": "+257",
    "Flag Emoji": ":flag-bi:",
    "Time Zones": ["Africa/Bujumbura"],
  },
  "Cabo Verde": {
    "Currency Code": "CVE",
    "Country Code": "CPV",
    "Phone Code": "+238",
    "Flag Emoji": ":flag-cv:",
    "Time Zones": ["Atlantic/Cape_Verde"],
  },
  Cameroon: {
    "Currency Code": "XAF",
    "Country Code": "CMR",
    "Phone Code": "+237",
    "Flag Emoji": ":flag-cm:",
    "Time Zones": ["Africa/Douala"],
  },
  "Central African Republic": {
    "Currency Code": "XAF",
    "Country Code": "CAF",
    "Phone Code": "+236",
    "Flag Emoji": ":flag-cf:",
    "Time Zones": ["Africa/Bangui"],
  },
  Chad: {
    "Currency Code": "XAF",
    "Country Code": "TCD",
    "Phone Code": "+235",
    "Flag Emoji": ":flag-td:",
    "Time Zones": ["Africa/Ndjamena"],
  },
  Comoros: {
    "Currency Code": "KMF",
    "Country Code": "COM",
    "Phone Code": "+269",
    "Flag Emoji": ":flag-km:",
    "Time Zones": ["Indian/Comoro"],
  },
  "Congo (Congo-Brazzaville)": {
    "Currency Code": "XAF",
    "Country Code": "COG",
    "Phone Code": "+242",
    "Flag Emoji": ":flag-cg:",
    "Time Zones": ["Africa/Brazzaville"],
  },
  "Congo (DRC)": {
    "Currency Code": "CDF",
    "Country Code": "COD",
    "Phone Code": "+243",
    "Flag Emoji": ":flag-cd:",
    "Time Zones": ["Africa/Kinshasa", "Africa/Lubumbashi"],
  },
  Djibouti: {
    "Currency Code": "DJF",
    "Country Code": "DJI",
    "Phone Code": "+253",
    "Flag Emoji": ":flag-dj:",
    "Time Zones": ["Africa/Djibouti"],
  },
  Egypt: {
    "Currency Code": "EGP",
    "Country Code": "EGY",
    "Phone Code": "+20",
    "Flag Emoji": ":flag-eg:",
    "Time Zones": ["Africa/Cairo"],
  },
  "Equatorial Guinea": {
    "Currency Code": "XAF",
    "Country Code": "GNQ",
    "Phone Code": "+240",
    "Flag Emoji": ":flag-gq:",
    "Time Zones": ["Africa/Malabo"],
  },
  Eritrea: {
    "Currency Code": "ERN",
    "Country Code": "ERI",
    "Phone Code": "+291",
    "Flag Emoji": ":flag-er:",
    "Time Zones": ["Africa/Asmara"],
  },
  "Eswatini (Swaziland)": {
    "Currency Code": "SZL",
    "Country Code": "SWZ",
    "Phone Code": "+268",
    "Flag Emoji": ":flag-sz:",
    "Time Zones": ["Africa/Mbabane"],
  },
  Ethiopia: {
    "Currency Code": "ETB",
    "Country Code": "ETH",
    "Phone Code": "+251",
    "Flag Emoji": ":flag-et:",
    "Time Zones": ["Africa/Addis_Ababa"],
  },
  Gabon: {
    "Currency Code": "XAF",
    "Country Code": "GAB",
    "Phone Code": "+241",
    "Flag Emoji": ":flag-ga:",
    "Time Zones": ["Africa/Libreville"],
  },
  Gambia: {
    "Currency Code": "GMD",
    "Country Code": "GMB",
    "Phone Code": "+220",
    "Flag Emoji": ":flag-gm:",
    "Time Zones": ["Africa/Banjul"],
  },
  Ghana: {
    "Currency Code": "GHS",
    "Country Code": "GHA",
    "Phone Code": "+233",
    "Flag Emoji": ":flag-gh:",
    "Time Zones": ["Africa/Accra"],
  },
  Guinea: {
    "Currency Code": "GNF",
    "Country Code": "GIN",
    "Phone Code": "+224",
    "Flag Emoji": ":flag-gn:",
    "Time Zones": ["Africa/Conakry"],
  },
  "Guinea-Bissau": {
    "Currency Code": "XOF",
    "Country Code": "GNB",
    "Phone Code": "+245",
    "Flag Emoji": ":flag-gw:",
    "Time Zones": ["Africa/Bissau"],
  },
  "Ivory Coast (Côte d’Ivoire)": {
    "Currency Code": "XOF",
    "Country Code": "CIV",
    "Phone Code": "+225",
    "Flag Emoji": ":flag-ci:",
    "Time Zones": ["Africa/Abidjan"],
  },
  Kenya: {
    "Currency Code": "KES",
    "Country Code": "KEN",
    "Phone Code": "+254",
    "Flag Emoji": ":flag-ke:",
    "Time Zones": ["Africa/Nairobi"],
  },
  Lesotho: {
    "Currency Code": "LSL",
    "Country Code": "LSO",
    "Phone Code": "+266",
    "Flag Emoji": ":flag-ls:",
    "Time Zones": ["Africa/Maseru"],
  },
  Liberia: {
    "Currency Code": "LRD",
    "Country Code": "LBR",
    "Phone Code": "+231",
    "Flag Emoji": ":flag-lr:",
    "Time Zones": ["Africa/Monrovia"],
  },
  Libya: {
    "Currency Code": "LYD",
    "Country Code": "LBY",
    "Phone Code": "+218",
    "Flag Emoji": ":flag-ly:",
    "Time Zones": ["Africa/Tripoli"],
  },
  Madagascar: {
    "Currency Code": "MGA",
    "Country Code": "MDG",
    "Phone Code": "+261",
    "Flag Emoji": ":flag-mg:",
    "Time Zones": ["Indian/Antananarivo"],
  },
  Malawi: {
    "Currency Code": "MWK",
    "Country Code": "MWI",
    "Phone Code": "+265",
    "Flag Emoji": ":flag-mw:",
    "Time Zones": ["Africa/Blantyre"],
  },
  Mali: {
    "Currency Code": "XOF",
    "Country Code": "MLI",
    "Phone Code": "+223",
    "Flag Emoji": ":flag-ml:",
    "Time Zones": ["Africa/Bamako"],
  },
  Mauritania: {
    "Currency Code": "MRO",
    "Country Code": "MRT",
    "Phone Code": "+222",
    "Flag Emoji": ":flag-mr:",
    "Time Zones": ["Africa/Nouakchott"],
  },
  Mauritius: {
    "Currency Code": "MUR",
    "Country Code": "MUS",
    "Phone Code": "+230",
    "Flag Emoji": ":flag-mu:",
    "Time Zones": ["Indian/Mauritius"],
  },
  Morocco: {
    "Currency Code": "MAD",
    "Country Code": "MAR",
    "Phone Code": "+212",
    "Flag Emoji": ":flag-ma:",
    "Time Zones": ["Africa/Casablanca"],
  },
  Mozambique: {
    "Currency Code": "MZN",
    "Country Code": "MOZ",
    "Phone Code": "+258",
    "Flag Emoji": ":flag-mz:",
    "Time Zones": ["Africa/Maputo"],
  },
  Namibia: {
    "Currency Code": "NAD",
    "Country Code": "NAM",
    "Phone Code": "+264",
    "Flag Emoji": ":flag-na:",
    "Time Zones": ["Africa/Windhoek"],
  },
  Niger: {
    "Currency Code": "XOF",
    "Country Code": "NER",
    "Phone Code": "+227",
    "Flag Emoji": ":flag-ne:",
    "Time Zones": ["Africa/Niamey"],
  },
  Nigeria: {
    "Currency Code": "NGN",
    "Country Code": "NGA",
    "Phone Code": "+234",
    "Flag Emoji": ":flag-ng:",
    "Time Zones": ["Africa/Lagos"],
  },
  Rwanda: {
    "Currency Code": "RWF",
    "Country Code": "RWA",
    "Phone Code": "+250",
    "Flag Emoji": ":flag-rw:",
    "Time Zones": ["Africa/Kigali"],
  },
  "São Tomé and Príncipe": {
    "Currency Code": "STN",
    "Country Code": "STP",
    "Phone Code": "+239",
    "Flag Emoji": ":flag-st:",
    "Time Zones": ["Africa/Sao_Tome"],
  },
  Senegal: {
    "Currency Code": "XOF",
    "Country Code": "SEN",
    "Phone Code": "+221",
    "Flag Emoji": ":flag-sn:",
    "Time Zones": ["Africa/Dakar"],
  },
  Seychelles: {
    "Currency Code": "SCR",
    "Country Code": "SYC",
    "Phone Code": "+248",
    "Flag Emoji": ":flag-sc:",
    "Time Zones": ["Indian/Mahe"],
  },
  "Sierra Leone": {
    "Currency Code": "SLL",
    "Country Code": "SLE",
    "Phone Code": "+232",
    "Flag Emoji": ":flag-sl:",
    "Time Zones": ["Africa/Freetown"],
  },
  Somalia: {
    "Currency Code": "SOS",
    "Country Code": "SOM",
    "Phone Code": "+252",
    "Flag Emoji": ":flag-so:",
    "Time Zones": ["Africa/Mogadishu"],
  },
  "South Africa": {
    "Currency Code": "ZAR",
    "Country Code": "ZAF",
    "Phone Code": "+27",
    "Flag Emoji": ":flag-za:",
    "Time Zones": ["Africa/Johannesburg"],
  },
  "South Sudan": {
    "Currency Code": "SSP",
    "Country Code": "SSD",
    "Phone Code": "+211",
    "Flag Emoji": ":flag-ss:",
    "Time Zones": ["Africa/Juba"],
  },
  Sudan: {
    "Currency Code": "SDG",
    "Country Code": "SDN",
    "Phone Code": "+249",
    "Flag Emoji": ":flag-sd:",
    "Time Zones": ["Africa/Khartoum"],
  },
  Tanzania: {
    "Currency Code": "TZS",
    "Country Code": "TZA",
    "Phone Code": "+255",
    "Flag Emoji": ":flag-tz:",
    "Time Zones": ["Africa/Dar_es_Salaam"],
  },
  Togo: {
    "Currency Code": "XOF",
    "Country Code": "TGO",
    "Phone Code": "+228",
    "Flag Emoji": ":flag-tg:",
    "Time Zones": ["Africa/Lome"],
  },
  Tunisia: {
    "Currency Code": "TND",
    "Country Code": "TUN",
    "Phone Code": "+216",
    "Flag Emoji": ":flag-tn:",
    "Time Zones": ["Africa/Tunis"],
  },
  Uganda: {
    "Currency Code": "UGX",
    "Country Code": "UGA",
    "Phone Code": "+256",
    "Flag Emoji": ":flag-ug:",
    "Time Zones": ["Africa/Kampala"],
  },
  Zambia: {
    "Currency Code": "ZMW",
    "Country Code": "ZMB",
    "Phone Code": "+260",
    "Flag Emoji": ":flag-zm:",
    "Time Zones": ["Africa/Lusaka"],
  },
  Zimbabwe: {
    "Currency Code": "ZWL",
    "Country Code": "ZWE",
    "Phone Code": "+263",
    "Flag Emoji": ":flag-zw:",
    "Time Zones": ["Africa/Harare"],
  },
};
