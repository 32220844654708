import axios from "../../../../../utils/axios";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import Backdrop from "../../../../Backdrop";
import CloseButton from "../../../../CloseButton";
import Rule from "../../../../Rule/Index";
import { Button } from "../../../../Button";

const ViewPaymentPlan = ({ open, setopen, details, refreshBtn }) => {
  let finalDate = "";
  const [loading, setloading] = useState(false);
  console.log(details, "details");

  const dateformatter = [
    {
      date: "Sunday",
      value: details?.daybinaries[0],
    },
    {
      date: "Monday",
      value: details?.daybinaries[1],
    },
    {
      date: "Tuesday",
      value: details?.daybinaries[2],
    },
    {
      date: "Wednesday",
      value: details?.daybinaries[3],
    },
    {
      date: "Thursday",
      value: details?.daybinaries[4],
    },
    {
      date: "Friday",
      value: details?.daybinaries[5],
    },
    {
      date: "Saturday",
      value: details?.daybinaries[6],
    },
  ]
    .filter((i) => i?.value == "1")
    .map((i) => i.date);

  if (dateformatter.length > 1) {
    const andClause = "and " + dateformatter[dateformatter.length - 1];
    let formatter = dateformatter.slice(0, dateformatter.length - 1);
    formatter.push(andClause);
    finalDate = formatter.join(", ");
  } else {
    finalDate = `${dateformatter[0]}.`;
  }

  const formattime = (item) =>
    `${item.substring(0, 2)}:${item.substring(2, 4)}`;

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[550px] bg-white drop-shadow-md rounded p-3">
          <div className="flex items-start  w-full justify-between">
            <div className="">
              <div className=" font-semibold text-[14px] bg-gray-50">
                Payment plan details
              </div>
              <div className=" hidden w-[80%] text-gray-700 font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <CloseButton closeBtn={setopen} />
          </div>

          <Rule />
          <div className="">
            <div className=" mt-2">
              <div className="text-gray-700 text-[14px]">Plan name:</div>
              <div className="font-semibold text-gray-800 border rounded p-2 mt-1 text-[14px] bg-gray-50">
                {details?.planalias}
              </div>
            </div>
            <div className=" mt-2">
              <div className="text-gray-700 text-[14px]">Plan summary:</div>
              <div className="font-semibold p-2 border mt-1 rounded  text-gray-80 text-gray-800 text-[14px] bg-gray-50">
                This plan will be avaliable for {details?.duration}{" "}
                {details?.durationlength} from {formattime(details.starttime)}{" "}
                to {formattime(details.endtime)} on {finalDate}
                {/* {details?.duration}  {details?.durationlength} {" "} */}
                {/*   */}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className=" mt-2">
                <div className="text-gray-700 text-[14px]">Price:</div>
                <div className="font-semibold  p-2 border mt-1 rounded text-gray-800 text-[14px] bg-gray-50">
                  ₦{details?.amount.toLocaleString(undefined)}
                </div>
              </div>
              <div className=" mt-2">
                <div className="text-gray-700 text-[14px]">Date created:</div>
                <div className="font-semibold  p-2 border mt-1 rounded  text-gray-800 text-[14px] bg-gray-50">
                  {moment(details?.datecreated).format("lll")}
                </div>
              </div>
            </div>

            <div className=" mt-2">
              <div className="text-gray-700 text-[14px]">Units assigned:</div>
              <div className="font-semibold p-2 border mt-1 rounded text-gray-800 text-[14px] bg-gray-50">
                {details?.energyorpower} units
              </div>
            </div>
            <div className=" mt-2">
              <div className="text-gray-700 text-[14px]">Description:</div>
              <div className="font-semibold p-2 border mt-1 rounded text-gray-800 text-[14px] bg-gray-50">
                {details?.description}
              </div>
            </div>
          </div>

          {/* <div className=" mt-2">
            <div className="text-gray-700">:</div>
            <div className="font-semibold text-gray-800 text-[14px] bg-gray-50">
              {details?.energyorpower}units
            </div>
          </div> */}
        </div>
      </div>
    </Backdrop>
  );
};

export default ViewPaymentPlan;
