import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const customerAPI = createApi({
  reducerPath: "customerPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      //   const token = credentials.token;
      //   if (token) {
      //     headers.set("Version", `0.1`);
      //     return headers;
      //   }
    },
  }),
  endpoints: (build) => ({
    // unassign roles
    getcustomerAddress: build.mutation({
      query: (data) => ({
        url: "customer/getallmetercustomeraddresses",
        method: "POST",
        body: {
          ...credentials,
          customerid: data,
        },
      }),
    }),
    editCustomerMeter: build.mutation({
      query: (data) => ({
        url: "customer/editcustomeraccount",
        method: "POST",
        body: {
          ...credentials,
          customerid: data?.customerid,
          meter: data?.meter,
          customertype: data?.customertype,
          section: data?.section,
          gps: data?.gps,
          polenumber: data?.polenumber,
          projectzone: Number(data?.projectzone),
          address: data?.address,
        },
      }),
    }),
    //
  }),
});

export const { useGetcustomerAddressMutation, useEditCustomerMeterMutation } =
  customerAPI;
