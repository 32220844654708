import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { FcCalendar, FcDeleteRow } from "react-icons/fc";
import { MdOutlineDeleteForever } from "react-icons/md";
import DataTable from "react-data-table-component";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import ViewPaymentPlan from "./modals/ViewPaymentPlan";
import { useSelector } from "react-redux";
import { channelController } from "../../../controllers/channelController";
import { errorBlock } from "../../../controllers/errorBlock";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { CustomButton } from "../../CustomButton";

const OtherSitePaymentPlan = ({
  data,
  loading,
  refreshbtn,
  tabstate,
  filteredData,
}) => {
  const [plandetails, setplandetails] = useState({});
  const [selectedtabstate, setselectedtabstate] = useState(0);
  const [deleting, setdeleting] = useState(false);
  const [selectplan, setselectplan] = useState({});
  const [open, setopen] = useState(false);
  const { siteid } = useSelector((state) => state.projectSite);

  const columns = [
    {
      name: "Plan Alias",
      center: false,
      selector: (row) => row.planalias,
      grow: 2,
    },

    {
      name: "Plan Duration",
      center: true,
      selector: (row) => row.duration,
      grow: 1,
      cell: (item) => {
        return (
          <div className="">{`${item.duration} ${item.durationlength}`}</div>
        );
      },
    },
    {
      name: "Amount",
      center: true,
      selector: (row) => row.amount,
      grow: 1,
    },

    {
      name: "Meter Class",
      center: true,
      selector: (row) => row.meter_class,
      grow: 1,
    },
    {
      name: "Action",
      selector: (row) => row.id, // Assuming you have an id field for each row
      grow: 2,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            {tabstate == 1 && (
              <CustomButton
                loading={selectplan == item.planalias ? deleting : false}
                disabled={deleting}
                color={"tomato"}
                onClick={() => {
                  setselectplan(item.planalias);
                  // deletePlan(item);
                }}
                className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
              >
                {selectplan == item.planalias && deleting ? (
                  ""
                ) : (
                  <MdOutlineDeleteForever size={15} />
                )}
              </CustomButton>
            )}
            {/* {tabstate == 1 && (
              <Link
                to={`/paymentplan/edit/${item.planalias}`}
                onClick={() => {}}
                className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
              >
                <FaRegEdit size={15} />
              </Link>
            )} */}
            <div
              onClick={() => {
                setplandetails(item);
                setopen(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              <FaEye size={15} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="">
      {open && (
        <ViewPaymentPlan open={open} setopen={setopen} details={plandetails} />
      )}
      {data?.map((i, index) => (
        <div className="">
          <div
            key={index}
            onClick={() => {
              if (selectedtabstate == index) {
                setselectedtabstate(null);
                return;
              }
              setselectedtabstate(index);
            }}
            className="bg-[#f9fdff] p-2 mb-2 cursor-pointer border w-full flex items-center justify-between "
          >
            <div className="">
              <h2 className="m-0">
                Site name: {i?.siteName}{" "}
                <span>
                  (
                  {i?.plans?.length == 0
                    ? "No plan avalable "
                    : `${i?.plans?.length} plan${
                        i?.plans?.length == 1 ? "" : "s"
                      } available`}
                  )
                </span>
              </h2>
              {/* <p className="m-0">
                {i?.plans?.length == 0
                  ? "No plan avalable "
                  : `${i?.plans?.length} plan${
                      i?.plans?.length == 1 ? "" : "s"
                    } available`}
              </p> */}
            </div>
            <div className="">
              {selectedtabstate == index ? (
                <IoIosArrowUp size={18} />
              ) : (
                <IoIosArrowDown size={18} />
              )}
            </div>
          </div>
          {selectedtabstate == index && (
            <div className="">
              <DataTable
                style={{
                  height: "100%",
                }}
                columns={columns}
                data={i?.plans}
                pagination
                progressPending={loading}
                paginationPerPage={10}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OtherSitePaymentPlan;

// const deletePlan = async (item) => {
//   try {
//     setdeleting(true);
//     let res = await axios.post("/vending/paymentplan/deletepaymentplan", {
//       planalias: item.planalias,
//       customerclass: item.meter_class,
//       siteid: Number(siteid),
//     });

//     let result = channelController(res);

//     if (result.type != "success") {
//       toast.error(result.message);
//       setdeleting(false);
//       return;
//     }
//     toast.success("Plan deleted successfully.");
//     setdeleting(false);
//     refreshbtn((prev) => !prev);
//   } catch (err) {
//     errorBlock(err);
//     setdeleting(false);
//   }
// };
