import React, { useMemo } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import CustomMarker from "./custom-marker";
import { findCenterPoint } from "../../../../helperFuncs";
import Rule from "../../../Rule/Index";
import { Link, useSearchParams } from "react-router-dom";

const MetersInDCULocation = ({ data, zoom }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let siteid = searchParams.get("siteid");

  const extractPosition = (entry) => {
    if (!entry?.gps) return null;
    const [lat, lng] = entry.gps.split(",");
    return { lat: parseFloat(lat), lng: parseFloat(lng) };
  };

  const formattedGps = !data
    ? []
    : data.filter((i) => i?.gps != null).map((i) => i.gps.split(","));

  const validPositions = useMemo(() => {
    return data.filter((i) => i?.gps != null).map(extractPosition);
  }, [data]);

  const centerPointer = findCenterPoint(formattedGps);

  if (!data || data.length == 0) {
    return (
      <div className="text-center font-normal p-3">
        No meter available in DCU
      </div>
    );
  }

  if (!centerPointer || centerPointer.some(isNaN)) {
    return <div className="text-center">No valid GPS coordinates found</div>;
  }

  return (
    <MapContainer
      center={centerPointer}
      zoom={zoom || 2}
      style={{ height: "100%", width: "100%" }}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {validPositions.map((position, index) => (
        <CustomMarker key={index} position={[position.lat, position.lng]}>
          <Popup>
            {data[index]?.type === "meter" && (
              <div>
                Name: {data[index]?.name}
                <br />
                Meter: {data[index]?.meter}
                <br />
                Username: {data[index]?.username}
              </div>
            )}
            {data[index]?.type === "dcu" && (
              <>
                <div className="">Device details</div>
                <Rule />
                <div className="w-[200px]">
                  <div className="flex m-0 items-center justify-between mt-2">
                    <div>DCU ID: </div>
                    <div className="m-0 font-semibold">
                      <Link
                        to={`/dculocation/${data[index]?.dcuid}/${siteid}`}
                        className="underline text-blue-500"
                      >
                        {data[index]?.dcuid}
                      </Link>
                    </div>
                  </div>
                  <div className="flex m-0 items-center justify-between mt-2">
                    <div>DCU Name: </div>
                    <div className="m-0 font-semibold">
                      {data[index]?.dcualias}
                    </div>
                  </div>
                  <div className="flex m-0 items-center justify-between mt-2">
                    <div>Meter in DCU: </div>
                    <div className="m-0 font-semibold">
                      {data[index]?.meter_count}
                    </div>
                  </div>
                </div>
              </>
            )}
          </Popup>
        </CustomMarker>
      ))}
    </MapContainer>
  );
};

export default MetersInDCULocation;
