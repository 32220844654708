import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Layout from "../../components/Layout";

import CustomersTable from "../../components/Customer/Table/CustomersTable";
import { channelController } from "../../controllers/channelController";
import { Select, theme } from "antd";
import { columnData } from "./data";
import "./style.css";
import { themeColor as color, themeColor } from "../../constant/color";
import axios from "../../utils/axios";
import { Button } from "../../components/Button";
import AdvancedFitler from "../../components/Customer/AdvancedFilter";

/** AntD Table */
import AntDTable from "../../components/Table/AntdTable";
import { customersColumns } from "../../components/TableColumns";
import TableSkimmer from "../../components/TableSkimmer";
import BackBtn from "../../components/BackBtn";

const Customers = () => {
  const [data, setData] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("customerid");
  const [columnInput, setColumnInput] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { Option } = Select;
  const controller = new AbortController();

  useEffect(() => {
    (async () => {
      try {
        // 7654
        if (!selectedColumn)
          return toast.error(
            "Please enter a column and enter the column value."
          );
        setLoading(true);
        let res = await axios.post("/customer/getallmetercustomers", {
          column: selectedColumn, // customerid
          columnparameter: columnInput,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        setData(result.message.body);
        setLoading(false);
        // 3456;
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
        // 3456
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh]);

  const btnStyles = {
    padding: "20px",
  };

  return (
    <Layout>
      <div className="mt-4">
        {modal && (
          <AdvancedFitler
            closeModal={setModal}
            refreshBtn={setRefresh}
            // setColumn={setSelectedColumn}
            // setColumnInput={() => console.log()}
            setData={setData}
          />
        )}
        <div className="mb-[200px]">
          <div className="p-2 mt-2 bg-white shadow-md rounded-md">
            <div className="col-span-12">
              <BackBtn />
              <div className="flex items-center justify-between">
                <div className="col-span-7 w-3/5">
                  <div className="customer-cover">
                    <div className="font-semibold">
                      Details of meter owned by customers
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center justify-between">
                    <div className=" ">
                      <Select
                        bordered
                        style={{
                          width: 200,
                          height: 33,
                        }}
                        defaultValue={selectedColumn}
                        placeholder={"Select a search field."}
                        onChange={(e) => setSelectedColumn(e)}
                      >
                        {columnData.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className="hidden mt-2"
                      onClick={() => setModal(true)}
                    ></div>
                    <div className="ml-4 flex overflow-hidden md:w-[200px] h-[33px] lg:w-[200px] items-center w-full border-[1px] rounded-sm border-gray-300  w-100">
                      <div className="ml-2 w-100">
                        <input
                          type={"text"}
                          placeholder={"Enter " + selectedColumn}
                          onChange={(e) => setColumnInput(e.target.value)}
                          className="placeholder:text-sm block w-100 text-[13px] placeholder:font-normal outline-none border-[0px] "
                        />
                      </div>
                    </div>
                    <div className="ml-4 flex overflow-hidden md:w-[150px] h-[33px] lg:w-[160px] items-center w-full border-[1px] rounded-sm border-gray-300  w-100">
                      <Button
                        text={"Search"}
                        bg={themeColor.secondary}
                        loading={loading}
                        onClick={() => setRefresh((prev) => !prev)}
                        // onClick={() => setAddSite(true)}
                      />
                    </div>
                    <div className="flex">
                      <div className=" hidden items-center justify-between rounded-sm w-full">
                        <input
                          type={"text"}
                          placeholder={`Enter username.`}
                          value={columnInput}
                          className=" px-2"
                          onChange={(e) => setColumnInput(e.target.value)}
                        />
                        <div
                          className="column-btn rounded-sm text-center text-white pt-1"
                          style={{ background: themeColor.baseColor }}
                          onClick={() => setRefresh((prev) => !prev)}
                        >
                          {loading ? "Searching..." : "Search"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-4" />
            <div className="col-span-12 h-[80vh] mt-5">
              {loading ? (
                <TableSkimmer entries={10} col={7} />
              ) : (
                <CustomersTable data={data} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Customers;
