import {
  RiDashboardFill,
  RiWallet3Fill,
  RiSettings5Fill,
} from "react-icons/ri";
import { GiWallet } from "react-icons/gi";
import { IoLogOut } from "react-icons/io5";
import { FaBuilding, FaUsers } from "react-icons/fa";
import { SlSettings } from "react-icons/sl";
import { MdMoreTime } from "react-icons/md";
import { IoHardwareChip } from "react-icons/io5";
import { IoHardwareChipOutline } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { MdOutlineDashboard } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";
import { MdDiscount } from "react-icons/md";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { RiBillLine } from "react-icons/ri";
import { CiWallet } from "react-icons/ci";
import { FiUsers } from "react-icons/fi";
import { MdOutlineElectricMeter } from "react-icons/md";
import { TbUsersPlus } from "react-icons/tb";
import { LuBarChart2 } from "react-icons/lu";

import { HiOutlineUsers } from "react-icons/hi2";
export const NavIcon = ({ title, link }) => {
  const location = useLocation();

  switch (title) {
    case "Dashboard":
      return (
        <MdOutlineDashboard
          size={21}
          color={location.pathname == link ? "#fff" : "#6c6c6c"}
        />
      );
    case "Payment plan":
      return (
        <RiBillLine
          size={21}
          color={
            location.pathname == link ||
            location.pathname.includes("/paymentplan") ||
            location.pathname.includes("/tierplans")
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "Site Discount":
      return (
        <MdDiscount
          size={21}
          color={location.pathname == link ? "#fff" : "#6c6c6c"}
        />
      );
    case "Devices":
      return (
        <MdOutlineElectricMeter
          size={23}
          // color={"white"}
          color={location.pathname == "/dcu" ? "#fff" : "#6c6c6c"}
        />
      );
    case "Customer profile":
      return (
        <RiDashboardFill
          size={21}
          color={
            location.pathname.includes == "/alldevices" ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Billing":
      return (
        <RiDashboardFill
          size={21}
          color={
            location.pathname.includes == "/dashboard/systemadmin"
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "User Management":
      return (
        <HiUsers
          size={21}
          color={
            location.pathname == "/channel/usermanagement" ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Project site":
      return (
        <HiOutlineBuildingOffice2
          size={18}
          color={location.pathname == "/projectsite" ? "#fff" : "#6c6c6c"}
        />
      );
    case "Customer Profile":
      return (
        <FaUsersCog
          size={21}
          color={location.pathname.includes("/users") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Users":
      return (
        <FaUsers
          size={21}
          color={location.pathname.includes("/users") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Staff":
      return (
        <HiOutlineUsers
          size={21}
          color={location.pathname.includes("/users") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Manage meters":
      return (
        <FaUsersCog
          size={21}
          color={location.pathname == "manage-meters" ? "#fff" : "#6c6c6c"}
        />
      );
    case "Wallet":
      return (
        <GiWallet
          size={21}
          color={location.pathname == "/wallet" ? "#fff" : "#6c6c6c"}
        />
      );
    case "Roles":
      return (
        <TbUsersPlus
          size={21}
          color={location.pathname.includes("/role") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Meters":
      return (
        <MdOutlineElectricMeter
          size={21}
          color={
            location.pathname.includes("/allmeters") ||
            location.pathname.includes("/usermeters")
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "Customer meters":
      return (
        <IoHardwareChipOutline
          size={21}
          color={
            location.pathname == "/salesagent/customer" ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Customer meters":
      return (
        <IoHardwareChipOutline
          size={21}
          color={
            location.pathname == "/dashboard/customers" ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Customers":
      return (
        <FiUsers
          size={21}
          color={location.pathname == "/manage-meter" ? "#fff" : "#6c6c6c"}
        />
      );
    case "Project sites":
      return (
        <FaBuilding
          size={19}
          color={
            location.pathname == "/dashboard/systemadmin/projectsite"
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "Customer class":
      return (
        <MdMoreTime
          size={21}
          color={location.pathname == "/meterclass" ? "#fff" : "#6c6c6c"}
        />
      );
    case "Sales analytics":
      return (
        <LuBarChart2
          size={23}
          color={
            location.pathname == "/salesagent/revenue" ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Revenue":
      return (
        <CiWallet
          size={23}
          color={location.pathname.includes("/revenue") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Meter Settings":
      return (
        <RiSettings5Fill
          size={21}
          color={location.pathname.includes("/settings") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Settings":
      return (
        <SlSettings
          size={21}
          color={location.pathname.includes("/settings") ? "#fff" : "#6c6c6c"}
        />
      );
    case "Sales Analytics":
      return (
        <RiWallet3Fill
          size={21}
          color={
            location.pathname.includes("/salesanalytics/") ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Recharge History":
      return (
        <RiWallet3Fill
          size={21}
          color={
            location.pathname.includes("/recharge-history") ? "#fff" : "#6c6c6c"
          }
        />
      );
    case "Meter Activities":
      return (
        <RiWallet3Fill
          size={21}
          color={
            location.pathname.includes("/customer-meter/meteractivities")
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "Transactions":
      return (
        <GiWallet
          size={21}
          color={
            location.pathname.includes("/allcustomer-transactions")
              ? "#fff"
              : "#6c6c6c"
          }
        />
      );
    case "Log out":
      return <IoLogOut size={21} color={"#6c6c6c"} />;
    default:
      return null;
  }
};

export default NavIcon;
