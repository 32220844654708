import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { useForm } from "react-hook-form";
import ErrorText from "../../../Errors/ErrorText";
import { useState } from "react";
import { Button } from "../../../Button";
import { toast } from "react-hot-toast";
import axios from "../../../../utils/axios";
import { meterController } from "../../../../controllers/MeterController";
import { countryList } from "../../../../helperFuncs/countryList";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { errorBlock } from "../../../../controllers/errorBlock";
const ProjectManagerTopUpWallet = ({ open, setopen, refresh }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { username } = useParams();
  const [amount, setamount] = useState(0);

  const { country, sitename } = useSelector((state) => state.projectSite);

  const [loading, setLoading] = useState(false);

  const handleTopUp = async () => {
    try {
      setLoading(true);
      // console.log(typeof amount);
      if (!amount || amount < 0) {
        toast.error("Amount field is required");
        setLoading(false);
        return;
      }

      const formattedAmount = parseFloat(Number(amount).toFixed(2));
      let res = await axios.post(
        "/wallet/rechargeagentwalletbyprojectmanager",
        {
          username,
          amount: formattedAmount,
          currencycode: countryList[country]["Currency Code"],
        }
      );

      if (res.data.response != "Success") {
        toast.error(res.data.response);
        setLoading(false);
        return;
      }

      toast.success("Top up successful.");
      refresh((prev) => !prev);
      setopen(false);
      setLoading(false);
    } catch (err) {
      errorBlock(err);
      setLoading(false);
    }
  };
  return (
    <Backdrop open={open} setopen={setopen}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[400px] bg-white drop-shadow-md rounded-sm p-4">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-semibold text-[17px]">
                Credit Agent Wallet
              </div>
            </div>
            <div className="flex  mt-[-5px] justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <div className="text-gray-700 mt-4 font-normal">
            You are about recharge the{" "}
            <span className="text-secondary font-semibold">
              {countryList[country]["Currency Code"]}
            </span>{" "}
            wallet of <span className="font-semibold"> {username ?? ""}</span>{" "}
            who is sales agent in{" "}
            <span className="font-semibold">{sitename}</span>
          </div>
          <Rule />
          <div>
            <div className="mt-4">
              <label className="text-primary font-normal text-sm">
                Enter an amount
              </label>
              <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-300 mt-2 h-[40px]">
                <div className="ml-2">
                  <input
                    required
                    value={amount}
                    onChange={(e) => setamount(e.target.value)}
                    type={"number"}
                    placeholder={"Enter an amount"}
                    className="bg-gray-100 placeholder:font-thin w-full outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6">
              <Rule />
            </div>

            <Button
              text={"Top up"}
              loading={loading}
              onClick={() => handleTopUp()}
            />
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ProjectManagerTopUpWallet;
