import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackBtn from "../../../../../components/BackBtn";
import Layout from "../../../../../components/Layout";
import UsersLineChart from "../../../../../components/RecordsCharts/UsersLineChart";
import CustomerRecordsTable from "../../../../../components/Table/CustomerRecordsTable";
import { useGetSiteDetailsMutation } from "../../../../../services/projectsite.services";
import { errorBlock } from "../../../../../controllers/errorBlock";
import { countryList } from "../../../../../helperFuncs/countryList";
import FilterDateRange from "../../../../../components/FilterDateRange";
import Rule from "../../../../../components/Rule/Index";

const SiteUsersRecords = () => {
  const [startsate, setStartDate] = useState(
    moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
  );

  const handlefilter = (e) => {
    switch (e) {
      case "today":
        setStartDate(
          moment(Date.now()).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "week":
        setStartDate(
          moment(Date.now()).startOf("week").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "month":
        setStartDate(
          moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      case "year":
        setStartDate(
          moment(Date.now()).startOf("year").format("YYYY-MM-DD HH:mm:ss")
        );
        break;
      default:
        break;
    }

    setEndDate(moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
    setRefresh((prev) => !prev);
  };

  const [currency, setcurrency] = useState("");

  const [modal, setmodal] = useState(false);

  const [enddate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const { projectsite, id } = useParams();

  const [refresh, setRefresh] = useState(false);

  const [
    getSiteDetails,
    { isLoading: siteloading, error: siteError, data: siteData },
  ] = useGetSiteDetailsMutation();

  // get Site details
  useEffect(() => {
    getSiteDetails({
      siteid: projectsite,
    }).unwrap();
  }, []);

  useEffect(() => {
    if (siteData?.country) {
      setcurrency(countryList[siteData?.country]["Currency Code"]);
    }
  }, [siteData]);

  useEffect(() => {
    if (siteError) {
      errorBlock(siteError);
    }
  }, [siteError]);
  return (
    <Layout>
      <div className="mt-2 p-2 bg-white drop-shadow-sm rounded">
        <div className="flex bg-gray-100 p-1 rounded items-center justify-between">
          <div className="font-bold">
            <BackBtn />
            <div className="">
              User on boarded by {id} in{" "}
              <span className="">{siteData?.sitename ?? ""}</span>{" "}
            </div>
          </div>

          <div className="flex ">
            <FilterDateRange
              open={modal}
              setRefresh={setRefresh}
              setopen={setmodal}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              handlefilter={handlefilter}
            />
          </div>
        </div>

        <Rule />
        <div className="h-[80vh]">
          <UsersLineChart />
        </div>
      </div>
    </Layout>
  );
};

export default SiteUsersRecords;
