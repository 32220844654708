import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import CustomMarker from "./custom-marker";
import { findCenterPoint } from "../../../../helperFuncs";
import Rule from "../../../Rule/Index";
import { Link } from "react-router-dom";

const ProjectSiteLocation = ({ data, zoom }) => {
  let formattedGps = data.map((i) => i.gps.split(","));
  const coordinates = [
    // [51.505, -0.09],
    [9.0643305, 7.4892974],
    [6.4550575, 3.3941795],
    [4.9795999, 8.3373597],
    // Add more coordinates as needed
  ];

  const extractPosition = (entry) => {
    return {
      lat: +entry?.split(",")[0],
      lng: +entry?.split(",")[1],
    };
  };

  let centerPointer1 = findCenterPoint(formattedGps);

  return (
    <>
      {centerPointer1 && (
        <MapContainer
          center={centerPointer1}
          zoom={zoom ? zoom : 4}
          style={{}}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {data.map((value, index) => (
            <CustomMarker
              key={index}
              position={[
                extractPosition(value?.gps).lat,
                extractPosition(value?.gps).lng,
              ]}
              // position={extractPosition([value[0], value[1]])}
            >
              <Popup>
                <div className="w-[200px]">
                  <div className="">
                    Sitename:{" "}
                    <span className="font-semibold">{value?.sitename}</span>
                  </div>
                  <Rule />
                  <div className="flex m-0 items-center justify-between mt-2">
                    <div>Site type: </div>
                    <div className="m-0 font-semibold">{value?.sitetype}</div>
                  </div>
                  <div className="flex m-0 items-center justify-between mt-2">
                    <div>Timezone: </div>
                    <div className="m-0 font-semibold">{value?.timezone}</div>
                  </div>
                  <div className="flex m-0 items-center justify-between mt-2">
                    <div>Number of meters: </div>
                    <div className="m-0 font-semibold">
                      {value?.meter_count}
                    </div>
                  </div>
                  <div className="flex m-0 items-center justify-between mt-2">
                    <div>Number of devices </div>
                    <div className="m-0 font-semibold">{value?.dcu_count}</div>
                  </div>
                  <div className="flex mt-2 items-center justify-between">
                    <Link
                      to={`/projectsite/location?lat=${
                        extractPosition(value?.gps).lat
                      }&long=${extractPosition(value?.gps).lng}&siteid=${
                        value?.siteid
                      }`}
                      className="underline"
                    >
                      View meters in site
                    </Link>
                  </div>
                </div>
              </Popup>
            </CustomMarker>
          ))}
        </MapContainer>
      )}
    </>
  );
};

export default ProjectSiteLocation;
