import DataTable from "react-data-table-component";
import { countryList } from "../../../../helperFuncs/countryList";
import { useSelector } from "react-redux";
import { tableCustomStyles } from "../../../../helperFuncs";
const SalesAgentTable = ({ data, currency, type }) => {
  let formattedData;
  const formatSiteStats = (siteStats, currency) => {
    return Object.values(siteStats).map((site) => ({
      siteName: site.siteName,
      siteType: site.siteType,
      transactionCount: site[currency]?.transactionCount || 0,
      totalRevenue: site[currency]?.totalRevenue || 0,
      totalCommission: site[currency]?.totalCommission || 0,
      netRevenue: site[currency]?.netRevenue || 0,
      totalKWh: site[currency]?.totalKWh || 0,
      avgTransactionValue: site[currency]?.avgTransactionValue || 0,
    }));
  };

  if (type != "agent") {
    formattedData = !data ? [] : formatSiteStats(data, currency);
  } else {
    formattedData = data;
  }

  const columns = [
    {
      name: "Site Name",
      center: true,
      sortable: true,
      selector: (row) => row.siteName,
      grow: 1,
      omit: type == "agent" ? true : false,
    },
    {
      name: "Transaction Count",
      center: true,
      sortable: true,
      selector: (row) => row.transactionCount,
      grow: 1,
    },
    {
      //
      name: `Total Revenue`,
      center: true,
      sortable: true,
      selector: (row) => row.totalRevenue,
      grow: 1,
      cell: (row) => (
        <div className="">
          {row?.totalRevenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      name: `Net revenue`,
      center: true,
      sortable: true,
      selector: (row) => row.netRevenue,
      grow: 1,
      cell: (row) => (
        <div className="">
          {row?.netRevenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      name: `Total commission`,
      center: true,
      sortable: true,
      selector: (row) => row.totalCommission,
      grow: 1,
      cell: (row) => (
        <div className="">
          {row?.totalCommission.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      name: "Total kWh",
      center: true,
      sortable: true,
      selector: (row) => row.totalKWh,
      grow: 1,
      cell: (row) => (
        <div className="">
          {row?.totalKWh.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      name: `Avg Transaction Value`,
      center: true,
      sortable: true,
      selector: (row) => row.avgTransactionValue,
      grow: 1,
      cell: (row) => (
        <div className="">
          {row?.avgTransactionValue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      name: `Date`,
      center: true,
      sortable: true,
      selector: (row) => row.date,
      grow: 1,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={formattedData}
      pagination={true}
      paginationPerPage={10}
      customStyles={tableCustomStyles}
    />
  );
};

export default SalesAgentTable;
