import Chart from "react-apexcharts";
import React from "react";
import moment from "moment";

const SalesAgentsCategoryLineChart = ({ height, data, currency }) => {
  // Extracting date, totalRevenue, and totalCommission
  const dates = data.length ? data.map((i) => i.date) : [];
  const totalRevenue = data.length
    ? data.map((i) => Number(i.totalRevenue))
    : [];
  const totalCommission = data.length
    ? data.map((i) => Number(i.totalCommission))
    : [];

  return (
    <Chart
      type="bar"
      options={{
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false, // Hide the toolbar to save space
          },
        },
        xaxis: {
          categories: dates,
          labels: {
            formatter: function (value) {
              return moment(value).format("MM-DD"); // Shorter date format
            },
            rotate: -45, // Rotate labels to save horizontal space
            style: {
              fontSize: "10px", // Smaller font size for labels
            },
          },
        },
        yaxis: {
          title: {
            text: "Amount",
            style: {
              fontSize: "12px", // Smaller font size for y-axis title
            },
          },
          labels: {
            style: {
              fontSize: "10px", // Smaller font size for y-axis labels
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (val) => `${currency ?? ""}${val}`,
          },
        },
        legend: {
          position: "top",
          fontSize: "12px", // Smaller font size for legend
        },
        plotOptions: {
          bar: {
            columnWidth: "20%", // Adjust bar width
          },
        },
        grid: {
          padding: {
            left: 10,
            right: 10,
          },
        },
      }}
      series={[
        {
          name: "Total Revenue",
          data: totalRevenue,
        },
        {
          name: "Total Commission",
          data: totalCommission,
        },
      ]}
      height={height || 350} // Reduced default height
      width="100%"
    />
  );
};

export default SalesAgentsCategoryLineChart;
