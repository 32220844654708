import { useParams } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useEffect, useState } from "react";
import { errorBlock } from "../../../../controllers/errorBlock";
import { useSelector } from "react-redux";
import axios from "../../../../utils/axios";
import { useForm } from "react-hook-form";
import { CustomButton } from "../../../../components/CustomButton";
import PageTitle from "../../../../components/PageTitle";
import DaysSelector from "../../../../components/Commands/DaysSelector";
import { durationValues } from "../../../../helperFuncs";
import { Select, TimePicker } from "antd";
import ErrorText from "../../../../components/Errors/ErrorText";

const PaymentPlanEdit = () => {
  const { planid, customerclass } = useParams();
  const [time, settime] = useState({
    starttime: "",
    endtime: "",
  });
  const [durationperiod, setdurationperiod] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const { siteid, sitename } = useSelector((state) => state.projectSite);
  const [plandetails, setplandetails] = useState(null);
  const [daybinaries, setdaybinaries] = useState([
    {
      id: 1,
      title: "Sunday",
      value: 0,
    },
    {
      id: 2,
      title: "Monday",
      value: 0,
    },
    {
      id: 3,
      title: "Tuesday",
      value: 0,
    },
    {
      id: 4,
      title: "Wednesday",
      value: 0,
    },
    {
      id: 5,
      title: "Thursday",
      value: 0,
    },
    {
      id: 6,
      title: "Friday",
      value: 0,
    },
    {
      id: 7,
      title: "Saturday",
      value: 0,
    },
  ]);
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/vending/paymentplan/getspecificpaymentplaninfo",
          {
            planalias: planid,
            siteid: Number(siteid),
            customerclass,
          }
        );

        const { daybinaries } = res.data;

        setplandetails(res.data);
        setdaybinaries((prev) => {
          return prev.map((i, index) => {
            return {
              id: i.id,
              title: i.title,
              value: Number(daybinaries.split("")[index]),
            };
          });
        });
      } catch (error) {
        errorBlock(error);
      }
    })();
  }, []);

  const formattime = (time) => {
    let start, end;
    return time[0] + time[1] + ":" + time[0] + time[1];
  };
  return (
    <Layout>
      <div className=" p-2 mt-2 bg-white shadow-sm rounded">
        {/* {JSON.stringify(daybinaries.map((i) => i.value))} */}
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <div className="h-[30px]  mr-2 w-[20px] rounded bg-sky-200"></div>
            <PageTitle title={`Plan name: ${planid}`} caption={""} />
          </div>
          <CustomButton className=" w-[120px] bg-gray-900 p-2 h-[40px] rounded-md flex items-center justify-center">
            <p className="m-0 text-white">Edit plan</p>
          </CustomButton>
        </div>
        <hr className="my-2" />
        <div className="w-[70%] mt-4 mx-auto">
          <div className="flex justify-between">
            <div className="">Plan description</div>
            <textarea
              type={"text"}
              value={plandetails?.description}
              // {...register("description", {
              //   required: true,
              // })}
              placeholder={"Enter description for plan"}
              className="bg-gray-50 w-[400px]  h-[120px] resize-none  border-[1px] rounded-sm p-2 placeholder:font-light  outline-none "
            />
          </div>
          <hr className="my-4" />

          <div className="flex justify-between">
            <div className="">Time range</div>
            <div className="flex flex-col p-1  bg-white  items-center rounded-md w-100 border-[1px] border-gray-200">
              <TimePicker.RangePicker
                // use12Hours
                style={{
                  width: "100%",
                  fontSize: 13,
                }}
                value={[time.starttime, time.endtime]}
                format="h:mm"
                onChange={(e) => {
                  settime((prev) => {
                    return {
                      starttime: e[0],
                      endtime: e[1],
                    };
                  });
                  console.log(e, "selected time ");
                }}
              />
            </div>
          </div>
          <hr className="my-4" />

          <div className="flex justify-between">
            <div className="">Plan duration</div>
            <div className="flex flex-wrap p-1 bg-white items-center justify-between rounded-md w-100 border-[1px] border-gray-200">
              <input
                // {...register("duration", {
                //   required: true,
                // })}
                defaultValue={plandetails?.duration}
                type="number"
                className="px-[2px] w-[200px] block text-[14px] placeholder:text-[13px] outline-none"
                placeholder="Enter duration"
              />
              <Select
                placeholder=""
                value={plandetails?.durationlength}
                style={{
                  width: 200,
                  fontSize: 13,
                }}
                onChange={(e) => {
                  setdurationperiod(e);
                }}
                size="default"
                options={durationValues}
              />
            </div>
          </div>
          <hr className="my-4" />
          <div className="flex justify-between ">
            <div className="">Active days</div>
            <div className="flex flex-wrap p-1 bg-white items-center rounded-md w-100 border-[1px] border-gray-200">
              <DaysSelector setFd={setdaybinaries} fd={daybinaries} />
            </div>
          </div>
          <hr className="my-4" />
          <div className="flex justify-between ">
            <div className="">Active days</div>
            <div className=" p-1 bg-white rounded-md w-100 ">
              <input
                {...register("kwh", {
                  required: true,
                  maxLength: 4,
                })}
                defaultValue={plandetails?.energyorpower}
                value={plandetails?.energyorpower}
                type="number"
                className="p-2 rounded h-[35px] w-full border-[1px] border-gray-300 block text-[14px] placeholder:text-[14px] outline-none"
                placeholder="Enter units (kwh)"
              />
              {errors.kwh && <ErrorText text={"kwh is required."} />}
            </div>
          </div>
          <hr className="my-4" />
          <div className="flex justify-between ">
            <div className="">Amount</div>
            <div className=" p-1 bg-white rounded-md w-100 ">
              <input
                {...register("kwh", {
                  required: true,
                  maxLength: 4,
                })}
                defaultValue={Number(plandetails?.amount)}
                type="number"
                className="p-2 rounded h-[35px] w-full border-[1px] border-gray-300 block text-[14px] placeholder:text-[14px] outline-none"
                placeholder="Enter units (kwh)"
              />
              {errors.kwh && <ErrorText text={"kwh is required."} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentPlanEdit;
