import moment from "moment";
import DollarImg from "../../../../assets/svg/dollarimg.svg";
import { themeColor as color } from "../../../../constant/color";
import "./styles.css";
import { IoFlashOutline } from "react-icons/io5";
import { RiUserAddLine } from "react-icons/ri";
import { MdOutlineGasMeter } from "react-icons/md";

const SiteDetailsCard = ({ desc, value, imgType, userDetails, currency }) => {
  return (
    <div className="p-2 border flex items-start justify-between  rounded">
      <div className="flex flex-row items-center justify-between">
        <div className="">
          <div className="">{desc}</div>
          <div className="mt-1">
            Date made project manage:{" "}
            <span className="font-semibold">
              {userDetails
                ? moment(new Date(userDetails.dateregistered)).format(
                    "MMMM Do, YYYY HH:mm:ss"
                  )
                : ""}{" "}
            </span>
          </div>
        </div>
      </div>
      <div className="">
        {imgType == "energy" && (
          <div className="">
            <IoFlashOutline color="orange" size={30} />
          </div>
        )}
        {imgType == "revenue" && (
          <div className=" bg-secondary text-white rounded text-center p-1">
            {currency ?? "-Nil-"}
          </div>
        )}
        {imgType == "customer" && (
          <div className="">
            <RiUserAddLine color="orange" size={30} />
          </div>
        )}
        {imgType == "dcu" && (
          <div className="">
            <MdOutlineGasMeter color="orange" size={30} />
          </div>
        )}

        <p className="text-3xl m-0 mt-6 text-end">
          {value}
          {imgType == "energy" && <span className="text-[14px]">kWh</span>}
        </p>
      </div>
    </div>
  );
};

export default SiteDetailsCard;
