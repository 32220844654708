import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { FcCalendar, FcDeleteRow } from "react-icons/fc";
import { MdOutlineDeleteForever } from "react-icons/md";
import DataTable from "react-data-table-component";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import ViewPaymentPlan from "./modals/ViewPaymentPlan";
import { useSelector } from "react-redux";
import { channelController } from "../../../controllers/channelController";
import { errorBlock } from "../../../controllers/errorBlock";
import { CustomButton } from "../../CustomButton";
import { countryList } from "../../../helperFuncs/countryList";
import { GoInfinity } from "react-icons/go";
import { tableCustomStyles } from "../../../helperFuncs";

const TieredPlanTable = ({ data, loading, refreshbtn, tabstate }) => {
  const [plandetails, setplandetails] = useState({});
  const [deleting, setdeleting] = useState(false);
  const [selectplan, setselectplan] = useState({});
  const [open, setopen] = useState(false);
  const { siteid, sitename, country } = useSelector(
    (state) => state.projectSite
  );

  const deletePlan = async (item) => {
    try {
      setdeleting(true);
      let res = await axios.post("/vending/paymentplan/deletepaymentplan", {
        planalias: item.planalias,
        customerclass: item.meter_class,
        siteid: Number(siteid),
      });

      let result = channelController(res);

      if (result.type != "success") {
        toast.error(result.message);
        setdeleting(false);
        return;
      }
      toast.success("Plan deleted successfully.");
      setdeleting(false);
      refreshbtn((prev) => !prev);
    } catch (err) {
      errorBlock(err);
      setdeleting(false);
    }
  };

  const columns = [
    {
      name: "Sitename",
      center: true,
      selector: (row) => row.sitename,
      grow: 1,
      cell: (item) => {
        return (
          <div className="text-secondary font-semibold">{item?.sitename}</div>
        );
      },
      omit: tabstate == 1 ? true : false,
    },
    {
      name: "Set by",
      center: true,
      selector: (row) => row.set_by,
      grow: 1,
    },
    {
      name: "Date Created",
      center: true,
      selector: (row) => row.date_set,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[13px]">
              {moment(item.date_set).format("YYYY-MM-DD HH:mm:ss")}
            </div>
          </div>
        );
      },
      sortable: true,
    },

    {
      name: "Customer Class",
      center: true,
      selector: (row) => row.customer_class,
      grow: 1,
    },
    {
      name: "Action",
      selector: (row) => row.id, // Assuming you have an id field for each row
      grow: 2,
      center: true,
      omit: tabstate == 1 ? true : false,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            {tabstate == 1 && (
              <CustomButton
                loading={selectplan == item.planalias ? deleting : false}
                disabled={deleting}
                color={"tomato"}
                onClick={() => {
                  setselectplan(item.planalias);
                  deletePlan(item);
                }}
                className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
              >
                {selectplan == item.planalias && deleting ? (
                  ""
                ) : (
                  <MdOutlineDeleteForever size={15} />
                )}
              </CustomButton>
            )}
            {/* {tabstate == 2 && (
              <div
                // to={`/paymentplan/edit/${item.planalias}`}
                onClick={() => {}}
                className="border-[1px] cursor-pointer ml-2 rounded border-sky-600 text-[12px] text-sky-600 font-light px-4 py-2"
              >
                Add to {sitename}
              </div>
            )} */}
            {/* <div
              onClick={() => {
                setplandetails(item);
                setopen(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              <FaEye size={15} />
            </div> */}
          </div>
        );
      },
    },
  ];

  const expandedColumns = [
    {
      name: "From (units)",
      center: true,
      selector: (row) => row.min_units,
      grow: 2,
    },
    {
      name: "To (units)",
      center: true,
      selector: (row) => row.max_units,
      grow: 2,
      cell: (item) => {
        return (
          <div className="">
            {item.max_units == null ? (
              <div className="">
                <GoInfinity size={18} />
              </div>
            ) : (
              item?.max_units
            )}
          </div>
        );
      },
    },
    {
      name: `Price per kWh (${countryList[country]["Currency Code"]})`,
      center: true,
      selector: (row) => row.price_per_kWh,
      grow: 2,
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <div className="w-[60%] my-2 mx-auto border">
      <div className="p-2 border-b">
        <h2 className="font-semibold">
          {tabstate == 1 ? `Price tiers` : <>Tiered</>}
        </h2>
      </div>
      <DataTable
        style={{
          height: "100%",
        }}
        columns={expandedColumns}
        data={data?.pricing_tiers?.tiers}
        pagination={false}
        progressPending={loading}
        paginationPerPage={10}
        customStyles={tableCustomStyles}
        // expandableRows
        // expandableRowsComponent={ExpandedComponent}
      />
    </div>
  );

  return (
    <>
      {open && (
        <ViewPaymentPlan open={open} setopen={setopen} details={plandetails} />
      )}
      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        paginationPerPage={10}
        expandableRows
        customStyles={tableCustomStyles}
        expandableRowsComponent={ExpandedComponent}
      />
    </>
  );
};

export default TieredPlanTable;
