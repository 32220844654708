import { v4 as uuidv4 } from "uuid";

export const customerFilterData = [
  {
    // meter/customerid//agent/customertype/////
    id: `${uuidv4()}`,
    label: "Username",
    value: "customerid",
  },
  {
    id: `${uuidv4()}`,
    label: "Agent Name",
    value: "agent",
  },
  {
    id: `${uuidv4()}`,
    label: "Control Policy",
    value: "controlpolicy",
  },
  {
    id: `${uuidv4()}`,
    label: "Customer type.",
    value: "customertype",
  },
  {
    id: `${uuidv4()}`,
    label: "Customer class",
    value: "customerclass",
  },

  {
    id: `${uuidv4()}`,
    label: "Meter Number",
    value: "meter",
  },
  {
    id: `${uuidv4()}`,
    label: "Section",
    value: "Section",
  },
  {
    id: `${uuidv4()}`,
    label: "Pole number.",
    value: "polenumber",
  },
  // {
  //   id: `${uuidv4()}`,
  //   label: "Zone",
  //   value: "zone",
  // },
  {
    id: `${uuidv4()}`,
    label: "Meter type",
    value: "meter",
  },
];
