import React, { useState } from "react";
import "./style.css";

const DaysSelector = ({ fd, setFd, height, border }) => {
  const handleSelectedDay = (i) => {
    let data = [...fd];
    if (data[i].value === 0) {
      data[i].value = 1;
      setFd((prev) => [...data]);
    } else {
      data[i].value = 0;
      setFd((prev) => [...data]);
    }
  };

  return fd.map((item, i) => (
    <div
      className={
        item.value === 1
          ? "px-3 py-2 text-[12px] m-2 rounded-md  border-[1px] border-secondary bg-secondary text-white hover:bg-secondary hover:border-secondary cursor-pointer transition-all ease-in "
          : "px-3 py-2 text-[12px] m-2 rounded-md border-[1px] border-gray-400 bg-white cursor-pointer "
      }
      key={i}
      onClick={() => handleSelectedDay(i)}
    >
      {item.title}
    </div>
  ));
};

export default DaysSelector;
