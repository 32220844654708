import { useParams, useSearchParams } from "react-router-dom";
import BackBtn from "../../../../components/BackBtn";
import Layout from "../../../../components/Layout";
import ProjectSiteLocation from "../../../../components/ProjectManager/ProjectSite/Map/ProjectSiteLocation";
import { useGetAllSiteMeterMapMutation } from "../../../../services/map.services";
import { useEffect } from "react";
import { errorBlock } from "../../../../controllers/errorBlock";
import MetersInSiteLocation from "../../../../components/ProjectManager/ProjectSite/Map/MetersInSiteLocation";
const ProjectsitGPSelocation = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  let lat = searchParams.get("lat");
  let long = searchParams.get("long");
  let siteid = searchParams.get("siteid");

  const [
    getAllSiteMeterMap,
    { isLoading: sitemapLoading, error: sitemapError, data: sitemapData },
  ] = useGetAllSiteMeterMapMutation();

  console.log(sitemapData?.devices, "data");

  useEffect(() => {
    getAllSiteMeterMap({ siteid }).unwrap();
  }, []);

  useEffect(() => {
    if (sitemapError) {
      errorBlock(sitemapError);
    }
  }, [sitemapError]);

  return (
    <Layout>
      <div className="w-full h-auto p-4 rounded shadow-md bg-white">
        <div className="">
          <BackBtn />
        </div>
        <hr className="my-3" />
        <div className="flex items-center pb-2 justify-between">
          <div className="font-semibold">Location of meters in project</div>
          <div className=" cursor-pointer"></div>
        </div>
        <div className="h-[600px] rounded border-[1px]">
          <MetersInSiteLocation data={sitemapData?.devices ?? []} zoom={4} />
        </div>
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default ProjectsitGPSelocation;
