import Layout from "../../components/Layout";
import { getToken } from "../../utils/token";

const CustomerRepDashboard = () => {
  return (
    <div className="bg-white drop-shadow-md rounded mt-1 mb-10 h-screen w-full">
      <iframe
        src={`http://172.29.105.37:3000/tickets?sessionid=8w97ihfsjsbdc&callerid=jzannu`}
        className="h-screen w-full"
      ></iframe>
    </div>
  );
};

export default CustomerRepDashboard;
