import { useSelector } from "react-redux";
import Rule from "../../../Rule/Index";
import CloseButton from "../../../CloseButton";
import Backdrop from "../../../Backdrop";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { CustomButton } from "../../../CustomButton";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ErrorText from "../../../Errors/ErrorText";
import { Select, Space, TimePicker } from "antd";
import DaysSelector from "../../../Commands/DaysSelector";
import { durationValues } from "../../../../helperFuncs";
import { errorBlock } from "../../../../controllers/errorBlock";
import axios from "../../../../utils/axios";
import { meterController } from "../../../../controllers/MeterController";
import toast from "react-hot-toast";
import moment from "moment";
import { channelController } from "../../../../controllers/channelController";

const AddDailyEnergyPlan = ({ open, setopen, refreshbtn, customerclass }) => {
  const [hint, sethint] = useState(true);
  const [durationperiod, setdurationperiod] = useState("");
  const [loading, setloading] = useState(false);
  const [classtype, setclasstype] = useState("");
  const [timedefault, settimedefault] = useState(true);
  const [time, settime] = useState({
    starttime: "",
    endtime: "",
  });
  const [tab, settab] = useState(1);
  const { siteid, sitename } = useSelector((state) => state.projectSite);

  const [daybinaries, setdaybinaries] = useState([
    {
      id: 1,
      title: "Sunday",
      value: 0,
    },
    {
      id: 2,
      title: "Monday",
      value: 0,
    },
    {
      id: 3,
      title: "Tuesday",
      value: 0,
    },
    {
      id: 4,
      title: "Wednesday",
      value: 0,
    },
    {
      id: 5,
      title: "Thursday",
      value: 0,
    },
    {
      id: 6,
      title: "Friday",
      value: 0,
    },
    {
      id: 7,
      title: "Saturday",
      value: 0,
    },
  ]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setloading(true);

      let startime, endtime;

      if (!timedefault) {
        startime = moment(time.starttime._d)
          .format("HH:mm")
          .toString()
          .replace(":", "");
        endtime = moment(time.endtime._d)
          .format("HH:mm")
          .toString()
          .replace(":", "");
      }

      let res = await axios.post("/vending/paymentplan/createdailyenergyplan", {
        planalias: `${data.planalias} (${data.kwh}kWh)`,
        description: data.description,
        daybinaries: daybinaries.map((i) => i.value).join(""),
        starttime: startime ?? time.starttime.replaceAll(":", ""),
        endtime: endtime ?? time.endtime.replaceAll(":", ""),
        kwh: data.kwh,
        duration: Number(data.duration),
        durationlength: durationperiod,
        amount: Number(data.amount),
        siteid: Number(siteid),
        customerclass: classtype,
      });

      const result = channelController(res);

      if (result.type !== "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }

      toast.success("Plan create successfully.");
      refreshbtn((prev) => !prev);
      setloading(false);
      setopen(false);
    } catch (error) {
      setloading(false);
      errorBlock(error);
    }
  };

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[500px] rounded-md bg-white drop-shadow-md  p-3">
          <div className="flex items-start justify-between">
            <div className="">
              <div className=" font-semibold text-[17px]">
                Create Daily Energy plan
              </div>
              <div className="w-[100%] text-gray-700">
                Fill the fields below to create a dialy energy plan
              </div>
            </div>
            <CloseButton closeBtn={setopen} />
          </div>
          <Rule />
          <form onSubmit={handleSubmit(onSubmit)}>
            {tab == 1 && (
              <>
                <div className="">
                  <div className="flex m-0 justify-between">
                    <p className="m-0">Plan name</p>
                    <p
                      onClick={() => sethint((prev) => !prev)}
                      className="text-gray-400 m-0 cursor-pointer"
                    >
                      hint
                    </p>
                  </div>
                  <input
                    type={"text"}
                    {...register("planalias", {
                      required: true,
                    })}
                    placeholder={"Enter plan name."}
                    className="bg-gray-100 mt-2 h-[40px] border-[1px] rounded-sm p-2 placeholder:font-light w-full outline-none "
                  />
                </div>

                {errors.planalias && (
                  <ErrorText text={"Plan name is required."} />
                )}
                {hint && (
                  <div className="border-[1px] rounded-md p-2 mt-2">
                    <p className="m-0 underline">
                      Examples of names to be used:
                    </p>
                    <ul className="list-disc list-inside">
                      <li className="font-light text-[12px]">
                        <span className="font-semibold">
                          Weekday energy plan:
                        </span>{" "}
                        this tells the customers the plan being created is to be
                        used from Monday to Friday (weekday)
                      </li>
                      <li className="font-light text-[12px]">
                        <span className="font-semibold">
                          {" "}
                          Weekend energy plan:
                        </span>
                        this tells the customers the plan being created is to be
                        used only on Sunday and Friday (weekends)
                      </li>
                    </ul>
                  </div>
                )}
                <textarea
                  type={"text"}
                  {...register("description", {
                    required: true,
                  })}
                  placeholder={"Enter description for plan"}
                  className="bg-gray-100 h-[120px] resize-none mt-2 border-[1px] rounded-sm p-2 placeholder:font-light w-full outline-none "
                />
              </>
            )}

            {tab == 2 && (
              <>
                <div className="font-semibold">Active days</div>
                <p className="m-0">
                  {" "}
                  Select days of the week the plan should be active:
                </p>
                <div className=" flex justify-end items-center mb-2">
                  <div className=" flex items-center  justify-end">
                    <div className="h-[10px] mr-1 w-[10px] rounded-full border-[1px] border-gray-500"></div>
                    <p className="m-0 text-[13px] font-light"> Not selected</p>
                  </div>
                  <div className=" flex items-center  justify-end ml-2">
                    <div className="h-[10px] mr-1 w-[10px] bg-secondary rounded-full border-[1px] border-secondary"></div>
                    <p className="m-0 text-[13px] font-light"> Selected</p>
                  </div>
                </div>
                <div className="flex flex-wrap p-1 bg-white items-center rounded-md w-100 border-[1px] border-gray-200">
                  <DaysSelector setFd={setdaybinaries} fd={daybinaries} />
                </div>
              </>
            )}

            {tab == 3 && (
              <>
                <div className="font-semibold">Duration length</div>
                <p className="m-0">Duration of plan from time of purchase</p>

                <div className="flex mt-3 flex-wrap p-1 bg-white items-center justify-between rounded-md w-100 border-[1px] border-gray-200">
                  <input
                    {...register("duration", {
                      required: true,
                    })}
                    type="number"
                    className="px-[2px]  w-[250px] block text-[14px] placeholder:text-[14px] outline-none"
                    placeholder="Enter duration"
                  />
                  <Select
                    placeholder="Select duration"
                    style={{
                      width: 200,
                      fontSize: 13,
                    }}
                    onChange={(e) => {
                      setdurationperiod(e);
                    }}
                    size="large"
                    options={durationValues}
                  />
                </div>
              </>
            )}

            {tab == 4 && (
              <>
                <div className="font-semibold">Active time</div>
                <p className="m-0">
                  Select a time range for which the plan should be active (24hr
                  format):
                </p>

                {timedefault && (
                  <div className="flex flex-col p-1 mt-2 bg-white  items-center rounded-md w-100 border-[1px] border-gray-200">
                    <p className="m-0 text-center text-[14px]">
                      Would you like the plan to be active throughout the day,{" "}
                      <br />
                      i.e 00:00 to 23:59 (24 hours)
                    </p>
                    <div className="mt-2 flex items-center justify-around">
                      <CustomButton
                        onClick={() => settimedefault(false)}
                        className={
                          " border-gray-300 mx-2 hover:text-secondary rounded-md flex items-center justify-end"
                        }
                      >
                        <p className="m-0 px-4">No</p>
                      </CustomButton>
                      <div className="h-[40px] border-l-[1px] border-gray-300"></div>
                      <CustomButton
                        onClick={() => {
                          settime((prev) => {
                            return {
                              starttime: "00:00",
                              endtime: "23:59",
                            };
                          });

                          settab(5);
                        }}
                        className={
                          " border-gray-300 hover:text-secondary rounded-md mx-2 flex items-center justify-end"
                        }
                      >
                        <p className="m-0 px-4 ">Yes</p>
                      </CustomButton>
                    </div>
                  </div>
                )}

                {!timedefault && (
                  <div className="flex flex-col p-1 mt-2 bg-white  items-center rounded-md w-100 border-[1px] border-gray-200">
                    <TimePicker.RangePicker
                      // use12Hours
                      size="large"
                      style={{
                        width: "100%",
                        fontSize: 13,
                      }}
                      value={[time.starttime, time.endtime]}
                      format="h:mm"
                      onChange={(e) => {
                        settime((prev) => {
                          return {
                            starttime: e[0],
                            endtime: e[1],
                          };
                        });
                        console.log(e, "selected time ");
                      }}
                    />
                  </div>
                )}
              </>
            )}

            {tab == 5 && (
              <>
                <div className="font-semibold">Enter units and price</div>
                {/* <p className="m-0">Meta</p> */}
                <div className="mt-3 p-1 bg-white rounded-md w-100 ">
                  <input
                    {...register("kwh", {
                      required: true,
                      maxLength: 4,
                    })}
                    type="number"
                    className="p-2 rounded h-[40px] w-full border-[1px] border-gray-300 block text-[14px] placeholder:text-[14px] outline-none"
                    placeholder="Enter units (kwh)"
                  />
                  {errors.kwh && <ErrorText text={"kwh is required."} />}

                  <input
                    {...register("amount", {
                      required: true,
                    })}
                    type="number"
                    className="p-2 mt-3 rounded h-[40px] w-full border-[1px] border-gray-300 block text-[14px] placeholder:text-[14px] outline-none"
                    placeholder="Enter amount"
                  />
                  {errors.amount && <ErrorText text={"kwh is required."} />}

                  <Select
                    placeholder={"Select customer class"}
                    style={{
                      marginTop: 10,
                      width: "100%",
                      borderRadius: "10 !important",
                      fontSize: 13,
                    }}
                    onChange={(e) => {
                      setclasstype(e);
                    }}
                    size="large"
                    options={customerclass.map((i) => {
                      return {
                        value: i.customerClass,
                        label: i.customerClass,
                      };
                    })}
                  />
                </div>
              </>
            )}

            <hr className="mt-3" />
            <div
              className={`flex items-center ${
                tab > 1 ? " justify-between" : "justify-end"
              }`}
            >
              {tab > 1 && (
                <CustomButton
                  onClick={(e) => {
                    e.preventDefault();
                    if (tab == 2) {
                      settab(1);
                    } else {
                      settab((prev) => (prev -= 1));
                    }
                  }}
                  className={
                    "mt-4 border-[1px] p-2 border-gray-300 rounded-md flex items-center justify-end"
                  }
                >
                  <IoIosArrowBack />
                  <p className="m-0  pr-2">Back</p>
                </CustomButton>
              )}
              {tab != 5 && (
                <CustomButton
                  onClick={(e) => {
                    e.preventDefault();
                    settab((prev) => (prev += 1));
                  }}
                  className={
                    "mt-4 border-[1px] p-2 border-gray-300 rounded-md flex items-center justify-end"
                  }
                >
                  <p className="m-0  pr-2">Next</p>
                  <IoIosArrowForward />
                </CustomButton>
              )}

              {tab == 5 && (
                <CustomButton
                  loading={loading}
                  className={
                    "mt-4 border-[1px] p-2 bg-black border-gray-300 rounded-md flex items-center justify-end"
                  }
                >
                  <p className="m-0 text-white pr-2">Create plan</p>
                </CustomButton>
              )}
            </div>
          </form>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddDailyEnergyPlan;
