import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import DataTable from "react-data-table-component";
import { MdOutlineDeleteForever } from "react-icons/md";
import axios from "../../../../../utils/axios";
import toast from "react-hot-toast";
import { errorBlock } from "../../../../../controllers/errorBlock";
import { tableCustomStyles } from "../../../../../helperFuncs";
import { Dropdown, message, Space } from "antd";
import { TbDotsVertical } from "react-icons/tb";

const MeterClassTable = ({ data, setdetails, setmodal }) => {
  const sampleData = [
    {
      meterclass: "Class A",
      tou: "Time of Use A",
      description: "USA",
      dateentered: moment().subtract(1, "days").toISOString(),
      siteid: "1",
    },
    {
      meterclass: "Class B",
      tou: "Time of Use B",
      description: "Canada",
      dateentered: moment().subtract(2, "days").toISOString(),
      siteid: "2",
    },
    {
      meterclass: "Class C",
      tou: "Time of Use C",
      description: "UK",
      dateentered: moment().subtract(3, "days").toISOString(),
      siteid: "3",
    },
    {
      meterclass: "Class D",
      tou: "Time of Use D",
      description: "Germany",
      dateentered: moment().subtract(4, "days").toISOString(),
      siteid: "4",
    },
    {
      meterclass: "Class E",
      tou: "Time of Use E",
      description: "Australia",
      dateentered: moment().subtract(5, "days").toISOString(),
      siteid: "5",
    },
  ];

  const [selectedclass, setselectedclass] = useState("");
  const [loading, setloading] = useState(false);

  const handleDelete = async (item) => {
    try {
      setloading(true);
      let res = await axios.post("/meterclass/deletesavedmeterclasses", {
        meterclasscode: item,
      });
      toast.success("Meterclass deleted successfully.");
      setloading(false);
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: <div className="text-[12px]">Edit</div>,
    },
    {
      key: "2",
      label: <div className="text-[12px]">View class details</div>,
    },
    {
      key: "3",
      label: <div className="text-[12px]">Delete</div>,
    },
  ];

  const columns = [
    {
      name: "Meter class",
      center: true,
      selector: (row) => row.customerClass,
      grow: 1,
    },
    {
      name: "Time of use",
      center: true,
      selector: (row) => row.timeOfUse,
      grow: 3,
    },
    {
      name: "Class type",
      center: true,
      selector: (row) => row.customerType,
      grow: 3,
    },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.dateCreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[13px]">
              {moment(item.dateCreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => row.id, // Assuming you have an id field for each row
      grow: 2,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomCenter"
              arrow
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <TbDotsVertical color="black" />
                </Space>
              </a>
            </Dropdown>
            {/* <div
              onClick={() => handleDelete(item.customerClass)}
              className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
            >
              <MdOutlineDeleteForever size={15} />
            </div>
            <div
              onClick={() => {
                // Your edit action
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
            >
              <FaRegEdit size={15} />
            </div>
            <div
              // to={`/details/${item.id}`} // Adjust the path as needed
              className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              <FaEye size={15} />
            </div> */}
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      style={{
        height: "100%",
      }}
      columns={columns}
      data={data}
      // data={sampleData}
      pagination
      paginationPerPage={10}
      customStyles={tableCustomStyles}
    />
  );
};

export default MeterClassTable;

//  <table className="w-full border-collapse">
//    <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
//      <th className="font-semibold text-gray-800">S/N</th>
//      <th className="font-semibold text-gray-800">Meterclass Name</th>
//      <th className="font-semibold text-gray-800 w-[200px]">
//        Active time of use
//      </th>
//      <th className="font-semibold text-gray-800">Description</th>

//      <th className="font-semibold text-gray-800">Date created</th>
//      {/* <th className="font-semibold text-gray-800">Time range</th> */}
//      <th className="font-semibold text-gray-800">Action</th>
//    </thead>
//    <tbody>
//      {data.map((i, index) => (
//        <tr
//          className="bg-white whitespace-nowrap hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
//          key={i?.roleid}
//        >
//          <td className="text-center">{index + 1}</td>
//          <td className="text-center">{i?.meterclass}</td>
//          <td className="text-center text-secondary w-[400px]">
//            <div className="flex flex-wrap justify-center">
//              {touFormatter(i?.tou).length > 1
//                ? touFormatter(i?.tou)
//                    .slice(0, 2)
//                    .map(({ pt, st, et }, index, arr) => (
//                      <div
//                        className="even:border-l-[1px] even:ml-2 border-[1px] rounded-lg p-2 my-[2px] border-t-secondary"
//                        key={index}
//                      >
//                        <div className="flex items-center justify-start">
//                          <div className="font-light text-[12px]">
//                            Start time:
//                          </div>
//                          <div className="font-light text-[12px]">
//                            {" "}
//                            {"  "}
//                            {st}
//                          </div>
//                        </div>
//                        <div className="flex items-center justify-start">
//                          <div className="font-light text-[12px]">End time:</div>
//                          <div className="font-light text-[12px]">
//                            {" "}
//                            {"  "}
//                            {et}
//                          </div>
//                        </div>{" "}
//                        <div className="flex items-center justify-start">
//                          <div className="font-light text-[12px]">
//                            Billing percentage:
//                          </div>
//                          <div className="font-light text-[12px]">
//                            {" "}
//                            {"  "}
//                            {pt}
//                          </div>
//                        </div>
//                      </div>
//                    ))
//                : touFormatter(i?.tou).map(({ pt, st, et }, index, arr) => (
//                    <div
//                      className="even:border-l-[1px] even:ml-2 border-[1px] rounded-lg p-2 my-[2px] border-t-secondary"
//                      key={index}
//                    >
//                      <div className="flex items-center justify-start">
//                        <div className="font-light text-[12px]">Start time:</div>
//                        <div className="font-light text-[12px]">
//                          {" "}
//                          {"  "}
//                          {st}
//                        </div>
//                      </div>
//                      <div className="flex items-center justify-start">
//                        <div className="font-light text-[12px]">End time:</div>
//                        <div className="font-light text-[12px]">
//                          {" "}
//                          {"  "}
//                          {et}
//                        </div>
//                      </div>{" "}
//                      <div className="flex items-center justify-start">
//                        <div className="font-light text-[12px]">
//                          Billing percentage:
//                        </div>
//                        <div className="font-light text-[12px]">
//                          {" "}
//                          {"  "}
//                          {pt}
//                        </div>
//                      </div>
//                    </div>
//                  ))}
//            </div>
//          </td>
//          <td className="rounded-lg text-center">{i?.description}</td>
//          <td className="text-center">
//            <div className="flex items-center justify-center">
//              <div className="pl-2">{moment(i?.dateentered).format("lll")}</div>
//            </div>{" "}
//          </td>

//          <td className="">
//            <div className="grid grid-cols-2 gap-4 place-content-end justify-center">
//              <div className="border-[1px] hidden text-center rounded border-orange-600 text-[12px] text-orange-600 font-light px-4 py-2">
//                Edit
//              </div>
//              <div
//                onClick={() => {
//                  setdetails(i);
//                  setmodal(true);
//                }}
//                className="border-[1px] text-center rounded border-secondary text-[12px] text-secondary font-light px-4 py-2"
//              >
//                View
//              </div>
//            </div>
//          </td>
//        </tr>
//      ))}
//    </tbody>
//  </table>;
