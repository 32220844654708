import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import EmptyState from "../../../EmptyState";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../../../helperFuncs";
import { countryList } from "../../../../helperFuncs/countryList";
import { useSelector } from "react-redux";
const WalletHistoryTable = ({ data, setdetails, setmodal, loading }) => {
  const { country, sitename } = useSelector((state) => state.projectSite);

  const columns = [
    {
      name: "S/N",
      selector: (_, index) => index + 1,
      center: true,
      sortable: false,
      grow: 1,
    },
    {
      name: `Initiator`,
      selector: (row) => row.operator,
      center: true,
      sortable: true,
      grow: 2,
    },
    {
      name: `Amount (${countryList[country]["Currency Code"]})`,
      selector: (row) => row.amount,
      center: true,
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="">
          {row.amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      name: `Transaction type`,
      selector: (row) => row.action,
      center: true,
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div
          className={`flex items-center justify-center border-[1px] px-2 py-1 rounded-full ${
            row.action == "debit"
              ? "border-red-400 text-red-400"
              : "border-green-500 text-green-500"
          }`}
        >
          {row.action}
        </div>
      ),
    },
    {
      name: "Date created",
      selector: (row) => moment(row.date).format("lll"),
      center: true,
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="flex items-center justify-center">
          {moment(row.transactionDate).format("lll")}
        </div>
      ),
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.roleid,
    //   center: true,
    //   grow: 1,
    //   cell: (row) => (
    //     <div
    //       className="border-[1px] rounded border-secondary text-[12px] text-secondary font-light px-4 py-2 cursor-pointer"
    //       onClick={() => setdetails(row)}
    //     >
    //       View
    //     </div>
    //   ),
    // },
  ];
  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        paginationPerPage={10}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default WalletHistoryTable;
