import Backdrop from "../../Backdrop";
import { createPortal } from "react-dom";
import ReactDOM from "react-dom";

const BottomModal = ({ children, setopen, open }) => {
  return ReactDOM.createPortal(
    <div
      className="fixed h-screen w-screen bg-[#00000036]  bottom-0 right-0 z-50"
      style={{
        display: open ? "block" : "none",
      }}
    >
      {/*  */}
      <div className="w-screen rounded-t-md absolute bottom-0 animate__animated animate__fadeInUp h-[95vh] bg-white ">
        <div className=" p-3">{children}</div>
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export default BottomModal;
