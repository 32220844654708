import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import { Select } from "antd";
import EmptyState from "../../../EmptyState";
import DataTable, { TableColumn } from "react-data-table-component";
import { VscCopy } from "react-icons/vsc";
import { setToken } from "../../../../utils/token";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { setProjectSiteName } from "../../../../redux/slice/projectsiteSlice";
import { tableCustomStyles } from "../../../../helperFuncs";

const SalesTopUpTable = ({ data = [], setdetails, setmodal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    // {
    //   name: "Agent username",
    //   selector: (row) => row.owner,
    //   center: true,
    //   grow: 3,
    // },
    {
      name: "Issuer",
      selector: (row) => row.operator,
      center: true,
      grow: 3,
    },
    {
      name: "Top up amount",
      selector: (row) => row.amount,
      center: true,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="">{` ${item.amount}` ?? "-Nil-"}</div>
          </div>
        );
      },
    },
    // {
    //   name: "Current balance",
    //   center: true,
    //   selector: (row) => row.currentBalance,
    //   sortable: true,
    //   grow: 1,
    //   cell: (item) => {
    //     return (
    //       <div className="flex items-center">
    //         <div className="">{item?.currentBalance ?? "-Nil-"}</div>
    //       </div>
    //     );
    //   },
    // },

    {
      name: "Transaction date",
      center: true,
      selector: (row) => row.transactionDate,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="">{moment(item.transactionDate).format("lll")}</div>
          </div>
        );
      },
      sortable: true,
    },
  ];

  return (
    <div className="">
      <DataTable
        columns={columns}
        customStyles={tableCustomStyles}
        data={data}
        pagination
        paginationPerPage={10}
      />
    </div>
  );
};

export default SalesTopUpTable;
