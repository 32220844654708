import "./styles.css";
import moment from "moment";
import Backdrop from "../../../../Backdrop";
import { useState } from "react";

const DCUInfoModal = ({ open, setopen, dcuinfo }) => {
  const [meterDetails, setMeterDetails] = useState({});
  const [loading, setLoading] = useState(false);

  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[450px] bg-white  drop-shadow-md rounded p-4">
          <div className=" pb-1 flex items-center justify-between">
            <div className="font-semibold">DCU Info</div>
            <div className=" cursor-pointer" onClick={() => setopen(false)}>
              Close
            </div>
          </div>
          <hr />
          <div className="">
            <div className=" mt-4 flex items-center justify-between">
              <div className="">DCU number:</div>
              <div className="font-semibold">{dcuinfo?.dcuid ?? "-"}</div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Capacity:</div>
              <div className="font-semibold">
                {dcuinfo?.devicecapacity ?? "-"}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Created by:</div>
              <div className="font-semibold">{dcuinfo?.registrar ?? "-"}</div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Serial Id:</div>
              <div className="font-semibold">{dcuinfo?.serialid ?? "-"}</div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Batch Id:</div>
              <div className="font-semibold">{dcuinfo?.batchid ?? "-"}</div>
            </div>

            <div className=" mt-4 flex items-center justify-between">
              <div className="">Model:</div>
              <div className="font-semibold">{dcuinfo?.model ?? "-"}</div>
            </div>

            <div className=" mt-4 flex items-center justify-between">
              <div className="">Date registered:</div>
              <div className="font-semibold">
                {dcuinfo?.dateregistered
                  ? moment(dcuinfo?.dateregistered)
                      .subtract("1", "week")
                      .format("DD-MM-YYYY HH:mm")
                  : "-"}
              </div>
            </div>
            <div className=" mt-4 flex items-center justify-between">
              <div className="">Date decommissioned:</div>
              <div className="font-semibold">
                {meterDetails?.decommissioned
                  ? moment(meterDetails?.decommissioned).format(
                      "DD-MM-YYYY HH:mm"
                    )
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default DCUInfoModal;
