import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { FcCalendar } from "react-icons/fc";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../../../../helperFuncs";
import EditSite from "../../EditSite";
import { Dropdown, Space } from "antd";
import { TbDotsVertical } from "react-icons/tb";
import DeleteSite from "../../DeleteSite";

const ProjectSitesTable = ({
  data,
  loading,
  setEditSite,
  selectedSite,
  refresh,
  siteTypes,
}) => {
  const [seletedsite, setseletedsite] = useState(null);
  const [siteobject, setsiteobject] = useState(null);
  const [openedit, setopenedit] = useState(false);
  const [opendelete, setopendelete] = useState(false);

  const columns = [
    {
      name: "Site name",
      center: true,
      selector: (row) => row.sitename,
      grow: 1,
    },
    {
      name: "Site type",
      center: true,
      selector: (row) => row.sitetype,
      grow: 1,
    },
    // {
    //   name: "GPS",
    //   center: true,
    //   selector: (row) => row.gps,
    //   grow: 1,
    //   cell: (item) => {
    //     return (
    //       <Link
    //         className="underline"
    //         to={`/projectsite/location?lat=${
    //           item.gps.split(",")[0] ?? ""
    //         }&long=${item.gps.split(",")[0] ?? ""}`}
    //       >
    //         {item.gps}
    //       </Link>
    //     );
    //   },
    // },
    {
      name: "Country",
      center: true,
      selector: (row) => row.country,
      grow: 1,
    },
    {
      name: "Time zone",
      center: true,
      selector: (row) => row.timezone,
      grow: 1,
    },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.datecreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[13px]">
              {moment(item.datecreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.siteid,
      grow: 2,
      center: true,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <div
              onClick={() => {
                setopendelete(true);
                setseletedsite(item.sitename);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
            >
              Delete
            </div>
            <div
              onClick={() => {
                setopenedit(true);
                setseletedsite(item.sitename);
                setsiteobject(item);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
            >
              Edit
            </div>
            <Link
              to={`/dashboard/projectsite/${item.siteid}`}
              className="border-[1px] cursor-pointer ml-2 rounded border-blue-400 text-[12px] font-light px-4 py-2"
            >
              Details
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {openedit && (
        <EditSite
          open={openedit}
          setopen={setopenedit}
          closeModal={setEditSite}
          siteTypes={siteTypes}
          site={siteobject}
          refresh={refresh}
        />
      )}
      {opendelete && (
        <DeleteSite
          setopen={setopendelete}
          open={opendelete}
          // site={deleteSite}
          selectedSite={seletedsite}
          setselectedSite={setseletedsite}
          refresh={refresh}
        />
      )}

      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        customStyles={tableCustomStyles}
        pagination
        progressPending={loading}
        paginationPerPage={10}
      />
    </>
  );
};

export default ProjectSitesTable;
