import React, { useState, useEffect, useCallback, useMemo } from "react";
import Layout from "../../../../components/Layout";
import { useAsync } from "../../../../utils/Hooks/useAsync";
import { client } from "../../../../utils/api";
import { Link, useParams } from "react-router-dom";
import ProfileImg from "../../../../assets/svg/projectimg.svg";
import moment from "moment";
import SiteDetailsCard from "../../../../components/ProjectManager/ProjectSite/SiteDetailsCard";
import SiteDetailsCardRight from "../../../../components/ProjectManager/ProjectSite/SiteDetailsCardRight";
import BackBtn from "../../../../components/BackBtn";
import { FaUserShield } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

/** Styles */
import "./styles.css";
import Rule from "../../../../components/Rule/Index";
import { useGetSiteDetailsMutation } from "../../../../services/projectsite.services";
import { countryList } from "../../../../helperFuncs/countryList";
import { errorBlock } from "../../../../controllers/errorBlock";

const SysAdminDashboard = () => {
  const [cardtype, setcardtype] = useState({});
  const [currency, setcurrency] = useState("");
  const [tab, settab] = useState("energy");
  const { data: userDetails, run: getUser } = useAsync({
    data: [],
    status: "pending",
  });

  const { data: userRevenue, run: getsaleshistoryperzone } = useAsync({
    data: [],
    status: "pending",
  });

  const { data: meters, run: getallmetersinzone } = useAsync({
    data: {},
    status: "pending",
  });

  const { projectsite, username } = useParams();

  // get user
  useEffect(() => {
    getUser(
      client(`/auth/useraccount/getuser`, {
        data: { username },
        method: "POST",
      })
    );
  }, [getUser, username]);

  // get sales history per zone based on vending
  const [revenuestartdate, setrevenuestartdate] = useState("");
  const [revenueendate, setrevenueendate] = useState("");

  useEffect(() => {
    const payload = {
      projectzone: JSON.stringify([""]),
      assettype: JSON.stringify([]),
      startdate: revenuestartdate,
      enddate: revenueendate,
      paymentoperation: JSON.stringify(["Vending"]),
      limit: 100,
      offset: 0,
    };

    getsaleshistoryperzone(
      client(`/salesanalytics/getsaleshistoryperzone`, {
        data: payload,
        method: "POST",
      })
    );
  }, [revenueendate, revenuestartdate]);

  const [
    getSiteDetails,
    { isLoading: siteloading, error: siteError, data: siteData },
  ] = useGetSiteDetailsMutation();

  // get Site details
  useEffect(() => {
    getSiteDetails({
      siteid: projectsite,
    }).unwrap();
  }, []);

  useEffect(() => {
    if (siteData?.country) {
      setcurrency(countryList[siteData?.country]["Currency Code"]);
    }
  }, [siteData]);

  useEffect(() => {
    if (siteError) {
      errorBlock(siteError);
    }
  }, [siteError]);

  return (
    <Layout>
      <div className="mb-40">
        {<BackBtn text={"Go back"} />}
        <div className="container">
          <div className=" p-2 rounded shadow mt-3 bg-white items-center">
            <div className="flex items-cente justify-between">
              <div className="flex items-center ">
                <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
                <div className="ml-2">
                  Details of project site managed by{" "}
                  <span className="text-secondary font-semibold">
                    {userDetails?.username
                      ? `${userDetails?.username[0].toUpperCase()}${userDetails?.username.substring(
                          1
                        )} `
                      : ""}
                  </span>
                </div>
              </div>
              <div className="flex items-center w-[170px] justify-end">
                Site managed:
                {siteData?.sitename && (
                  <Link
                    to={`/dashboard/projectsite/${siteData?.siteid}`}
                    className=" font-semibold underline pl-2"
                  >
                    {siteData?.sitename}
                  </Link>
                )}
              </div>
            </div>
            {/* user details */}
            <div className=" mt-4 grid grid-cols-4 gap-4 w-full py-4 border rounded">
              {/* full name */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <FaUserShield size={24} />
                </div>
                <div className="text-gray-400">Full Name</div>
                <div className="font-semibold">
                  {!userDetails?.firstname
                    ? "Registration not complete"
                    : userDetails?.firstname + " " + userDetails?.lastname}
                </div>
              </div>
              {/* Email */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <MdMarkEmailRead size={24} />
                </div>
                <div className="text-gray-400">Email</div>
                <div className="font-semibold">
                  {!userDetails
                    ? "Registration not complete"
                    : userDetails?.email}
                </div>
              </div>
              {/* Phone */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <FaPhone size={24} />
                </div>
                <div className="text-gray-400">Phone</div>
                <div className="font-semibold">
                  {!userDetails
                    ? "Registration not complete"
                    : userDetails?.phone}
                </div>
              </div>
              {/* Date registered */}
              <div className="flex flex-col items-center justify-center">
                <div className="h-[50px] flex items-center justify-center w-[50px] rounded-full bg-gray-100 mb-4">
                  <MdDateRange size={24} />
                </div>
                <div className="text-gray-400">Date registered</div>
                <div className="font-semibold">
                  {moment(userDetails?.dateregistered).format("LLL")}
                </div>
              </div>
            </div>

            <div className="w-full hidden project-datails__title">
              Project manager details for{" "}
              <span className="zone-name">{projectsite}</span>
            </div>
          </div>
          <div className="hidden flex-row p-3 bg-white">
            <div className="hidden md:block lg:block xl:block w-2/12">
              <div className="project-img flex items-center justify-center">
                <img
                  src={ProfileImg}
                  alt="img"
                  style={{ width: "70px", height: "70px" }}
                />
              </div>
            </div>
            <div className="hidden flex-grow-0 flex-shrink-0 w-1/3">
              <div className="flex-row items-center justify-between">
                <div className="sm:col-span-12 sm:col-start-1 sm:col-end-7 lg:col-span-7 xl:col-span-7 xxl:col-span-7">
                  <div className="">
                    <div className="project-entry flex items-center justify-between  ">
                      <div className="project-title text-sm">Full Name</div>
                      <div className="project-value">
                        {!userDetails?.firstname
                          ? "Registration not complete"
                          : userDetails?.firstname +
                            " " +
                            userDetails?.lastname}
                      </div>
                    </div>
                    <div className="project-entry mt-2 flex items-center justify-between">
                      <div className="project-title">Phone Number</div>
                      <div className="project-value">{userDetails?.phone}</div>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-12 sm:col-start-1 sm:col-end-7 lg:col-span-7 xl:col-span-7 xxl:col-span-7">
                  <div className="">
                    <div className="project-entry mt-2 flex items-center justify-between">
                      <div className="project-title ">Email</div>
                      <div className="project-value">
                        {!userDetails?.email
                          ? "Registration not complete."
                          : userDetails?.email}
                      </div>
                    </div>
                    <div className="project-entry flex mt-2 items-center justify-between">
                      <div className="project-title">Date Registered</div>
                      <div className="project-value">
                        {moment(userDetails?.dateregistered).format("LLL")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Energy generate in the site  */}
          <div className="p-2 bg-white rounded shadow mt-2">
            <div className="flex items-center justify-between">
              <div className="font-semibold"> Energy records</div>
              <div
                className=" cursor-pointer"
                onClick={() => {
                  settab("energy");
                }}
              >
                <IoIosArrowDown />
              </div>
            </div>
            <Rule />
            {tab == "energy" && (
              <div className="grid grid-cols-2 gap-2 mt-2">
                <div className="">
                  <SiteDetailsCard
                    userDetails={userDetails ?? {}}
                    desc={`Energy generated since ${
                      userDetails ? userDetails?.username : ""
                    }
                    was made a project manager.`}
                    imgType={"energy"}
                    value={"0"}
                    currency={currency}
                  />
                </div>
                <div className="bg-white rounded-1 shadow-sm">
                  <SiteDetailsCardRight
                    title={"Energy generated from site today."}
                    caption={"Total energy"}
                    value={"0"}
                    imgType={"energy"}
                    currency={currency}
                    bg={"#FFF8EF"}
                    link={`/dashboard/records/energy/${projectsite}/${username}`}
                    border={`border-2 border-yellow-500`}
                  />
                </div>
              </div>
            )}
          </div>

          {/* Revenue generate in the site  */}
          <div className="p-2 bg-white rounded shadow mt-2">
            <div className="flex items-center justify-between">
              <div className="font-semibold">Revenue records</div>
              <div
                className=" cursor-pointer"
                onClick={() => {
                  settab("revenue");
                }}
              >
                <IoIosArrowDown />
              </div>
            </div>
            <Rule />
            {tab == "revenue" && (
              <div className="grid grid-cols-2 gap-2 mt-2">
                <div className="">
                  <SiteDetailsCard
                    currency={currency}
                    userDetails={userDetails ?? {}}
                    desc={`Revenue generated since since ${
                      userDetails ? userDetails?.username : ""
                    }
                    was made a project manager.`}
                    imgType={"revenue"}
                    value={"0"}
                  />
                </div>
                <div className="bg-white rounded-1 shadow-sm">
                  <SiteDetailsCardRight
                    currency={currency}
                    title={"Revenue generated from site today."}
                    caption={"Total revenue"}
                    value={"0"}
                    imgType={"revenue"}
                    bg={"#FFF8EF"}
                    link={`/dashboard/records/revenue/${projectsite}/${username}`}
                    border={`border-2 border-yellow-500`}
                  />
                </div>
              </div>
            )}
          </div>

          {/* Customer onboarded in the site */}
          <div className="p-2 bg-white rounded shadow mt-2">
            <div className="flex items-center justify-between">
              <div className="font-semibold">Customer records</div>
              <div
                className=" cursor-pointer"
                onClick={() => {
                  settab("customer");
                }}
              >
                <IoIosArrowDown />
              </div>
            </div>
            <Rule />
            {tab == "customer" && (
              <div className="grid grid-cols-2 gap-2 mt-2">
                <div className="">
                  <SiteDetailsCard
                    currency={currency}
                    userDetails={userDetails ?? {}}
                    desc={`Number of customers onboarded since ${
                      userDetails ? userDetails?.username : ""
                    }
                    was made a project manager.`}
                    imgType={"customer"}
                    value={"0"}
                  />
                </div>
                <div className="bg-white rounded-1 shadow-sm">
                  <SiteDetailsCardRight
                    currency={currency}
                    title={"Number of customers onboarded from site today."}
                    caption={"Total customer"}
                    value={"0"}
                    imgType={"customer"}
                    bg={"#FFF8EF"}
                    link={`/dashboard/records/users/${projectsite}/${username}`}
                    border={`border-2 border-yellow-500`}
                  />
                </div>
              </div>
            )}
          </div>

          {/* DCU onboarded in the site */}
          <div className="p-2 bg-white rounded shadow mt-2">
            <div className="flex items-center justify-between">
              <div className="font-semibold">DCU records</div>
              <div
                className=" cursor-pointer"
                onClick={() => {
                  settab("dcu");
                }}
              >
                <IoIosArrowDown />
              </div>
            </div>
            <Rule />
            {tab == "dcu" && (
              <div className="grid grid-cols-2 gap-2 mt-2">
                <div className="">
                  <SiteDetailsCard
                    currency={currency}
                    userDetails={userDetails ?? {}}
                    desc={`Newly added DCUs added since ${
                      userDetails ? userDetails?.username : ""
                    }
                    was made a project manager.`}
                    imgType={"dcu"}
                    value={"0"}
                  />
                </div>
                <div className="bg-white rounded-1 shadow-sm">
                  <SiteDetailsCardRight
                    currency={currency}
                    title={"Total number of DCUs added to site today."}
                    caption={"Total DCUs"}
                    value={"0"}
                    imgType={"dcu"}
                    bg={"#FFF8EF"}
                    link={`/dashboard/records/meters/${projectsite}/${username}`}
                    border={`border-2 border-yellow-500`}
                  />
                </div>
              </div>
            )}
          </div>

          {/* Meters onboarded in the site */}
          <div className="p-2 bg-white rounded shadow mt-2">
            <div className="flex items-center justify-between">
              <div className="font-semibold">Meter records</div>
              <div
                className=" cursor-pointer"
                onClick={() => {
                  settab("meter");
                }}
              >
                <IoIosArrowDown />
              </div>
            </div>
            <Rule />
            {tab == "meter" && (
              <div className="grid grid-cols-2 gap-2 mt-2">
                <div className="">
                  <SiteDetailsCard
                    currency={currency}
                    userDetails={userDetails ?? {}}
                    desc={`Newly added meters since ${
                      userDetails ? userDetails?.username : ""
                    }
                    was made a project manager.`}
                    imgType={"meters"}
                    value={"0"}
                  />
                </div>
                <div className="bg-white rounded-1 shadow-sm">
                  <SiteDetailsCardRight
                    currency={currency}
                    title={"Total number of Meters added to site today."}
                    caption={"Total DCUs"}
                    value={"0"}
                    imgType={"dcu"}
                    bg={"#FFF8EF"}
                    link={`/dashboard/records/meters/${projectsite}/${username}`}
                    border={`border-2 border-yellow-500`}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-[100px]"></div>
    </Layout>
  );
};

export default SysAdminDashboard;
